import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { CustomsOfficeModel, CustomsOfficeService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'rmb-customs-office-select',
  templateUrl: './customs-office-select.component.html',
  styleUrls: ['./customs-office-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CustomsOfficeSelectComponent)],
})
export class CustomsOfficeSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  customsOffice$: Observable<CustomsOfficeModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = false;

  constructor(
    private customsOfficeService: CustomsOfficeService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.customsOffice$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.customsOfficeService.listCustomsOffices(query, 20) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(customOffice: CustomsOfficeModel): void {
    this.selectedName = customOffice?.name;
    this.value = customOffice?.id;
    this.onChange(customOffice?.id);
    this.changed.emit(customOffice?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }
    const customOffice = await this.customsOfficeService
      .listCustomsOffices(value)
      .toPromise()
      .then((filteredValues) => filteredValues.find((customOffice) => customOffice.id === value));

    // In case no entry found with that id
    if (!customOffice) {
      return;
    }

    this.value = customOffice.id;
    this.selectedName = customOffice.name;
  }
}
