import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './components';
import { OverlayModule } from '@angular/cdk/overlay';
import { ComponentOutletDirective } from './directives';
import { ModalService } from './service';
import { FlexModule } from '@ngbracket/ngx-layout';

const COMPONENTS = [OverlayComponent];

const DIRECTIVES = [ComponentOutletDirective];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [CommonModule, OverlayModule, FlexModule],
  exports: [...COMPONENTS, ...DIRECTIVES],
  providers: [ModalService],
})
export class ModalModule {}
