import { NgModule } from '@angular/core';
import { ServiceSelectionComponent } from './service-selection.component';
import { CommonModule } from '@angular/common';
import { FormErrorModule, SelectModule } from '@eg/ui';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

@NgModule({
  declarations: [ServiceSelectionComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [ServiceSelectionComponent],
})
export class ServiceSelectionModule {}
