<eg-select
  *transloco="let translate"
  [disabled]="disabled"
  [items]="service"
  [label]="label"
  [value]="value"
  (changed)="onSelect($event)"
  [clearable]="clearable"
  (blurred)="onBlur()"
  [fieldType]="_fieldType"
  [dropDownPosition]="dropDownPosition"
>
</eg-select>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
