import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmptyPageComponent } from './empty-page.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { SharedUtilModule } from '@railmybox/shared/util';
import { FormErrorModule } from '@eg/ui';

@NgModule({
  declarations: [EmptyPageComponent],
  imports: [CommonModule, FlexLayoutModule, FormErrorModule, SharedUtilModule],
  exports: [EmptyPageComponent],
})
export class EmptyPageModule {}
