import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'eg-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
})
export class SpinnerComponent implements AfterViewInit {
  @Input() size = 100;
  @ViewChild('spinner', { static: false }) spinner: ElementRef;

  ngAfterViewInit(): void {
    this.spinner.nativeElement.style.width = this.size + 'px';
    this.spinner.nativeElement.style.height = this.size + 'px';
  }
}
