import { Component, Input } from '@angular/core';
import { ModalRef } from './../modal';
import { ConfirmationSource, ConfirmationType } from './confirmation.type';

@Component({
  selector: 'eg-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  @Input() source: ConfirmationSource;
  @Input() type: ConfirmationType;
  @Input() data: string | number;
  @Input() status: string;

  readonly TRANSLOCO_PREFIX = 'confirmation';

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onResponseClick(): void {
    if (this.type === 'delete') {
      this.modalRef.close('DELETE');
      return;
    }

    if (this.type === 'cancel') {
      this.modalRef.close('CANCEL');
      return;
    }

    if (this.type === 'update') {
      this.modalRef.close('UPDATE');
      return;
    }
  }
}
