import { PositionStrategy } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';

export interface ModalConfig<T> {
  data: T;
  content: string | TemplateRef<any> | Type<any>;
  title: string | TemplateRef<any>;
  width?: string;
  overlayClass?: string;
  backdropClose?: boolean;
  showHeader?: boolean;
  positionStrategy?: PositionStrategy;
}

export const DEFAULT_MODAL_CONFIG: Partial<ModalConfig<any>> = Object.freeze({
  backdropClose: false,
  showHeader: true,
  width: '920px',
});
