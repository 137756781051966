export interface SelectItem {
  label?: string;
  value: any;
  disabled?: boolean;
}

export interface IconSelect {
  iconName?: string;
  value?: boolean;
}

export interface IllustratedOption {
  name?: string;
  icon: string;
  id: string | number;
  label: string;
  tooltip?: string;
  value: any;
  disabled?: boolean;
}
