<div class="radio-select" *transloco="let translate">
  @for (item of items; track item) {
  <div
    class="radio-select__item"
    [class.radio-select__item--selected]="item.value === value"
    [fxFlex]="100 / items.length"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="onItemSelect(item)"
  >
    <span>{{ translateLabel ? translate(item.label) : item.label }}</span>
  </div>
  }
</div>
