<div
  class="tile-select"
  [class.tile-select--disabled]="disabled"
  fxFlex="100"
  fxLayoutAlign="{{ alignment }}"
  fxLayout="row"
  *transloco="let translate"
>
  <div class="tile-select__container" fxFlex="{{ containerWidth }}px" fxLayoutGap="{{ tileGap }}px">
    @for (option of options; track option) {
    <button
      type="button"
      fxFlex="{{ 100 / options.length }}"
      fxLayoutAlign="center center"
      [class.selected]="isSelected(option)"
      [class.unselected]="isUnselected(option)"
      [disabled]="option.disabled"
      (click)="onSelectionChange(option.value)"
    >
      {{ translateAble ? translate(option.label) : option.label }}
    </button>
    }
  </div>
</div>
