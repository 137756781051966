import { Component, Input } from '@angular/core';

@Component({
  selector: 'eg-tile-add',
  templateUrl: './tile-add.component.html',
  styleUrls: ['./tile-add.component.scss'],
})
export class TileAddComponent {
  @Input() label: string;
}
