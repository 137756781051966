<div *transloco="let translate">
  <form [formGroup]="_form" fxFlex="100" fxLayout="column" fxLayoutGap="8px">
    <h3 class="subtitle-h3" fxFlex="100">{{ translate(TRANSLOCO_PREFIX + '.setting.owner.title') }}</h3>

    <div fxFlex="100" fxLayout="row" fxLayoutGap="12px">
      <eg-input
        fxFlex="100"
        type="text"
        name="bankAccountOwner"
        formControlName="bankAccountOwner"
        fieldType="M"
        [placeholder]="translate(TRANSLOCO_PREFIX + '.setting.bankAccountOwner.title')"
        [label]="translate(TRANSLOCO_PREFIX + '.setting.bankAccountOwner.title')"
      >
      </eg-input>
    </div>

    <h3 class="subtitle-h3 mt-24" fxFlex="100">{{ translate(TRANSLOCO_PREFIX + '.setting.iban.title') }}</h3>

    <div fxFlex="100" fxLayout="row" fxLayoutGap="12px">
      <eg-input
        fxFlex="100"
        type="text"
        name="iban"
        formControlName="iban"
        fieldType="M"
        [placeholder]="translate(TRANSLOCO_PREFIX + '.setting.iban.form')"
        [label]="translate(TRANSLOCO_PREFIX + '.setting.iban.form')"
        (blurred)="validateIban()"
      >
      </eg-input>
    </div>

    <eg-alert type="info" [message]="translate(TRANSLOCO_PREFIX + '.info')" fxFlex="100"></eg-alert>
  </form>
</div>
