import { Injectable } from '@angular/core';
import { CustomerState, CustomerStore } from './customer.store';
import { CustomerQuery } from './customer.query';
import { ContactModel, CustomerModel, CustomerService } from '@railmybox/api-user';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { arrayUpsert } from '@datorama/akita';

@Injectable({ providedIn: 'root' })
export class CustomerStoreService {
  constructor(private store: CustomerStore, private query: CustomerQuery, private customerService: CustomerService) {}

  async initializeCustomerInfo(): Promise<CustomerState> {
    const customer: CustomerModel = await this.customerService.getCustomer().toPromise();
    this.store.update({ ...customer });

    return customer;
  }

  updateCustomerState(customer: Partial<CustomerState>): Observable<CustomerState> {
    return this.customerService.updateCustomer(customer).pipe(tap((customerInfo) => this.store.update({ ...customerInfo })));
  }

  getCompanyContactList(): Observable<ContactModel[]> {
    this.customerService
      .listCompanyContacts()
      .pipe(tap((contacts: ContactModel[]) => this.store.update({ contacts })))
      .subscribe();
    return this.query.select('contacts');
  }

  inviteContact(mail: string) {
    return this.customerService.createInvitation(mail).toPromise();
  }

  resendInvitation(invitationKey: string) {
    return this.customerService.resendInvitation(invitationKey).toPromise();
  }

  updateContact(id: string, role: string) {
    return this.customerService.updateContact(id, role).toPromise();
  }

  /**
   * Retrieves the CustomerStoreQuery instance
   */
  getCustomerQuery(): CustomerQuery {
    return this.query;
  }

  getCustomerLegalTypeCollection(): Observable<any> {
    return this.customerService.listLegalTypes().pipe(
      map((legalTypes) => {
        return legalTypes.map((legalType) => {
          return { label: legalType.value, value: legalType.id };
        });
      })
    );
  }

  getCustomerProfileType(): Observable<any> {
    return this.customerService.listProfileTypes().pipe(
      map((profileTypes) => {
        return profileTypes.map((profileType) => {
          return { label: profileType.value, value: profileType.id };
        });
      })
    );
  }

  getCustomer(key: string) {
    return this.customerService.showCustomer(key).toPromise();
  }

  async activateContact(key: string): Promise<any> {
    return this.customerService
      .activateContact(key)
      .toPromise()
      .then((result) => {
        this.store.update((state) => {
          return {
            ...state,
            contacts: arrayUpsert(this.query.getContacts(), key, { active: true }),
          };
        });
      });
  }

  async deactivateContact(key: string): Promise<any> {
    return this.customerService
      .deactivateContact(key)
      .toPromise()
      .then((result) => {
        this.store.update((state) => {
          return {
            ...state,
            contacts: arrayUpsert(this.query.getContacts(), key, { active: false }),
          };
        });
      });
  }
}
