<div class="radio-select" *transloco="let translate">
  @for (item of items; track item) {
  <div
    class="radio-select__item"
    [class.radio-select__item--selected]="item.value === value"
    [fxFlex]="100 / items.length"
    fxLayout="row"
    fxLayoutAlign="center center"
    (click)="onItemSelect(item)"
  >
    <img
      [ngClass]="{ 'svg-to-light-grey': item.iconName === 'trip_drop' }"
      class="icon"
      src="/assets/icons/{{ item.iconName }}.svg"
      alt="{{ item.iconName }}"
    />
    <!-- @TODO: Add icons for edit and delete here -->
  </div>
  }
</div>
