import { Location } from '../location.model';

export const GROUPED_PLACES: Location[] = [
  {
    type: 'LOADPOINT',
    addresses: [
      {
        id: 1,
        type: 'LOADPOINT',
        sourceId: 'bd318cf2-05bd-448f-8916-558e28b58402',
        source: 'molestie hendrerit',
        street: '57 Welch Road',
        houseNumber: '1',
        zipCode: '50935',
        city: 'Köln',
        district: 'NW',
        countryCode: 'DE',
        latitude: 50.9210958,
        longitude: 6.8968829,
        formattedAddress: '#1, 57 Welch Road Köln, 50935, Germany',
      },
      {
        id: 2,
        type: 'LOADPOINT',
        sourceId: '606bfc38-ae04-4a29-b718-465e5fb50da8',
        source: 'amet cursus',
        street: '8 Mallory Drive',
        houseNumber: '024',
        zipCode: '10715',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4837296,
        longitude: 13.3304798,
        formattedAddress: '#024, 8 Mallory Drive Berlin, 10715, Germany',
      },
      {
        id: 3,
        type: 'LOADPOINT',
        sourceId: '0630b39a-908b-4ed2-bf19-6679456a19ff',
        source: 'ut massa',
        street: '332 Montana Drive',
        houseNumber: '561',
        zipCode: '20249',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.5900195,
        longitude: 9.9897946,
        formattedAddress: '#561, 332 Montana Drive Hamburg, 20249, Germany',
      },
      {
        id: 4,
        type: 'LOADPOINT',
        sourceId: '744920cc-6c0b-4985-8a05-41d3a9375bde',
        source: 'odio consequat',
        street: '3 Luster Parkway',
        houseNumber: '9200',
        zipCode: '01189',
        city: 'Dresden',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.0144934,
        longitude: 13.6972162,
        formattedAddress: '#9200, 3 Luster Parkway Dresden, 01189, Germany',
      },
      {
        id: 5,
        type: 'LOADPOINT',
        sourceId: 'dd0d2910-acc8-492a-a1f2-7dc12b363c5e',
        source: 'pellentesque',
        street: '49 Crowley Terrace',
        houseNumber: '6',
        zipCode: '38126',
        city: 'Braunschweig',
        district: 'NI',
        countryCode: 'DE',
        latitude: 52.2346447,
        longitude: 10.5665003,
        formattedAddress: '#6, 49 Crowley Terrace Braunschweig, 38126, Germany',
      },
      {
        id: 6,
        type: 'LOADPOINT',
        sourceId: '26bc4935-61e9-4c39-9950-dea358445b3f',
        source: 'quis augue',
        street: '452 Larry Crossing',
        houseNumber: '4',
        zipCode: '12045',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4867189,
        longitude: 13.4343715,
        formattedAddress: '#4, 452 Larry Crossing Berlin, 12045, Germany',
      },
      {
        id: 7,
        type: 'LOADPOINT',
        sourceId: '4d7c4aed-8810-49fd-bc59-18edf0ce0569',
        source: 'vel est donec',
        street: '44085 Badeau Place',
        houseNumber: '84292',
        zipCode: '67067',
        city: 'Ludwigshafen am Rhein',
        district: 'RP',
        countryCode: 'DE',
        latitude: 49.4603474,
        longitude: 8.402137,
        formattedAddress: '#84292, 44085 Badeau Place Ludwigshafen am Rhein, 67067, Germany',
      },
      {
        id: 8,
        type: 'LOADPOINT',
        sourceId: '21160534-499c-487c-af14-1debc7ba3d2b',
        source: 'pellentesque quisque',
        street: '18284 Ramsey Park',
        houseNumber: '4',
        zipCode: '59071',
        city: 'Hamm',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.6813704,
        longitude: 7.9085254,
        formattedAddress: '#4, 18284 Ramsey Park Hamm, 59071, Germany',
      },
      {
        id: 9,
        type: 'LOADPOINT',
        sourceId: 'fc5253bd-a50e-4092-be8a-4795fe2f0c27',
        source: 'semper est quam',
        street: '62 Del Sol Place',
        houseNumber: '6',
        zipCode: '30167',
        city: 'Hannover',
        district: 'NI',
        countryCode: 'DE',
        latitude: 52.3863062,
        longitude: 9.7156831,
        formattedAddress: '#6, 62 Del Sol Place Hannover, 30167, Germany',
      },
      {
        id: 10,
        type: 'LOADPOINT',
        sourceId: '6063ae62-f3e4-4aa5-8b84-9370b75b20be',
        source: 'nulla suscipit',
        street: '3 Melody Way',
        houseNumber: '028',
        zipCode: '12103',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4639426,
        longitude: 13.3728893,
        formattedAddress: '#028, 3 Melody Way Berlin, 12103, Germany',
      },
      {
        id: 11,
        type: 'LOADPOINT',
        sourceId: 'd3585ffa-07a0-4af2-a9b3-b8aa3aeb51aa',
        source: 'eleifend pede',
        street: '82669 Sheridan Plaza',
        houseNumber: '63',
        zipCode: '63456',
        city: 'Hanau',
        district: 'HE',
        countryCode: 'DE',
        latitude: 50.104315,
        longitude: 8.911141,
        formattedAddress: '#63, 82669 Sheridan Plaza Hanau, 63456, Germany',
      },
      {
        id: 12,
        type: 'LOADPOINT',
        sourceId: '42746fa3-4dac-46f2-8874-c9065f0d2293',
        source: 'cras pellentesque',
        street: '5 Bashford Place',
        houseNumber: '613',
        zipCode: '52080',
        city: 'Aachen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 50.7925769,
        longitude: 6.1597163,
        formattedAddress: '#613, 5 Bashford Place Aachen, 52080, Germany',
      },
      {
        id: 13,
        type: 'LOADPOINT',
        sourceId: '2dc5ae10-94af-4144-90a4-7c9e5b32e3ef',
        source: 'eros suspendisse',
        street: '8 Rockefeller Place',
        houseNumber: '02',
        zipCode: '24109',
        city: 'Kiel',
        district: 'SH',
        countryCode: 'DE',
        latitude: 54.3171885,
        longitude: 10.0477345,
        formattedAddress: '#02, 8 Rockefeller Place Kiel, 24109, Germany',
      },
      {
        id: 14,
        type: 'LOADPOINT',
        sourceId: '679090ba-b981-4e32-8ea8-585a9f1f6caa',
        source: 'ut suscipit a',
        street: '275 Transport Point',
        houseNumber: '07',
        zipCode: '55124',
        city: 'Mainz',
        district: 'RP',
        countryCode: 'DE',
        latitude: 50.0022253,
        longitude: 8.1937267,
        formattedAddress: '#07, 275 Transport Point Mainz, 55124, Germany',
      },
      {
        id: 15,
        type: 'LOADPOINT',
        sourceId: '7cee1e0d-1463-45ca-a44a-3f1d81b78763',
        source: 'ipsum primis in',
        street: '14 Mifflin Park',
        houseNumber: '12270',
        zipCode: '18147',
        city: 'Rostock',
        district: 'MV',
        countryCode: 'DE',
        latitude: 54.1463269,
        longitude: 12.1186173,
        formattedAddress: '#12270, 14 Mifflin Park Rostock, 18147, Germany',
      },
      {
        id: 16,
        type: 'LOADPOINT',
        sourceId: '033aae8f-04dc-40d8-90a9-bfe76d77ff23',
        source: 'amet nunc',
        street: '9655 Browning Plaza',
        houseNumber: '834',
        zipCode: '90411',
        city: 'Nürnberg',
        district: 'BY',
        countryCode: 'DE',
        latitude: 49.4904742,
        longitude: 11.0986834,
        formattedAddress: '#834, 9655 Browning Plaza Nürnberg, 90411, Germany',
      },
      {
        id: 17,
        type: 'LOADPOINT',
        sourceId: '427b79ff-f4be-49e7-b5f3-7c1d4173b875',
        source: 'diam in magna',
        street: '2229 Mcguire Parkway',
        houseNumber: '66156',
        zipCode: '12559',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.416925,
        longitude: 13.6492904,
        formattedAddress: '#66156, 2229 Mcguire Parkway Berlin, 12559, Germany',
      },
      {
        id: 18,
        type: 'LOADPOINT',
        sourceId: '9d034eb9-c2d1-4967-8040-a8feb41125c2',
        source: 'ac enim',
        street: '77 Sloan Place',
        houseNumber: '751',
        zipCode: '41199',
        city: 'Mönchengladbach',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1329676,
        longitude: 6.4449432,
        formattedAddress: '#751, 77 Sloan Place Mönchengladbach, 41199, Germany',
      },
      {
        id: 19,
        type: 'LOADPOINT',
        sourceId: '7902722a-f5ce-4c48-955f-9f8a81844877',
        source: 'potenti cras in',
        street: '2 Killdeer Park',
        houseNumber: '96',
        zipCode: '45149',
        city: 'Essen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.4188047,
        longitude: 6.9643749,
        formattedAddress: '#96, 2 Killdeer Park Essen, 45149, Germany',
      },
      {
        id: 20,
        type: 'LOADPOINT',
        sourceId: 'b3f88cfe-1ebf-430a-902d-1b013ebacd0e',
        source: 'at ipsum ac',
        street: '605 Kenwood Parkway',
        houseNumber: '4839',
        zipCode: '70567',
        city: 'Stuttgart',
        district: 'BW',
        countryCode: 'DE',
        latitude: 48.7226206,
        longitude: 9.1584836,
        formattedAddress: '#4839, 605 Kenwood Parkway Stuttgart, 70567, Germany',
      },
      {
        id: 21,
        type: 'LOADPOINT',
        sourceId: 'a1cd7bf3-8f61-4a8c-a25b-d98b56eefa97',
        source: 'augue vestibulum',
        street: '68 Springs Alley',
        houseNumber: '9514',
        zipCode: '10409',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5433958,
        longitude: 13.4413606,
        formattedAddress: '#9514, 68 Springs Alley Berlin, 10409, Germany',
      },
      {
        id: 22,
        type: 'LOADPOINT',
        sourceId: '0eef3607-fb5b-459c-a52d-e6421eec9ea7',
        source: 'felis donec',
        street: '0443 Farwell Plaza',
        houseNumber: '7281',
        zipCode: '22339',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.6447228,
        longitude: 10.0383761,
        formattedAddress: '#7281, 0443 Farwell Plaza Hamburg, 22339, Germany',
      },
      {
        id: 23,
        type: 'LOADPOINT',
        sourceId: 'b255a3b5-7b2f-40a6-9e37-9a9adf220658',
        source: 'potenti in',
        street: '74731 Buell Parkway',
        houseNumber: '60',
        zipCode: '22453',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.6125259,
        longitude: 9.9659855,
        formattedAddress: '#60, 74731 Buell Parkway Hamburg, 22453, Germany',
      },
      {
        id: 24,
        type: 'LOADPOINT',
        sourceId: '7ce87f39-830c-45f7-b980-492d6d4d019c',
        source: 'nulla facilisi cras',
        street: '7084 Huxley Way',
        houseNumber: '540',
        zipCode: '13409',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5693104,
        longitude: 13.3714743,
        formattedAddress: '#540, 7084 Huxley Way Berlin, 13409, Germany',
      },
      {
        id: 25,
        type: 'LOADPOINT',
        sourceId: 'b213f1d9-6288-490b-a730-4db66c09b049',
        source: 'nulla ultrices',
        street: '96061 Warbler Crossing',
        houseNumber: '1945',
        zipCode: '55124',
        city: 'Mainz',
        district: 'RP',
        countryCode: 'DE',
        latitude: 50.0022253,
        longitude: 8.1937267,
        formattedAddress: '#1945, 96061 Warbler Crossing Mainz, 55124, Germany',
      },
      {
        id: 26,
        type: 'LOADPOINT',
        sourceId: 'be1fef83-d713-47fc-9849-c3214019c58d',
        source: 'mi integer ac',
        street: '8502 Milwaukee Circle',
        houseNumber: '2338',
        zipCode: '09123',
        city: 'Chemnitz',
        district: 'SN',
        countryCode: 'DE',
        latitude: 50.7868675,
        longitude: 12.8945945,
        formattedAddress: '#2338, 8502 Milwaukee Circle Chemnitz, 09123, Germany',
      },
      {
        id: 27,
        type: 'LOADPOINT',
        sourceId: '69aa4da5-1390-4f82-91a7-b5ae0a0319a2',
        source: 'semper sapien a',
        street: '45509 Shoshone Avenue',
        houseNumber: '326',
        zipCode: '37217',
        city: 'Witzenhausen',
        district: 'HE',
        countryCode: 'DE',
        latitude: 51.349366,
        longitude: 9.7730968,
        formattedAddress: '#326, 45509 Shoshone Avenue Witzenhausen, 37217, Germany',
      },
      {
        id: 28,
        type: 'LOADPOINT',
        sourceId: 'bdf97f15-46a3-47cf-8214-77e6835e0f2e',
        source: 'eleifend pede',
        street: '75 Stone Corner Crossing',
        houseNumber: '82056',
        zipCode: '42897',
        city: 'Remscheid',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1730117,
        longitude: 7.2822788,
        formattedAddress: '#82056, 75 Stone Corner Crossing Remscheid, 42897, Germany',
      },
      {
        id: 29,
        type: 'LOADPOINT',
        sourceId: '4c1223fe-2e87-49f8-8039-a367ee1d3eb7',
        source: 'sapien a libero',
        street: '31 Lerdahl Street',
        houseNumber: '0946',
        zipCode: '60435',
        city: 'Frankfurt am Main',
        district: 'HE',
        countryCode: 'DE',
        latitude: 50.1616215,
        longitude: 8.7032376,
        formattedAddress: '#0946, 31 Lerdahl Street Frankfurt am Main, 60435, Germany',
      },
      {
        id: 30,
        type: 'LOADPOINT',
        sourceId: '90a24eb7-5259-4940-bf58-d14ee0cb54ac',
        source: 'amet eros',
        street: '50 Farmco Way',
        houseNumber: '7317',
        zipCode: '70190',
        city: 'Stuttgart',
        district: 'BW',
        countryCode: 'DE',
        latitude: 48.7893977,
        longitude: 9.1962771,
        formattedAddress: '#7317, 50 Farmco Way Stuttgart, 70190, Germany',
      },
      {
        id: 31,
        type: 'LOADPOINT',
        sourceId: '8c3e1d5e-7350-4e91-8c28-1091834f9ced',
        source: 'vulputate nisl',
        street: '47 Loeprich Circle',
        houseNumber: '5008',
        zipCode: '45356',
        city: 'Essen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.4923815,
        longitude: 6.9694303,
        formattedAddress: '#5008, 47 Loeprich Circle Essen, 45356, Germany',
      },
      {
        id: 32,
        type: 'LOADPOINT',
        sourceId: 'c8ec310a-4eae-4acc-8dc8-80cb7a9f52eb',
        source: 'mi sit amet',
        street: '294 Grasskamp Lane',
        houseNumber: '2312',
        zipCode: '22179',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.607925,
        longitude: 10.0852568,
        formattedAddress: '#2312, 294 Grasskamp Lane Hamburg, 22179, Germany',
      },
      {
        id: 33,
        type: 'LOADPOINT',
        sourceId: 'eec27bd7-3207-4303-8028-7db8ccdec8e7',
        source: 'etiam etiam',
        street: '6468 Michigan Street',
        houseNumber: '6',
        zipCode: '04288',
        city: 'Leipzig',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.2928924,
        longitude: 12.4645518,
        formattedAddress: '#6, 6468 Michigan Street Leipzig, 04288, Germany',
      },
      {
        id: 34,
        type: 'LOADPOINT',
        sourceId: 'cefbf922-48d6-4958-9ef3-ab73de0d4fee',
        source: 'ligula eleifend',
        street: '1107 Tennessee Lane',
        houseNumber: '8734',
        zipCode: '44143',
        city: 'Dortmund',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.5195303,
        longitude: 7.5123813,
        formattedAddress: '#8734, 1107 Tennessee Lane Dortmund, 44143, Germany',
      },
      {
        id: 35,
        type: 'LOADPOINT',
        sourceId: '1baca288-f10f-493d-9e02-96c5aabe973b',
        source: 'bibendum orci',
        street: '1 Nobel Trail',
        houseNumber: '23084',
        zipCode: '13409',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5693104,
        longitude: 13.3714743,
        formattedAddress: '#23084, 1 Nobel Trail Berlin, 13409, Germany',
      },
      {
        id: 36,
        type: 'LOADPOINT',
        sourceId: '810c99a4-a0b2-4d4d-af18-993205899bc1',
        source: 'tristique tempus',
        street: '1831 Buell Circle',
        houseNumber: '1',
        zipCode: '68239',
        city: 'Mannheim',
        district: 'BW',
        countryCode: 'DE',
        latitude: 49.4637092,
        longitude: 8.560081,
        formattedAddress: '#1, 1831 Buell Circle Mannheim, 68239, Germany',
      },
      {
        id: 37,
        type: 'LOADPOINT',
        sourceId: 'dcfcdc6f-83e3-4dfc-a024-bef6be57caa1',
        source: 'in faucibus',
        street: '0 Old Shore Circle',
        houseNumber: '569',
        zipCode: '39130',
        city: 'Magdeburg',
        district: 'ST',
        countryCode: 'DE',
        latitude: 52.1557661,
        longitude: 11.5721966,
        formattedAddress: '#569, 0 Old Shore Circle Magdeburg, 39130, Germany',
      },
      {
        id: 38,
        type: 'LOADPOINT',
        sourceId: '0b8dff66-0165-4cec-8842-ebbf6cb8afa2',
        source: 'et ultrices posuere',
        street: '09 Bunker Hill Road',
        houseNumber: '27561',
        zipCode: '48161',
        city: 'Münster',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.9601912,
        longitude: 7.5468477,
        formattedAddress: '#27561, 09 Bunker Hill Road Münster, 48161, Germany',
      },
      {
        id: 39,
        type: 'LOADPOINT',
        sourceId: '9029aca8-a0f6-4a28-91d1-1785473b2e43',
        source: 'morbi lorem',
        street: '149 Coolidge Avenue',
        houseNumber: '3',
        zipCode: '51149',
        city: 'Köln',
        district: 'NW',
        countryCode: 'DE',
        latitude: 50.9093945,
        longitude: 7.0486698,
        formattedAddress: '#3, 149 Coolidge Avenue Köln, 51149, Germany',
      },
      {
        id: 40,
        type: 'LOADPOINT',
        sourceId: 'ea4d70bf-0d9a-40dc-974a-f1873a8f0888',
        source: 'in justo',
        street: '22199 Park Meadow Way',
        houseNumber: '734',
        zipCode: '63073',
        city: 'Offenbach',
        district: 'HE',
        countryCode: 'DE',
        latitude: 50.0809432,
        longitude: 8.8107219,
        formattedAddress: '#734, 22199 Park Meadow Way Offenbach, 63073, Germany',
      },
      {
        id: 41,
        type: 'LOADPOINT',
        sourceId: 'd997796b-0941-4970-9b41-405c681b1050',
        source: 'nam libero',
        street: '39985 Maywood Hill',
        houseNumber: '9751',
        zipCode: '80804',
        city: 'München',
        district: 'BY',
        countryCode: 'DE',
        latitude: 48.1700928,
        longitude: 11.5776824,
        formattedAddress: '#9751, 39985 Maywood Hill München, 80804, Germany',
      },
      {
        id: 42,
        type: 'LOADPOINT',
        sourceId: '5440b959-4248-4083-9430-aa2b08eddae5',
        source: 'sodales sed',
        street: '41515 Evergreen Center',
        houseNumber: '3513',
        zipCode: '42285',
        city: 'Wuppertal',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.256002,
        longitude: 7.1736304,
        formattedAddress: '#3513, 41515 Evergreen Center Wuppertal, 42285, Germany',
      },
      {
        id: 43,
        type: 'LOADPOINT',
        sourceId: '056c57ea-4589-483d-a3a1-479d7ce3d807',
        source: 'fermentum nec',
        street: '2 Fulton Crossing',
        houseNumber: '319',
        zipCode: '90411',
        city: 'Nürnberg',
        district: 'BY',
        countryCode: 'DE',
        latitude: 49.4904742,
        longitude: 11.0986834,
        formattedAddress: '#319, 2 Fulton Crossing Nürnberg, 90411, Germany',
      },
      {
        id: 44,
        type: 'LOADPOINT',
        sourceId: '728c1b2d-616d-4e5e-9b73-f6813f36d236',
        source: 'nunc turpis',
        street: '5963 Jenna Center',
        houseNumber: '186',
        zipCode: '48161',
        city: 'Münster',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.9601912,
        longitude: 7.5468477,
        formattedAddress: '#186, 5963 Jenna Center Münster, 48161, Germany',
      },
      {
        id: 45,
        type: 'LOADPOINT',
        sourceId: '76ca7442-8e87-4a65-8c2e-73f0e80b2a0f',
        source: 'vulputate landit',
        street: '98 Loftsgordon Lane',
        houseNumber: '2',
        zipCode: '45141',
        city: 'Essen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.4780256,
        longitude: 7.0328812,
        formattedAddress: '#2, 98 Loftsgordon Lane Essen, 45141, Germany',
      },
      {
        id: 46,
        type: 'LOADPOINT',
        sourceId: '81910c08-e371-45c2-a59c-d0f4fba547a2',
        source: 'tempus sit amet',
        street: '30995 Sundown Alley',
        houseNumber: '551',
        zipCode: '40225',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1974375,
        longitude: 6.7898817,
        formattedAddress: '#551, 30995 Sundown Alley Düsseldorf, 40225, Germany',
      },
      {
        id: 47,
        type: 'LOADPOINT',
        sourceId: 'e9de1670-ef2a-43d1-9294-56e947825e38',
        source: 'varius leo',
        street: '77 Crescent Oaks Street',
        houseNumber: '6783',
        zipCode: '80686',
        city: 'München',
        district: 'BY',
        countryCode: 'DE',
        latitude: 48.1288628,
        longitude: 11.5089621,
        formattedAddress: '#6783, 77 Crescent Oaks Street München, 80686, Germany',
      },
      {
        id: 48,
        type: 'LOADPOINT',
        sourceId: '471ce852-f198-47ce-b9c4-f3b7b9325790',
        source: 'dignissim ante',
        street: '36 Nobel Center',
        houseNumber: '22501',
        zipCode: '40225',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1974375,
        longitude: 6.7898817,
        formattedAddress: '#22501, 36 Nobel Center Düsseldorf, 40225, Germany',
      },
      {
        id: 49,
        type: 'LOADPOINT',
        sourceId: '04a2ee4a-5997-4521-ae01-cf2f32d315b6',
        source: 'orci sapien',
        street: '46 Beilfuss Way',
        houseNumber: '8',
        zipCode: '10409',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5433958,
        longitude: 13.4413606,
        formattedAddress: '#8, 46 Beilfuss Way Berlin, 10409, Germany',
      },
      {
        id: 50,
        type: 'LOADPOINT',
        sourceId: 'aa1e6fd9-9619-414d-b750-5c19b44ff3da',
        source: 'cubilia nulla',
        street: '2771 Saint Paul Street',
        houseNumber: '170',
        zipCode: '13409',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5693104,
        longitude: 13.3714743,
        formattedAddress: '#170, 2771 Saint Paul Street Berlin, 13409, Germany',
      },
    ],
  },
  {
    type: 'STATION',
    addresses: [
      {
        id: 51,
        type: 'STATION',
        sourceId: '237c3832-d87a-4583-accf-3d1fd1a294a6',
        source: 'nunc purus in',
        street: '33789 Thompson Court',
        houseNumber: '10964',
        zipCode: '45886',
        city: 'Gelsenkirchen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.4964248,
        longitude: 7.1215271,
        formattedAddress: '#10964, 33789 Thompson Court Gelsenkirchen, 45886, Germany',
      },
      {
        id: 52,
        type: 'STATION',
        sourceId: 'c8c3ed9b-2680-40a5-92da-b30b15f7a537',
        source: 'sapien a libero',
        street: '1 Dawn Parkway',
        houseNumber: '900',
        zipCode: '22179',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.607925,
        longitude: 10.0852568,
        formattedAddress: '#900, 1 Dawn Parkway Hamburg, 22179, Germany',
      },
      {
        id: 53,
        type: 'STATION',
        sourceId: 'bcb23cdb-3502-489c-9e4d-f79d9ead24e8',
        source: 'luctus et ultrices',
        street: '1902 Mosinee Park',
        houseNumber: '723',
        zipCode: '01189',
        city: 'Dresden',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.0144934,
        longitude: 13.6972162,
        formattedAddress: '#723, 1902 Mosinee Park Dresden, 01189, Germany',
      },
      {
        id: 54,
        type: 'STATION',
        sourceId: 'e2ba43d1-7aa1-4ec7-9c37-b5d9b5c72b69',
        source: 'in tempor turpis',
        street: '25 Fisk Drive',
        houseNumber: '9675',
        zipCode: '40225',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1974375,
        longitude: 6.7898817,
        formattedAddress: '#9675, 25 Fisk Drive Düsseldorf, 40225, Germany',
      },
      {
        id: 55,
        type: 'STATION',
        sourceId: 'f92079e0-4bb4-4831-b606-1fb342b88564',
        source: 'tristique congue diam',
        street: '71185 Meadow Vale Place',
        houseNumber: '02872',
        zipCode: '13599',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5485032,
        longitude: 13.2385645,
        formattedAddress: '#02872, 71185 Meadow Vale Place Berlin, 13599, Germany',
      },
      {
        id: 56,
        type: 'STATION',
        sourceId: '080b409c-5c4f-4ff8-9ec7-ec524e1fa5e5',
        source: 'ante justo aliquam',
        street: '6 Talmadge Terrace',
        houseNumber: '124',
        zipCode: '97078',
        city: 'Würzburg',
        district: 'BY',
        countryCode: 'DE',
        latitude: 49.8180135,
        longitude: 9.9637931,
        formattedAddress: '#124, 6 Talmadge Terrace Würzburg, 97078, Germany',
      },
      {
        id: 57,
        type: 'STATION',
        sourceId: 'e041b978-7d6b-4f87-bf1a-366e38fdcad2',
        source: 'molestie at',
        street: '14 Algoma Road',
        houseNumber: '80176',
        zipCode: '34132',
        city: 'Kassel',
        district: 'HE',
        countryCode: 'DE',
        latitude: 51.2804955,
        longitude: 9.4266475,
        formattedAddress: '#80176, 14 Algoma Road Kassel, 34132, Germany',
      },
      {
        id: 58,
        type: 'STATION',
        sourceId: '0a99469f-eefc-4d63-9c11-571ee26bd1de',
        source: 'suspendisse porttitor',
        street: '93 Sugar Street',
        houseNumber: '2052',
        zipCode: '40591',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1879547,
        longitude: 6.8173593,
        formattedAddress: '#2052, 93 Sugar Street Düsseldorf, 40591, Germany',
      },
      {
        id: 59,
        type: 'STATION',
        sourceId: 'ad3465b4-3f05-4ce0-8c07-34770c4a3315',
        source: 'vivamus sagittis',
        street: '75196 Hooker Parkway',
        houseNumber: '3918',
        zipCode: '40591',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1879547,
        longitude: 6.8173593,
        formattedAddress: '#3918, 75196 Hooker Parkway Düsseldorf, 40591, Germany',
      },
      {
        id: 60,
        type: 'STATION',
        sourceId: '1e7c04e7-07e9-412e-ba3d-2793dfdfa68e',
        source: 'enim amet',
        street: '952 Evergreen Drive',
        houseNumber: '11811',
        zipCode: '80337',
        city: 'München',
        district: 'BY',
        countryCode: 'DE',
        latitude: 48.1251024,
        longitude: 11.5584376,
        formattedAddress: '#11811, 952 Evergreen Drive München, 80337, Germany',
      },
      {
        id: 61,
        type: 'STATION',
        sourceId: '3e66f0fa-3b79-4f40-8629-d8aa5df6aaac',
        source: 'non aliquam sit',
        street: '5 Forster Crossing',
        houseNumber: '2',
        zipCode: '70180',
        city: 'Stuttgart Stuttgart-Mitte',
        district: 'BW',
        countryCode: 'DE',
        latitude: 48.7643143,
        longitude: 9.1752629,
        formattedAddress: '#2, 5 Forster Crossing Stuttgart Stuttgart-Mitte, 70180, Germany',
      },
      {
        id: 62,
        type: 'STATION',
        sourceId: '17f41062-dcbf-4bda-b0d6-9c1e04de9ae6',
        source: 'proin porttitor',
        street: '70137 Welch Plaza',
        houseNumber: '46',
        zipCode: '40489',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.3305246,
        longitude: 6.7625867,
        formattedAddress: '#46, 70137 Welch Plaza Düsseldorf, 40489, Germany',
      },
      {
        id: 63,
        type: 'STATION',
        sourceId: '34f108a5-346e-4c6e-907c-8aa00e3d0dba',
        source: 'nulla ullamcorper',
        street: '3 Buena Vista Plaza',
        houseNumber: '8',
        zipCode: '10715',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4837296,
        longitude: 13.3304798,
        formattedAddress: '#8, 3 Buena Vista Plaza Berlin, 10715, Germany',
      },
      {
        id: 64,
        type: 'STATION',
        sourceId: '1fca5c4c-6719-45fc-9910-3de14273f589',
        source: 'ligula morbi',
        street: '724 Washington Hill',
        houseNumber: '2577',
        zipCode: '44629',
        city: 'Herne',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.5491768,
        longitude: 7.2092264,
        formattedAddress: '#2577, 724 Washington Hill Herne, 44629, Germany',
      },
      {
        id: 65,
        type: 'STATION',
        sourceId: 'be30e364-c2bb-4f4c-92c1-07c3fbcf56b1',
        source: 'in platea',
        street: '9730 Kinsman Trail',
        houseNumber: '1182',
        zipCode: '12307',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.3824851,
        longitude: 13.3993839,
        formattedAddress: '#1182, 9730 Kinsman Trail Berlin, 12307, Germany',
      },
      {
        id: 66,
        type: 'STATION',
        sourceId: 'ac61619a-6ff2-4885-8087-bd823632899f',
        source: 'sed at nunc',
        street: '47059 Packers Terrace',
        houseNumber: '81',
        zipCode: '45149',
        city: 'Essen',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.4188047,
        longitude: 6.9643749,
        formattedAddress: '#81, 47059 Packers Terrace Essen, 45149, Germany',
      },
      {
        id: 67,
        type: 'STATION',
        sourceId: 'ba3ebfa8-cfa3-47c9-ae48-ef07fb1b3376',
        source: 'fermentum condimentum',
        street: '81 Atwood Place',
        houseNumber: '7088',
        zipCode: '37217',
        city: 'Witzenhausen',
        district: 'HE',
        countryCode: 'DE',
        latitude: 51.349366,
        longitude: 9.7730968,
        formattedAddress: '#7088, 81 Atwood Place Witzenhausen, 37217, Germany',
      },
      {
        id: 68,
        type: 'STATION',
        sourceId: '8abb1c49-309d-4310-841a-5051aac5efa4',
        source: 'aliquet non',
        street: '78866 Memorial Pass',
        houseNumber: '03',
        zipCode: '20459',
        city: 'Hamburg Sankt Pauli',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.5472987,
        longitude: 9.9778279,
        formattedAddress: '#03, 78866 Memorial Pass Hamburg Sankt Pauli, 20459, Germany',
      },
      {
        id: 69,
        type: 'STATION',
        sourceId: 'f421ee0a-a395-4484-92a1-62e90a4a4671',
        source: 'quam sapien varius',
        street: '8179 Susan Alley',
        houseNumber: '98',
        zipCode: '01156',
        city: 'Dresden',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.085544,
        longitude: 13.6276849,
        formattedAddress: '#98, 8179 Susan Alley Dresden, 01156, Germany',
      },
      {
        id: 70,
        type: 'STATION',
        sourceId: '6cb0a224-2463-49c2-82f9-2dde9112e3d5',
        source: 'rhoncus sed vestibulum',
        street: '4 Bashford Crossing',
        houseNumber: '689',
        zipCode: '10587',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.5170826,
        longitude: 13.3236611,
        formattedAddress: '#689, 4 Bashford Crossing Berlin, 10587, Germany',
      },
      {
        id: 71,
        type: 'STATION',
        sourceId: '1eff157c-619e-4517-aa79-a40198d3bf95',
        source: 'diam vitae quam',
        street: '431 Blackbird Lane',
        houseNumber: '031',
        zipCode: '72768',
        city: 'Reutlingen',
        district: 'BW',
        countryCode: 'DE',
        latitude: 48.5373555,
        longitude: 9.2004305,
        formattedAddress: '#031, 431 Blackbird Lane Reutlingen, 72768, Germany',
      },
      {
        id: 72,
        type: 'STATION',
        sourceId: 'a69096a9-93b7-4383-a3e9-2da62d7367ae',
        source: 'vel sem sed',
        street: '50106 Grim Center',
        houseNumber: '7',
        zipCode: '76199',
        city: 'Karlsruhe',
        district: 'BW',
        countryCode: 'DE',
        latitude: 48.9728482,
        longitude: 8.4060268,
        formattedAddress: '#7, 50106 Grim Center Karlsruhe, 76199, Germany',
      },
      {
        id: 73,
        type: 'STATION',
        sourceId: '0dbefc26-98cb-40f1-b12a-a1ac64828e5c',
        source: 'sagittis sociis',
        street: '339 Hooker Center',
        houseNumber: '1840',
        zipCode: '12307',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.3824851,
        longitude: 13.3993839,
        formattedAddress: '#1840, 339 Hooker Center Berlin, 12307, Germany',
      },
      {
        id: 74,
        type: 'STATION',
        sourceId: 'b8d57966-b3f3-4763-a796-d0ca70ba84e2',
        source: 'donec vestibulum',
        street: '42 Ronald Regan Crossing',
        houseNumber: '255',
        zipCode: '81679',
        city: 'München',
        district: 'BY',
        countryCode: 'DE',
        latitude: 48.1440135,
        longitude: 11.6074275,
        formattedAddress: '#255, 42 Ronald Regan Crossing München, 81679, Germany',
      },
      {
        id: 75,
        type: 'STATION',
        sourceId: '439d47cc-1816-4cde-8ea4-b26f7dbb1f96',
        source: 'dui vel nisl',
        street: '00 Raven Way',
        houseNumber: '91',
        zipCode: '12307',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.3824851,
        longitude: 13.3993839,
        formattedAddress: '#91, 00 Raven Way Berlin, 12307, Germany',
      },
      {
        id: 76,
        type: 'STATION',
        sourceId: '8749d9ed-d0fb-4e43-9612-3b015edb1ff1',
        source: 'felis posuere',
        street: '42726 Mallard Court',
        houseNumber: '1',
        zipCode: '27576',
        city: 'Bremerhaven',
        district: 'HB',
        countryCode: 'DE',
        latitude: 53.5567847,
        longitude: 8.5929499,
        formattedAddress: '#1, 42726 Mallard Court Bremerhaven, 27576, Germany',
      },
      {
        id: 77,
        type: 'STATION',
        sourceId: '44d7e173-ff5d-44ac-ba0c-3a4273bada15',
        source: 'eleifend ut',
        street: '25049 Gerald Circle',
        houseNumber: '54',
        zipCode: '44795',
        city: 'Bochum',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.449197,
        longitude: 7.1957779,
        formattedAddress: '#54, 25049 Gerald Circle Bochum, 44795, Germany',
      },
      {
        id: 78,
        type: 'STATION',
        sourceId: '66b0eb2e-8375-4f3c-b349-abcd28153f32',
        source: 'tristique fusce congue',
        street: '39073 Brentwood Parkway',
        houseNumber: '89',
        zipCode: '04317',
        city: 'Leipzig',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.3303754,
        longitude: 12.40504,
        formattedAddress: '#89, 39073 Brentwood Parkway Leipzig, 04317, Germany',
      },
      {
        id: 79,
        type: 'STATION',
        sourceId: '84c1e423-bc63-42d8-b20e-a3a46b79643e',
        source: 'faucibus luctus',
        street: '1341 Golden Leaf Lane',
        houseNumber: '5886',
        zipCode: '66119',
        city: 'Saarbrücken',
        district: 'SL',
        countryCode: 'DE',
        latitude: 49.2088164,
        longitude: 7.0075837,
        formattedAddress: '#5886, 1341 Golden Leaf Lane Saarbrücken, 66119, Germany',
      },
      {
        id: 80,
        type: 'STATION',
        sourceId: '2b15a38e-110b-494e-9c57-6762114da4f0',
        source: 'aenean pellentesque',
        street: '1 Valley Edge Point',
        houseNumber: '1',
        zipCode: '55124',
        city: 'Mainz',
        district: 'RP',
        countryCode: 'DE',
        latitude: 50.0022253,
        longitude: 8.1937267,
        formattedAddress: '#1, 1 Valley Edge Point Mainz, 55124, Germany',
      },
      {
        id: 81,
        type: 'STATION',
        sourceId: '2c22b72d-2092-4532-b29f-8a368a290b7a',
        source: 'turpis massa',
        street: '9 Di Loreto Avenue',
        houseNumber: '7',
        zipCode: '27576',
        city: 'Bremerhaven',
        district: 'HB',
        countryCode: 'DE',
        latitude: 53.5567847,
        longitude: 8.5929499,
        formattedAddress: '#7, 9 Di Loreto Avenue Bremerhaven, 27576, Germany',
      },
      {
        id: 82,
        type: 'STATION',
        sourceId: '239e751a-329d-4f1a-be8d-01a070c4d4d4',
        source: 'vel amet',
        street: '172 Scofield Alley',
        houseNumber: '1',
        zipCode: '28239',
        city: 'Bremen',
        district: 'HB',
        countryCode: 'DE',
        latitude: 53.132048,
        longitude: 8.7398289,
        formattedAddress: '#1, 172 Scofield Alley Bremen, 28239, Germany',
      },
      {
        id: 83,
        type: 'STATION',
        sourceId: '2b6e7a98-1305-43cd-b522-f8cb0f96f312',
        source: 'lacinia libero',
        street: '95540 Darwin Park',
        houseNumber: '9250',
        zipCode: '12045',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4867189,
        longitude: 13.4343715,
        formattedAddress: '#9250, 95540 Darwin Park Berlin, 12045, Germany',
      },
      {
        id: 84,
        type: 'STATION',
        sourceId: 'a61f0513-5220-47cd-977b-7e15bb3e148f',
        source: 'duis aliquam nunc',
        street: '18 Fieldstone Point',
        houseNumber: '7',
        zipCode: '40591',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1879547,
        longitude: 6.8173593,
        formattedAddress: '#7, 18 Fieldstone Point Düsseldorf, 40591, Germany',
      },
      {
        id: 85,
        type: 'STATION',
        sourceId: 'e66112f0-b8fa-462a-850c-cc5b82bebb09',
        source: 'adipiscing lorem vitae',
        street: '41 Acker Trail',
        houseNumber: '095',
        zipCode: '01189',
        city: 'Dresden',
        district: 'SN',
        countryCode: 'DE',
        latitude: 51.0144934,
        longitude: 13.6972162,
        formattedAddress: '#095, 41 Acker Trail Dresden, 01189, Germany',
      },
      {
        id: 86,
        type: 'STATION',
        sourceId: '32fa5a71-621d-435a-be95-c289270ce16d',
        source: 'libero ut volutpat',
        street: '991 Sloan Hill',
        houseNumber: '47596',
        zipCode: '41199',
        city: 'Mönchengladbach',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1329676,
        longitude: 6.4449432,
        formattedAddress: '#47596, 991 Sloan Hill Mönchengladbach, 41199, Germany',
      },
      {
        id: 87,
        type: 'STATION',
        sourceId: '2035efd9-9bb6-4ffe-9dc0-cd45a55d7180',
        source: 'ante ante',
        street: '84 Karstens Way',
        houseNumber: '53',
        zipCode: '42897',
        city: 'Remscheid',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1730117,
        longitude: 7.2822788,
        formattedAddress: '#53, 84 Karstens Way Remscheid, 42897, Germany',
      },
      {
        id: 88,
        type: 'STATION',
        sourceId: '43cbb8ba-1f7a-4777-9f12-3ce36536db5b',
        source: 'ante ipsum primis',
        street: '095 Spaight Park',
        houseNumber: '7',
        zipCode: '22179',
        city: 'Hamburg Bramfeld',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.607925,
        longitude: 10.0852568,
        formattedAddress: '#7, 095 Spaight Park Hamburg Bramfeld, 22179, Germany',
      },
      {
        id: 89,
        type: 'STATION',
        sourceId: '822bb4d9-c989-4756-a252-c19fc17a7477',
        source: 'augue quam',
        street: '7516 Lunder Road',
        houseNumber: '918',
        zipCode: '22111',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.5483785,
        longitude: 10.078217,
        formattedAddress: '#918, 7516 Lunder Road Hamburg, 22111, Germany',
      },
      {
        id: 90,
        type: 'STATION',
        sourceId: 'a3c2d4e9-3235-46f8-a68d-29750191494d',
        source: 'id mauris',
        street: '0 Esker Hill',
        houseNumber: '87037',
        zipCode: '59071',
        city: 'Hamm',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.6813704,
        longitude: 7.9085254,
        formattedAddress: '#87037, 0 Esker Hill Hamm, 59071, Germany',
      },
      {
        id: 91,
        type: 'STATION',
        sourceId: 'ae9519d5-c573-4014-9263-784b831cc106',
        source: 'odio odio elementum',
        street: '0591 South Terrace',
        houseNumber: '4934',
        zipCode: '12437',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.4609077,
        longitude: 13.4815474,
        formattedAddress: '#4934, 0591 South Terrace Berlin, 12437, Germany',
      },
      {
        id: 92,
        type: 'STATION',
        sourceId: 'a3bcaa75-ac2b-42ea-9f9d-ed5799b0a5d4',
        source: 'morbi aliquam',
        street: '2528 Almo Way',
        houseNumber: '876',
        zipCode: '40591',
        city: 'Düsseldorf',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.1879547,
        longitude: 6.8173593,
        formattedAddress: '#876, 2528 Almo Way Düsseldorf, 40591, Germany',
      },
      {
        id: 93,
        type: 'STATION',
        sourceId: '7c205ee2-1ace-4d5f-85c2-43a1d4c25712',
        source: 'ligula ornare',
        street: '4677 Manitowish Street',
        houseNumber: '4',
        zipCode: '14109',
        city: 'Berlin',
        district: 'BE',
        countryCode: 'DE',
        latitude: 52.415113,
        longitude: 13.1468678,
        formattedAddress: '#4, 4677 Manitowish Street Berlin, 14109, Germany',
      },
      {
        id: 94,
        type: 'STATION',
        sourceId: 'a79ee3f3-dc46-4c27-acec-56f0f84860e2',
        source: 'volutpat quam pede',
        street: '1 Meadow Valley Way',
        houseNumber: '50',
        zipCode: '22111',
        city: 'Hamburg',
        district: 'HH',
        countryCode: 'DE',
        latitude: 53.5483785,
        longitude: 10.078217,
        formattedAddress: '#50, 1 Meadow Valley Way Hamburg, 22111, Germany',
      },
      {
        id: 95,
        type: 'STATION',
        sourceId: '5eea0d4e-126c-4eb7-974d-4a336478d9c2',
        source: 'cubilia nulla',
        street: '16928 8th Terrace',
        houseNumber: '5',
        zipCode: '06116',
        city: 'Halle',
        district: 'ST',
        countryCode: 'DE',
        latitude: 51.4787653,
        longitude: 12.0365785,
        formattedAddress: '#5, 16928 8th Terrace Halle, 06116, Germany',
      },
      {
        id: 96,
        type: 'STATION',
        sourceId: '7fd88805-23f5-4543-a14a-954812a6ffe4',
        source: 'vulputate tincidunt',
        street: '33937 Muir Way',
        houseNumber: '040',
        zipCode: '27576',
        city: 'Bremerhaven',
        district: 'HB',
        countryCode: 'DE',
        latitude: 53.5567847,
        longitude: 8.5929499,
        formattedAddress: '#040, 33937 Muir Way Bremerhaven, 27576, Germany',
      },
      {
        id: 97,
        type: 'STATION',
        sourceId: '3f97e3c4-b362-476b-a311-56e0ac6ff588',
        source: 'quam nullam',
        street: '96853 Morning Court',
        houseNumber: '6',
        zipCode: '18147',
        city: 'Rostock',
        district: 'MV',
        countryCode: 'DE',
        latitude: 54.1463269,
        longitude: 12.1186173,
        formattedAddress: '#6, 96853 Morning Court Rostock, 18147, Germany',
      },
      {
        id: 98,
        type: 'STATION',
        sourceId: 'a4ddb8f8-0846-4e38-a915-4b94262a7aae',
        source: 'dictumst etiam faucibus',
        street: '293 Dryden Plaza',
        houseNumber: '44241',
        zipCode: '44795',
        city: 'Bochum',
        district: 'NW',
        countryCode: 'DE',
        latitude: 51.449197,
        longitude: 7.1957779,
        formattedAddress: '#44241, 293 Dryden Plaza Bochum, 44795, Germany',
      },
      {
        id: 99,
        type: 'STATION',
        sourceId: 'ed74ae9e-6495-4dc2-b9ea-71f32747e03f',
        source: 'aliquet at non',
        street: '67222 Badeau Junction',
        houseNumber: '7785',
        zipCode: '37217',
        city: 'Witzenhausen',
        district: 'HE',
        countryCode: 'DE',
        latitude: 51.349366,
        longitude: 9.7730968,
        formattedAddress: '#7785, 67222 Badeau Junction Witzenhausen, 37217, Germany',
      },
      {
        id: 100,
        type: 'STATION',
        sourceId: 'b2541eb7-12ca-4d76-879a-f39f62e74166',
        source: 'pretium iaculis justo',
        street: '6969 Karstens Circle',
        houseNumber: '2399',
        zipCode: '51107',
        city: 'Köln',
        district: 'NW',
        countryCode: 'DE',
        latitude: 50.9173381,
        longitude: 7.0902515,
        formattedAddress: '#2399, 6969 Karstens Circle Köln, 51107, Germany',
      },
    ],
  },
];
