<div [style.width.px]="prefixContainerWidth" class="inline absolute input-prefix">
  <ng-content class="inline-content" select="[egFieldPrefixDirective]"></ng-content>
</div>

@if (type !== 'number') {
<input
  class="input"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [type]="type"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [autocomplete]="autocomplete"
  [disabled]="disabled"
  (change)="inputChanged()"
  (keydown)="handleKeyDown($event)"
  (keyup)="handleKeyUp($event)"
  (blur)="markInputAsTouched()"
/>
} @else {
<input
  class="input"
  type="text"
  mask="separator.2"
  [decimalMarker]="decimalMarker$ | async"
  [showMaskTyped]="false"
  [thousandSeparator]="inputSeparator$ | async"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [autocomplete]="autocomplete"
  [disabled]="disabled"
  (change)="inputChanged()"
  (keyup)="onNumberKeyup($event)"
  (blur)="markInputAsTouched()"
/>
}

<label [for]="id" [style.left.px]="prefixContainerWidth + prefixInputPadding" class="absolute input-label">
  {{ labelWithSuffix }}
</label>

<div class="inline absolute input-suffix">
  <ng-content select="[egFieldSuffixDirective]"></ng-content>
  <!--  <fa-icon-->
  <!--    class='error-mark'-->
  <!--    *ngIf='showErrorIcon'-->
  <!--  ></fa-icon>-->
</div>
@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
