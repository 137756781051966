import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryModel, CountryService } from '@railmybox/api-booking';
import { Country } from '../phone-number.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CountryCodeService {
  constructor(private httpClient: HttpClient, private countryService: CountryService) {}

  getCountryList(): Observable<Country[]> {
    return this.countryService.getCountries().pipe(
      map((countries: CountryModel[]) => {
        return countries
          .filter((country) => country.callingCodes[0])
          .map(
            (country) =>
              ({
                ...country,
                alpha2Code: country.id,
                translatedName: country.display,
              } as Country)
          );
      })
    );
  }
}
