import { Injectable } from '@angular/core';
import { BookingDocumentModel, DocumentService } from '@railmybox/api-booking';

@Injectable()
export class UploadDocManagementService {
  constructor(private documentsService: DocumentService) {}

  getDocuments(bookingRef: string, documentType: any, containerKey?: string): Promise<BookingDocumentModel[]> {
    if (containerKey) {
      return this.documentsService.getBookingContainerDocumentDetails(bookingRef, containerKey, [documentType]).toPromise();
    } else {
      return this.documentsService.getBookingDocumentDetails(bookingRef, [documentType]).toPromise();
    }
  }
}
