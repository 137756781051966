import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { map, switchMap } from 'rxjs/operators';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { CustomerGroupInfoModel, CustomerService } from '@railmybox/api-user';

@Component({
  selector: 'rmb-customer-group-select',
  templateUrl: './customer-group-select.component.html',
  styleUrls: ['./customer-group-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CustomerGroupSelectComponent)],
})
export class CustomerGroupSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  customerGroups$: Observable<CustomerGroupInfoModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = true;

  constructor(
    private customerService: CustomerService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.customerGroups$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.customerService.adminListCustomerGroups(query) : of([]))),
      map((x) => x.filter((item) => item.active === true)),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(model: CustomerGroupInfoModel): void {
    this.selectedName = model?.name;
    this.value = model?.id;
    this.onChange(model?.id);
    this.changed.emit(model?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      this.selectedName = null;
      return;
    }

    const model = await this.customerService
      .adminListCustomerGroups()
      .toPromise()
      .then((filteredValues) => filteredValues.find((model) => model.id === value));

    // In case no entry found with that id
    if (!model) {
      return;
    }

    this.value = model.id;
    this.selectedName = model.name;
  }
}
