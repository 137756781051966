import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { FormControl, ControlContainer } from '@angular/forms';
import { map, Observable, of, switchMap } from 'rxjs';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { StationService, StationModel } from '@railmybox/api-booking';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'rmb-station-select',
  templateUrl: './station-select.component.html',
  styleUrls: ['./station-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(StationSelectComponent)],
})
export class StationSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  stations$: Observable<StationModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedName: string;
  @Input() filter: string;
  @Input() selected: string;
  @Input() clearable = true;
  readonly TRANSLOCO_PREFIX = 'master.trains';

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    private stationService: StationService,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  async ngOnInit() {
    this.stations$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.stationService.adminListStations(query) : of([]))),
      map((x) => x.filter((item) => item.active === true)),
      map((stations: StationModel[]) =>
        this.filter ? stations.filter((model) => `${model.id} ${model.name}`.toLowerCase().includes(this.filter.toLowerCase())) : stations
      ),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(station: StationModel): void {
    this.selectedName = station?.name;
    this.value = station?.id;
    this.onChange(station?.id);
    this.changed.emit(station?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      this.selectedName = null;
      return;
    }
    const station = await this.stationService
      .adminListStations()
      .toPromise()
      .then((filteredValues) => filteredValues.find((station) => station.id === value));

    // In case no entry found with that id
    if (!station) {
      return;
    }

    this.value = station.id;
    this.selectedName = station.name;
  }
}
