<ng-template let-translate transloco>
  @if (showSupportNav) {
  <aside>
    <rmb-support-nav [pricingOrAccountingRole]="hasPricingOrAccountingPermission"></rmb-support-nav>
  </aside>
  }
  <main [ngClass]="{ support: showSupportNav }" class="main-container">
    <header class="header">
      @if (navState === 'LOGIN') {
      <div class="header__item">
        <figure class="logo">
          <img class="logo__img" src="/assets/images/logo.svg" />
        </figure>
      </div>
      <div class="header__item">
        <ul class="header-navigation">
          <li class="header-navigation__item">
            <a [routerLinkActive]="['active']" [routerLink]="['/', 'auth', 'login']" class="navigation-link">{{
              translate('login.btn.login')
            }}</a>
          </li>
          <li class="header-navigation__item">
            <span (click)="switchLanguageList()" class="navigation-link--span" id="switchLanguageList">{{ activeLang }} </span>
            @if (listShow) {
            <ul class="language-list">
              @for (lang of languageList; track lang) {
              <li class="language-list__item">
                @if (activeLang !== lang) {
                <a (click)="setActiveLang(lang)" class="navigation-link" [id]="lang">
                  {{ lang }}
                </a>
                }
              </li>
              }
            </ul>
            }
          </li>
        </ul>
      </div>
      } @if (navState === 'USER') {
      <div class="header__item">
        <figure class="logo">
          <a [routerLink]="['/', 'dashboard', 'bookings']">
            <img class="logo__img" src="/assets/images/logo.svg" />
          </a>
        </figure>
        <div fxFlex fxLayout="row" fxLayoutAlign="center center">
          <button
            [disabled]="!rmbAuth"
            egButton
            egType="round"
            egStyle="none"
            egSize="default"
            class="new-booking"
            (click)="onCreateBooking()"
          >
            <img
              [ngClass]="{ 'svg-to-light-grey': !rmbAuth, 'svg-to-primary-filter': rmbAuth }"
              alt="Add Icon"
              src="/assets/icons/field_plus.svg"
            />
            {{ translate('dashboard.booking.create') }}
          </button>
          @if (!rmbAuth) {
          <span [egTooltip]="translate('registration.inProcess')" position="right" type="info" class="account-hint">
            {{ translate('registration.title') }}</span
          >
          }
        </div>
      </div>
      <div class="header__item">
        <ul class="header-navigation">
          <!-- li class="header-navigation__item">
                <a
                  [routerLinkActive]="['active']" [routerLink]="['/', 'auth', 'sign-up']" class="navigation-link"
                  ><span class="navigation-icon search"></span
                ></a>
                </li -->
          <!--
                <li class="header-navigation__item">
                  <a
                    [routerLinkActive]="['active']" [routerLink]="['/', 'auth', 'login']" class="navigation-link"
                    ><span class="navigation-icon messages"></span
                  ></a>
                </li>
                -->
          <li class="header-navigation__item">
            <a [routerLink]="['/', 'profile', 'user']" [routerLinkActive]="['active']" class="navigation-link"
              ><span class="navigation-icon user"></span
            ></a>
          </li>
          <li class="header-navigation__item">
            <a [routerLink]="['/', 'wishlist', 'management']" [routerLinkActive]="['active']" class="navigation-link"
              ><span class="navigation-icon wishlist"></span
            ></a>
          </li>
          <li class="header-navigation__item">
            <a [routerLink]="['/', 'dashboard', 'templates']" [routerLinkActive]="['active']" class="navigation-link"
              ><span class="navigation-icon templates"></span
            ></a>
          </li>
          @if (hasSupportPermission) {
          <li class="header-navigation__item">
            <a [routerLinkActive]="['active']" [routerLink]="['/', 'support']" class="navigation-link">
              <span class="navigation-icon support"></span>
            </a>
          </li>
          }
          <li class="header-navigation__item">
            <a (click)="logout($event)" class="navigation-link">{{ translate('login.btn.logout') }}</a>
          </li>
          <li class="header-navigation__item">
            <span (click)="switchLanguageList()" class="navigation-link--span">{{ activeLang }} </span>
            @if (listShow) {
            <ul class="language-list">
              @for (lang of languageList; track lang) {
              <li class="language-list__item">
                @if (activeLang !== lang) {
                <a (click)="setActiveLang(lang)" class="navigation-link">
                  {{ lang }}
                </a>
                }
              </li>
              }
            </ul>
            }
          </li>
        </ul>
      </div>
      }
    </header>

    <section class="main-section">
      <article [class.dashboard-container]="isDashboardHidden" class="router-container">
        <router-outlet></router-outlet>
      </article>

      @if (!isDashboardHidden) {
      <aside class="sidebar">
        <div class="sidebar__content--empty"></div>
      </aside>
      }
    </section>
  </main>
</ng-template>
