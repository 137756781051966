import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, ViewEncapsulation } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'eg-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(UploadComponent)],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UploadComponent extends AbstractValueAccessorComponent<any> implements OnInit {
  readonly TRANSLOCO_PREFIX = 'upload';
  @Input() file: any;
  @Input() deletable = true;
  @Input() documentType: string;
  @Input() isDocumentScaning: boolean;
  @Output() deleteClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() downloadClicked: EventEmitter<string> = new EventEmitter<string>();
  selectedFileName: string;

  constructor(protected changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    if (this.file) {
      this.selectedFileName = this.file.documentKey;
    }
  }

  onChanged(event): Promise<void> {
    if (event.target.files.length === 0) {
      return;
    }

    this.value = event.target.files[0];
    this.selectedFileName = event.target.files[0].name;

    this.formControl?.setValue(this.value);
    this.changed.emit(this.value);
  }

  removeSelection(key: string): void {
    this.deleteClicked.emit(key);
    this.value = null;
    this.file = null;
  }

  download(key: string): void {
    this.downloadClicked.emit(key);
  }
}
