<div *transloco="let translate" class="template-card" fxFlex="100" fxLayout="row" fxLayoutAlign="start baseline" fxLayoutGap="20px">
  <div fxFlex="10" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-3">{{ translate(TRANSLOCO_PREFIX + 'shipmentDate') }}</span>
    <span class="subheading2">{{ wish.shipmentDate | egDate }}</span>
  </div>

  <div fxFlex="30" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-3">{{ translate(TRANSLOCO_PREFIX + 'waypoints.' + firstAddress?.type) }}</span>
    <span class="subheading2">{{ firstAddress?.formattedAddress }}</span>
  </div>

  <div fxFlex="30" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-3">{{ translate(TRANSLOCO_PREFIX + 'waypoints.' + secondAddress?.type) }}</span>
    <span class="subheading2">{{ secondAddress?.formattedAddress }}</span>
  </div>

  <div fxFlex="10" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-4">20' - {{ wish.num20FtTeu }}</span>
    <span class="subheading">40' - {{ wish.num40FtTeu }}</span>
    @if (wish.num45FtTeu > 0) {
    <span class="subheading mt-4">45' - {{ wish.num45FtTeu }}</span>
    }
  </div>

  <div fxFlex="30" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-3">{{ translate(TRANSLOCO_PREFIX + 'contact') }}</span>
    <span class="subheading2">{{ wish?.customerInfo.firstName }} {{ wish?.customerInfo.lastName }}</span>
  </div>

  @if (wish.notificationDateTime) {
  <div fxFlex="15" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-3">{{ translate(TRANSLOCO_PREFIX + 'notificationDateTime') }}</span>
    <span class="subheading2">{{ wish.notificationDateTime | egDate: 'date-time' }}</span>
  </div>
  }

  <div fxFlex="5" fxLayout="row" fxFlexAlign="center">
    <div fxLayout="row" fxLayoutAlign="start center" (click)="onDeleteClick(wish.id)">
      <img src="assets/icons/trash.svg" class="svg-to-primary-filter icon" alt="delete" />
    </div>
  </div>
</div>
