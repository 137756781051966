import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';
import { CostsTypeSelectComponent } from './costs-type-select.component';

@NgModule({
  declarations: [CostsTypeSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [CostsTypeSelectComponent],
})
export class CostsTypeSelectModule {}
