import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { CustomerState, CustomerStore } from './customer.store';
import { Observable } from 'rxjs';
import { ContactModel } from '@railmybox/api-user';

@Injectable({ providedIn: 'root' })
export class CustomerQuery extends Query<CustomerState> {
  constructor(protected store: CustomerStore) {
    super(store);
  }

  getRegistrationStep(): string {
    return this.getValue().registrationStep;
  }

  /**
   * Retrieves a single customer attribute from the store by name.
   */
  getCustomerAttribute(attribute: keyof CustomerState): any {
    return this.getValue()[attribute];
  }

  /**
   * Retrieves a customer from the store.
   */
  getCustomer(): any {
    return this.getValue();
  }

  getRmbAuth(): Observable<boolean> {
    return this.select('rmbAuthorized');
  }

  getContacts(): ContactModel[] {
    return this.getValue().contacts as ContactModel[];
  }
}
