import { Injectable } from '@angular/core';
import { IbanValidationResultModel } from '@railmybox/api-user/model/models';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserStore } from './user.store';
import { UserAttributes, UserState } from './user.state';
import { UserQuery } from './user.query';
import { FetchUserAttributesOutput, updatePassword, updateUserAttributes } from '@aws-amplify/auth';
import { BillingAddressModel, CustomerPaymentInfoModel, CustomerService } from '@railmybox/api-user';
import { isSet, pickByKeys } from '@railmybox/shared/util';

@Injectable({ providedIn: 'root' })
export class UserStoreService {
  constructor(private store: UserStore, private query: UserQuery, private customerService: CustomerService) {}

  /**
   * Updates the user in store
   */
  updateUserAttributes(attributes: FetchUserAttributesOutput): void {
    this.store.update({ attributes: attributes as UserAttributes });
  }

  updateUserState(user: Partial<UserState>): void {
    this.store.update({ ...user });
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return updatePassword({ oldPassword, newPassword });
  }

  async getUserAttributes(): Promise<UserAttributes> {
    if (!isSet(this.query.getUserAttribute('phoneNumber'))) {
      const customer = await this.customerService.getCustomer().toPromise();
      this.store.update((state) => ({ ...state, attributes: { ...state.attributes, ...customer } }));
    }

    return this.query.getAttributes();
  }

  async getBillingAddress(): Promise<BillingAddressModel> {
    const attributes = await this.getUserAttributes();

    return {
      id: attributes.companyAddress.id,
      customerName1: `${attributes.given_name} ${attributes.family_name}`,
      addressLine1: `${attributes.companyAddress.streetNumber} ${attributes.companyAddress.street}`,
      postalCode: attributes.companyAddress.zipCode,
      location: attributes.companyAddress.city,
      countryCode: attributes.companyAddress.countryCode,
      invoiceEmail: attributes.email,
    };
  }

  async updateProfile(user: Partial<UserAttributes>): Promise<void> {
    await updateUserAttributes({
      userAttributes: { ...pickByKeys(user, ['locale', 'given_name', 'family_name', 'email', 'custom:time_zone']) },
    });
    this.store.update((state) => ({ ...state, attributes: { ...state.attributes, ...user } }));
  }

  async updateCustomer(user: Partial<UserAttributes>): Promise<void> {
    const customer = this.customerService.updateCustomer(user).toPromise();
    this.store.update((state) => ({ ...state, attributes: { ...state.attributes, ...customer } }));
  }

  async getCustomerPayment(): Promise<CustomerPaymentInfoModel> {
    return this.customerService
      .getCustomerPayment()
      .pipe(
        map((paymentModel) => paymentModel),
        catchError((err) => {
          this.store.setError(err);
          return of(err);
        })
      )
      .toPromise();
  }

  async updateCustomerPayment(customerPaymentInfoModel: CustomerPaymentInfoModel): Promise<CustomerPaymentInfoModel> {
    return this.customerService
      .updateCustomerPayment(customerPaymentInfoModel)
      .pipe(
        map((paymentModel) => paymentModel),
        catchError((err) => {
          this.store.setError(err);
          return of(err);
        })
      )
      .toPromise();
  }

  /**
   * Retrieves the UserStoreQuery instance
   */
  getUserQuery(): UserQuery {
    return this.query;
  }

  validateIban(iban?: string): Observable<IbanValidationResultModel> {
    return this.customerService.validateIban({ iban });
  }
}
