<div *transloco="let translate" fxFlex="100" fxFlexFill fxLayout="column" class="problem-card problem-active">
  <div fxFlex="100" fxLayout="row" fxLayoutAlign="flex-start baseline" class="pb-6">
    <span class="status-header">{{ translate('problem.status.' + problem.status | lowercase) }}</span>
    <span class="px-5"> - </span>
    <span class="container-detail">
      {{ problem.containerId }}
    </span>
  </div>
  <div fxFlex="100" class="problem-information pb-6" fxLayout="row" fxLayoutGap="10px">
    <span>{{ translate('problem.category.' + problem.root | lowercase) }}</span>
    <span class="px-5">-</span>
    <span>{{ translate('problem.name.' + problem.name | lowercase) }}</span>
    <span>({{ problem.opened | egDate: 'date-time' }})</span>
  </div>
</div>
