<eg-input
  class="ngx-daterangepicker-action mb-0"
  [value]="formattedValue"
  [label]="labelWithSuffix"
  [name]="name"
  [placeholder]="placeholder"
  [readonly]="true"
  [disabled]="disabled"
  (click)="openDatePicker($event)"
  (blurred)="markInputAsTouched()"
>
  <img egFieldSuffixDirective src="/assets/icons/field_date.svg" class="date-icon ngx-daterangepicker-action" />
</eg-input>

<input
  ngxDaterangepickerMd
  class="date-range"
  [(ngModel)]="value"
  [dateLimit]="dateLimit || null"
  [linkedCalendars]="linkedCalendars"
  [closeOnAutoApply]="true"
  [placeholder]="placeholder"
  [id]="'dateRangeInput'"
  [opens]="dropdownAlign"
  [drops]="dropdownDirection"
  [autoApply]="true"
  [minDate]="minDate || null"
  [maxDate]="maxDate || null"
  [locale]="locale"
  [value]="value || null"
  [showClearButton]="showClearButton"
  (datesUpdated)="onInputChanged($event)"
/>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
