import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NumberSelectionComponent } from './number-selection.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NumberInputDirective } from './directives/number-input.directive';

@NgModule({
  declarations: [NumberSelectionComponent, NumberInputDirective],
  imports: [CommonModule, FlexLayoutModule],
  exports: [NumberSelectionComponent, NumberInputDirective],
})
export class NumberSelectionModule {}
