import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UserStore } from './user.store';
import { UserAttributes, UserState } from './user.state';
import { AddressModel } from '@railmybox/api-booking';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {
  constructor(protected store: UserStore) {
    super(store);
  }

  /**
   * Retrieves the user attributes from the store.
   */
  getAttributes(): UserAttributes {
    return this.getValue().attributes;
  }

  getUserEmail(): string {
    return this.getValue().email;
  }

  /**
   * Retrieves a single user attribute from the store by name.
   */
  getUserAttribute(attribute: keyof UserAttributes): boolean | string | AddressModel {
    const attributes = this.getValue().attributes;
    return (attributes ? attributes[attribute] : undefined) as boolean | string | AddressModel;
  }
}
