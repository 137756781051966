import { Component, Input } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

export interface TimeRange {
  openFrom: string;
  openTo: string;
}

export type TimeType = 'openFrom' | 'openTo';

@Component({
  selector: 'eg-time-range-picker',
  templateUrl: './time-range-picker.component.html',
  styleUrls: ['./time-range-picker.component.scss'],
  providers: [ValueAccessorUtil.getValueAccessorProvider(TimeRangePickerComponent)],
})
export class TimeRangePickerComponent extends AbstractValueAccessorComponent<TimeRange> {
  isOpen = false;
  timeRange: Partial<TimeRange> = { openFrom: '00:00', openTo: '00:00' };
  @Input() labelStart: string;
  @Input() labelEnd: string;
  @Input() stepSize = 15;

  closeTimeRangePicker(): void {
    this.isOpen = false;
    this.onTouched();
  }

  toggleTimeRangePicker(): void {
    if (this.disabled) {
      this.isOpen = false;

      return;
    }
    this.isOpen = !this.isOpen;
  }

  onTimeChange(value: string, timeType: TimeType): void {
    // 00:00, time length will be 5 including the separator
    if (value.length !== 5) {
      return;
    }

    const newtime = {};
    newtime[timeType] = value;
    this.timeRange = { ...this.timeRange, ...newtime };

    if (this.timeRange.openTo || this.timeRange.openFrom) {
      this.value = this.timeRange as TimeRange;
      this.changed.emit(this.value);
      this.onChange(this.value);
    }
  }

  decrement(timeType: TimeType): void {
    const [hours, minutes] = this.timeRange[timeType].split(':').map((num) => Number(num));

    const minutesToSubtract = minutes % this.stepSize || this.stepSize;
    let newMinutes = minutes - minutesToSubtract;
    let newHours = hours;

    if (newMinutes < 0) {
      newMinutes = hours === 1 ? 60 + newMinutes : 0;
      newHours--;

      if (newHours <= 0) {
        newHours = 0;
      }
    }

    this.onTimeChange(`${this.getPaddedString(newHours)}:${this.getPaddedString(newMinutes % 60)}`, timeType);
  }

  increment(timeType: TimeType): void {
    const [hours, minutes] = this.timeRange[timeType].split(':').map((num) => Number(num));
    const minutesToAdd = this.stepSize - (minutes % this.stepSize);
    const newMinutes = minutes + minutesToAdd;
    let newHours = hours;

    if (newMinutes >= 60) {
      newHours++;

      if (newHours >= 24) {
        newHours = 0;
      }
    }

    this.onTimeChange(`${this.getPaddedString(newHours)}:${this.getPaddedString(newMinutes % 60)}`, timeType);
  }

  writeValue(value: TimeRange): void {
    if (value?.openFrom || value?.openTo) {
      this.timeRange = value;
      super.writeValue(value);
    } else {
      super.writeValue({ openFrom: '00:00', openTo: '00:00' });
    }
  }

  private getPaddedString(time: number): string {
    return Math.abs(time).toString().padStart(2, '0');
  }
}
