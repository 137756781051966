import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'eg-readonly-text',
  templateUrl: './readonly-text.component.html',
  styleUrls: ['./readonly-text.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class ReadonlyTextComponent {
  @Input() label: string;
}
