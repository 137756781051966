import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerTypeSelectComponent } from './container-type-select.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [ContainerTypeSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [ContainerTypeSelectComponent],
})
export class ContainerTypeModule {}
