export interface GooglePlaceChanged {
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
  streetNumber?: string;
  country?: string;
  address?: string;
  companyName?: string;
}
