import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { BookingDocumentModel, DocumentTypeModel } from '@railmybox/api-booking';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { UploadDocManagementService } from './service/upload-doc-management.service';

@Component({
  selector: 'rmb-upload-doc-management',
  templateUrl: './upload-doc-management.component.html',
  styleUrls: ['./upload-doc-management.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(UploadDocManagementComponent)],
  encapsulation: ViewEncapsulation.Emulated,
})
export class UploadDocManagementComponent extends AbstractValueAccessorComponent<any> implements OnInit, OnChanges {
  @Input() file: any;
  @Input() documentType: DocumentTypeModel;
  @Input() bookingRef: string;
  @Input() containerKey: string;
  @Input() title: string;
  @Input() isDocumentScaning: boolean;
  @Output() deleteClicked: EventEmitter<object> = new EventEmitter<object>();
  @Output() downloadClicked: EventEmitter<BookingDocumentModel> = new EventEmitter<BookingDocumentModel>();
  @Output() isOrderConfirmation: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isInvoice: EventEmitter<boolean> = new EventEmitter<boolean>();

  selectedFileName: string;
  document: BookingDocumentModel[] = [];
  containerDocument: any[][] = [[]];
  isDocumentPresent = false;
  isContainerDocumentPresent = false;
  DOCUMENT_TYPE = DocumentTypeModel;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    private uploadDocManagementService: UploadDocManagementService,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  async ngOnInit(): Promise<void> {
    if (!this.containerKey) {
      const documents = await this.uploadDocManagementService.getDocuments(this.bookingRef, this.documentType);
      if (documents.length > 0) {
        if (this.documentType === this.DOCUMENT_TYPE.OrderConfirmation) {
          this.document = documents.filter((p) => p.containerKey === null);
          this.isOrderConfirmation.emit(true);
        } else if (this.documentType === this.DOCUMENT_TYPE.Invoice) {
          this.document = documents;
          this.isInvoice.emit(true);
        } else {
          this.document = documents;
        }
      }
      this.isDocumentPresent = this.document.length > 0;
    }
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.containerKey) {
      this.containerKey = await changes.containerKey.currentValue;
      this.containerDocument[this.containerKey] = await this.uploadDocManagementService.getDocuments(
        this.bookingRef,
        this.documentType,
        this.containerKey
      );
      this.isContainerDocumentPresent = false;

      if (this.containerDocument[this.containerKey][0]) {
        this.isContainerDocumentPresent = true;
      } else {
        this.isDocumentPresent = false;
      }
    }
    if (changes.file && changes.file.currentValue && !this.containerKey) {
      this.document.push({ documentKey: this.file.documentKey, documentType: this.documentType });
      this.isDocumentPresent = true;
    }
    if (changes.file && changes.file.currentValue && this.containerKey) {
      this.containerDocument[this.containerKey].push({
        documentKey: this.file.documentKey,
        documentType: this.documentType,
        containerKey: this.containerKey,
      });
    }
  }

  onChanged(event): Promise<void> {
    if (event.target.files.length === 0) {
      return;
    }

    this.value = event.target.files[0];
    this.selectedFileName = event.target.files[0].name;

    this.changed.emit(this.value);
  }

  removeSelection(documentKey: string, isContainer: boolean): void {
    this.deleteClicked.emit({ documentKey, isContainer });
    if (!isContainer) {
      const index = this.document.findIndex((doc) => doc.documentKey === documentKey);
      if (index !== -1) {
        this.document.splice(index, 1);
        this.isDocumentPresent = false;
      }
    } else {
      if (this.containerDocument[this.containerKey]) {
        const index = this.containerDocument[this.containerKey].findIndex((doc) => doc.documentKey === documentKey);
        if (index !== -1) {
          this.containerDocument[this.containerKey].splice(index, 1);
        }
      }
    }
  }

  download(model: BookingDocumentModel): void {
    this.downloadClicked.emit(model);
  }
}
