<div [style.width.px]="prefixContainerWidth" class="inline absolute input-prefix">
  <ng-content class="inline-content" select="[egFieldPrefixDirective]"></ng-content>
</div>

<textarea
  class="input"
  [class.no-label]="!labelWithSuffix"
  [style.paddingLeft.px]="prefixContainerWidth + prefixInputPadding"
  [(ngModel)]="value"
  [id]="id"
  [placeholder]="placeholder"
  [class.has-suffix]="hasSuffixIcon"
  [readonly]="readonly"
  [autocomplete]="autocomplete"
  [disabled]="disabled"
  (change)="textareaChanged()"
  (keyup)="onKeyup($event)"
  (blur)="markInputAsTouched()"
  [rows]="rows"
></textarea>

<label [for]="id" [style.left.px]="prefixContainerWidth + prefixInputPadding" class="absolute input-label">
  {{ labelWithSuffix }}
</label>

<div class="inline absolute input-suffix">
  <ng-content select="[egFieldSuffixDirective]"></ng-content>
  <!--  <fa-icon-->
  <!--    class='error-mark'-->
  <!--    *ngIf='showErrorIcon'-->
  <!--  ></fa-icon>-->
</div>
@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
