import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseTableComponent } from '../base-table/base-table.component';
import { TableBaseData } from '../../table.model';
import { slideAnimation } from '@railmybox/shared/util';

@Component({
  selector: 'eg-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
  animations: [slideAnimation],
})
export class CardTableComponent<T extends TableBaseData> extends BaseTableComponent<T> {
  @ContentChild('emptyTableTemplate') emptyTableTemplate: TemplateRef<any>;

  @ContentChild('expandContentTemplate') expandContentTemplate: TemplateRef<any>;

  @Input() expanded: number;

  toggleExpansion(event: MouseEvent, idx: number): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.expanded === idx) {
      this.expanded = undefined;
    } else {
      this.expanded = idx;
    }
  }
}
