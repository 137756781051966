import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { ButtonSize, ButtonStyle, ButtonType } from '../button-types';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'button[egButton], a[egButton]',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  exportAs: 'egButton',
  encapsulation: ViewEncapsulation.None,
})
/* eslint-enable */
export class ButtonComponent {
  @Input() egType: ButtonType = 'round';
  @Input() egStyle: ButtonStyle = 'primary';
  @Input() egButtonSize: ButtonSize = 'default';
  @Input() disabled: boolean;
  @Input() tabIndex: number;

  @HostBinding('class.eg-btn') get egBbtn() {
    return true;
  }

  @HostBinding('class.eg-btn__default') get btnSizeDefault() {
    return this.egButtonSize === 'default';
  }

  @HostBinding('class.eg-btn__small') get btnSizeSmall() {
    return this.egButtonSize === 'small';
  }

  @HostBinding('class.eg-btn__large') get btnSizeLarge() {
    return this.egButtonSize === 'large';
  }

  @HostBinding('class.eg-btn__primary') get btnStylePrimary() {
    return this.egStyle === 'primary';
  }

  @HostBinding('class.eg-btn__danger') get btnStyleDanger() {
    return this.egStyle === 'danger';
  }

  @HostBinding('class.eg-btn__secondary') get btnStyleSecondary() {
    return this.egStyle === 'secondary';
  }

  @HostBinding('class.eg-btn__round') get btnTypeRound() {
    return this.egType === 'round';
  }

  @HostBinding('class.eg-btn__flat') get btnTypeFlat() {
    return this.egType === 'flat';
  }

  @HostBinding('class.eg-btn__next') get btnTypeNext() {
    return this.egType === 'next';
  }

  @HostBinding('class.eg-btn__prev') get btnTypePrev() {
    return this.egType === 'prev';
  }

  @HostBinding('attr.tabindex') get attrTabindex() {
    return this.disabled ? -1 : this.tabIndex === null ? null : this.tabIndex;
  }

  @HostBinding('attr.disabled') get attrDisabled() {
    return this.disabled || null;
  }
}
