<eg-input
  class="p-monthrange-action mb-0"
  [value]="formattedValue"
  [label]="labelWithSuffix"
  [name]="name"
  [placeholder]="placeholder"
  [readonly]="true"
  [disabled]="disabled"
  (click)="openDatePicker($event)"
  (blurred)="markInputAsTouched()"
>
  <img egFieldSuffixDirective src="/assets/icons/field_date.svg" class="date-icon p-monthrange-action" />
</eg-input>

<div>
  <p-calendar
    #rangeMonth
    class="month-range"
    [(ngModel)]="monthValue"
    [readonlyInput]="true"
    view="month"
    dateFormat="mm/yy"
    selectionMode="range"
    inputId="monthpicker"
    (onSelect)="onSelect($event)"
  ></p-calendar>
</div>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
