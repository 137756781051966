import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { PackagingService, PackagingModel } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'rmb-packaging-select',
  templateUrl: './packaging-select.component.html',
  styleUrls: ['./packaging-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(PackagingSelectComponent)],
})
export class PackagingSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  packagingTypes$: Observable<PackagingModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = false;

  constructor(
    private packagingService: PackagingService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.packagingTypes$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.packagingService.listPackagings(query, 20) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(packagingModel: PackagingModel): void {
    this.selectedName = packagingModel?.id;
    this.value = packagingModel?.id;
    this.onChange(packagingModel?.id);
    this.changed.emit(packagingModel?.id);
  }

  async writeValue(value: string): Promise<void> {
    if (!value) {
      return;
    }

    super.writeValue(value);

    this.value = value;
    this.selectedName = value;
  }
}
