import { BehaviorSubject, Observable } from 'rxjs';
import { TranslocoTestingModule, TranslocoTestingOptions } from '@ngneat/transloco';

type LANGUAGES = 'en' | 'de';

export class MockTranslocoService {
  langChanges: BehaviorSubject<LANGUAGES> = new BehaviorSubject<LANGUAGES>('en');
  langChanges$: Observable<LANGUAGES> = this.langChanges.asObservable();
}

export const translocoService = new MockTranslocoService();

/*
export const withLanguageKnob = (storyFunc: CallableFunction) => {
  const story = storyFunc();
  const language = select(
    'Languages',
    {
      en: 'en',
      de: 'de',
    },
    'en',
    'Languages'
  );

  translocoService.langChanges.next(language);
  return story;
};
*/
export function getTranslocoTestingModule(options: TranslocoTestingOptions = {}) {
  return TranslocoTestingModule.forRoot({
    translocoConfig: {
      availableLangs: ['en'],
      defaultLang: 'en',
    },
    preloadLangs: true,
    ...options,
  });
}
