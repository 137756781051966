import { MonetaryValueTypeModel } from '@railmybox/api-booking';
import dayjs from 'dayjs/esm';

export type DateFormatType = 'date' | 'date-time' | 'time' | 'time-full' | 'month';
export type DateType = string | number | Date;

export function monetaryModelToString(model: MonetaryValueTypeModel, lang = 'de', minimumFractionDigits = 2): string {
  return model ? model.amount.toLocaleString(lang, { minimumFractionDigits: minimumFractionDigits }) + ' ' + model.currency : '';
}

export const formatDateTime = (lang: string, type: DateFormatType, date: DateType) => {
  if (!date) return '';

  let format;
  switch (type) {
    case 'month':
      format = lang === 'de' ? 'MM/YYYY' : 'MM/YYYY';
      break;
    case 'date':
      format = lang === 'de' ? 'DD.MM.YYYY' : 'DD/MM/YYYY';
      break;
    case 'time':
      format = lang === 'de' ? 'HH:mm' : 'HH:mm';
      break;
    case 'time-full':
      format = lang === 'de' ? 'HH:mm:ss' : 'HH:mm:ss';
      break;
    case 'date-time':
    default:
      format = lang === 'de' ? 'DD.MM.YYYY HH:mm' : 'DD/MM/YYYY HH:mm';
      break;
  }
  return dayjs(date).format(format);
};

export function monetaryTableFormat(lang: string, price: MonetaryValueTypeModel): string {
  if (!price.amount) return '';

  const formater = new Intl.NumberFormat(lang, {
    style: 'currency',
    currency: price.currency,
  });

  return formater.format(price.amount);
}
