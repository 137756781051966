export interface Address {
  id: number;
  type: string;
  sourceId: string;
  source: string;
  formattedAddress: string;
  street: string;
  houseNumber: string;
  zipCode: string;
  city: string;
  district: string;
  countryCode: string;
  latitude: number;
  longitude: number;
}

export interface Location {
  type?: string;
  addresses: Address[];
}
