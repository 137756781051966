<div
  class="range-picker"
  [class.range-picker--disabled]="disabled"
  fxFlex="100"
  fxLayout="column"
  egClickOutside
  (outsideClick)="closeTimeRangePicker()"
>
  <div
    fxFlex="100"
    class="pointer range-picker__tile"
    fxLayout="row wrap"
    fxLayoutAlign="space-between center"
    (click)="toggleTimeRangePicker()"
  >
    <span class="range-picker__tile__title">{{ labelWithSuffix }}</span>
    <span class="range-picker__tile__value">{{ timeRange.openFrom }} - {{ timeRange.openTo }}</span>
  </div>

  @if (isOpen) {
  <div class="range-picker__dropdown" fxLayout="row wrap" fxFlex="100">
    <ng-container *ngTemplateOutlet="timeRangePickerTemplate; context: { title: labelStart, timeType: 'openFrom' }"></ng-container>
    <ng-container *ngTemplateOutlet="timeRangePickerTemplate; context: { title: labelEnd, timeType: 'openTo' }"></ng-container>
  </div>
  }
</div>

<ng-template #timeRangePickerTemplate let-title="title" let-timeType="timeType">
  <div fxFlex="100" class="range-picker__tile mx-13 mb-13" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <span class="range-picker__tile__title">{{ title }}</span>

    <div class="range-picker__tile__input" fxLayout="row" fxLayoutAlign="center center">
      <img class="mr-2" src="/assets/icons/field_minus.svg" alt="DECREMENT" (click)="decrement(timeType)" />
      <input
        type="text"
        mask="Hh:m0"
        [leadZeroDateTime]="true"
        [showMaskTyped]="false"
        [ngModel]="timeRange[timeType]"
        (keyup)="onTimeChange($event.target['value'], timeType)"
      />
      <img src="/assets/icons/field_plus.svg" alt="INCREMENT" (click)="increment(timeType)" />
    </div>
  </div>
</ng-template>
