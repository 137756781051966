export type TooltipPosition = 'top' | 'right' | 'bottom' | 'left';
export type TooltipType = 'info' | 'default';

import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { TooltipComponent } from '../tooltip.component';

@Directive({
  selector: '[egTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('egTooltip') content: string | TemplateRef<any>;
  @Input() showToolTip = true;
  @Input() toolTipClass: string | string[];
  @Input() position: TooltipPosition = 'top';
  @Input() tooltipType: TooltipType = 'default';
  @Input() templateContext: any;
  @Input() index: number;
  @Input() paddingClass = 'px-10 py-15';
  private overlayRef: OverlayRef;
  @Input() private isDisplayed = false;

  constructor(private overlayPositionBuilder: OverlayPositionBuilder, private elementRef: ElementRef, private overlay: Overlay) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayPositionBuilder.flexibleConnectedTo(this.elementRef).withPositions(this.getPositions()).withPush();

    const scrollStrategy = this.overlay.scrollStrategies.reposition();

    // Connect position strategy
    this.overlayRef = this.overlay.create({ positionStrategy, scrollStrategy, panelClass: this.toolTipClass });
  }

  /**
   * Creates and attaches the tooltip to overlay ref.
   */
  @HostListener('mouseenter')
  show(): void {
    if (this.isDisplayed || !this.content || !this.showToolTip) {
      return;
    }
    const tooltipRef: ComponentRef<TooltipComponent> = this.overlayRef.attach(new ComponentPortal(TooltipComponent));
    tooltipRef.instance.content = this.content;
    tooltipRef.instance.context = this.templateContext;
    tooltipRef.instance.index = this.index;
    tooltipRef.instance.classes = `tooltip-${this.position} tooltip-${this.tooltipType} ${this.paddingClass}`;
    this.isDisplayed = true;
  }

  /**
   * Detaches the overlay from the container.
   */
  @HostListener('mouseleave')
  hide(): void {
    if (!this.isDisplayed) {
      return;
    }
    this.overlayRef.detach();
    this.isDisplayed = false;
  }

  private getPositions(): ConnectedPosition[] {
    switch (this.position) {
      case 'right':
        return [{ originX: 'end', originY: 'center', overlayX: 'start', overlayY: 'center' }];
      case 'bottom':
        return [{ originX: 'center', originY: 'bottom', overlayX: 'center', overlayY: 'top' }];
      case 'left':
        return [{ originX: 'start', originY: 'center', overlayX: 'end', overlayY: 'center' }];
      case 'top':
      default:
        return [{ originX: 'center', originY: 'top', overlayX: 'center', overlayY: 'bottom' }];
    }
  }

  ngOnDestroy(): void {
    this.hide();
  }
}
