import { Injectable } from '@angular/core';
import { FCInitObject, FCUser, NgxFreshChatService } from 'ngx-freshchat';
import { UserAttributes, UserStoreService } from '@railmybox/auth';
import { environment } from '../../environments/environment';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class FreshchatService {
  constructor(private freshChat: NgxFreshChatService, private userStoreService: UserStoreService) {
    this.initializeFreshChat();
  }

  async initializeFreshChat(): Promise<void> {
    const userAttributes: UserAttributes = this.userStoreService.getUserQuery().getAttributes();
    const freshChatInitObj: FCInitObject = userAttributes
      ? {
          token: environment.freshChatToken,
          host: environment.freshChatHost,
          config: {
            headerProperty: {
              fontName: 'Roboto',
              fontUrl: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900',
            },
          },
          firstName: userAttributes.given_name,
          lastName: userAttributes.family_name,
          email: userAttributes.email,
          phone: userAttributes.phone_number,
          locale: userAttributes.locale,
          externalId: userAttributes.sub,
        }
      : {
          token: environment.freshChatToken,
          host: environment.freshChatHost,
          config: {
            headerProperty: {
              fontName: 'Roboto',
              fontUrl: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900',
            },
          },
        };
    this.freshChat
      .init(freshChatInitObj)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.updateFreshAttributes());
  }

  updateFreshAttributes(): void {
    this.userStoreService
      .getUserQuery()
      .select()
      .subscribe((userState) => {
        const attributes: UserAttributes = userState.attributes;

        const user: FCUser = attributes
          ? {
              firstName: attributes.given_name,
              lastName: attributes.family_name,
              email: attributes.email,
              phone: attributes.phone_number,
              externalId: attributes.sub,
            }
          : {};
        this.freshChat.clearUser();
        this.freshChat.setUserProperties(user);
      });
  }
}
