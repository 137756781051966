<div [@dialog] class="eg-modal-container" fxFlex="100" fxLayoutAlign="center center" fxLayout="row">
  <div [fxFlex]="width" fxLayout="column" fxLayoutAlign="start start" class="eg-modal-body">
    @if (showHeader) {
    <div class="eg-modal-title">
      @switch (titleType) { @case ('string') {
      <strong fxFlex="100" fxLayout="row">{{ title }}</strong>
      } @case ('template') {
      <ng-container *ngTemplateOutlet="title"></ng-container>
      } }
      <img class="close-dialog" src="/assets/icons/close.svg" alt="CLOSE" (click)="close()" />
    </div>
    } @switch (contentType) { @case ('string') {
    <div [innerHTML]="content"></div>
    } @case ('template') {
    <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
    } @case ('component') {
    <ng-container *egComponentOutlet="content; inputs: context"></ng-container>
    } }
  </div>
</div>
