import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthStoreService } from '@railmybox/auth';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  authRoute: boolean;

  constructor(protected authStoreService: AuthStoreService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    this.router.events
      .pipe(
        filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        map(async (next) => {
          const splitUrl = next.urlAfterRedirects.split('/');

          if (splitUrl.includes('auth')) {
            this.authRoute = true;
          }
        })
      )
      .subscribe();

    this.authStoreService
      .getAuthQuery()
      .getAuthMail()
      .subscribe(async () => {
        if (request.url.includes('api')) {
          this.authStoreService
            .getCurrentSession()
            .then((session) => {
              //console.log(session);
            })
            .catch(async (err) => {
              if (err === 'No current user' && !this.authRoute) {
                await this.authStoreService.logout();
              }
            });
        }
      });

    return next.handle(request);
  }
}
