import { NgModule } from '@angular/core';
import { ReadonlyTextComponent } from './readonly-text.component';
import { provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [ReadonlyTextComponent],
  imports: [],
  exports: [ReadonlyTextComponent],
  providers: [provideNgxMask()],
})
export class ReadonlyTextModule {}
