<eg-card-select
  *transloco="let translate"
  [disabled]="disabled"
  [items]="trainVisits$ | async"
  [itemTemplate]="itemTemplate"
  [tileTemplate]="tileTemplate"
  [hideAllItemsOnEmptyQuery]="false"
  [searchPlaceholder]="translate('forms.search')"
  (dropdownClosed)="markInputAsTouched()"
  (searchValueChange)="onSearchValueChanged($event)"
  (valueSelection)="onSelect($event)"
  [value]="value"
  [clearable]="clearable"
>
</eg-card-select>

<ng-template #tileTemplate>
  <div fxFlex="100" fxLayoutAlign="start center" class="label">
    @if (selectedTrain) {
    <span [class.label-top]="selectedTrain">{{ labelWithSuffix }}</span>
    }
    <span class="selected-value">{{ selectedTrain || labelWithSuffix }}</span>
  </div>
</ng-template>

<ng-template #itemTemplate let-item="item" let-selectCallback="selectCallback">
  <div fxFlex="100" fxLayoutAlign="center flex-start" class="px-16 select__item" (click)="selectCallback(item)">
    <span fxFlex="30" class="align-start"> {{ item.trainIdOperator }} </span>
    <span fxFlex="10" class="align-end"> {{ item.remainingCapacity }} </span>
    <span fxFlex="30" class="align-start"> {{ item.shippingDate }} </span>
    <span fxFlex="30" class="align-start"> {{ item.trainStatus }} </span>
  </div>
</ng-template>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
