<ng-template transloco let-translate>
  <div class="auth-container">
    <div class="auth-content">
      <h1 class="title">{{ translate(TRANSLOCO_PREFIX + '.title') }}</h1>
      <h3 class="subtitle-h3">{{ translate(TRANSLOCO_PREFIX + '.details') }}</h3>
      <form id="login-form" class="login-form" fxLayout="column" fxLayoutAlign="space-around" [formGroup]="loginForm" (ngSubmit)="login()">
        @if (errorMessage) {
        <eg-alert type="error" [message]="errorMessage" fxFlex="100"></eg-alert>
        }

        <div fxFlex="100" fxLayout="column">
          <eg-input
            class="mb-12"
            type="email"
            name="email"
            formControlName="email"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.email')"
            [label]="translate(TRANSLOCO_PREFIX + '.email')"
          >
          </eg-input>

          <eg-input
            class="mb-40"
            type="password"
            name="password"
            formControlName="password"
            fieldType="M"
            [placeholder]="translate(TRANSLOCO_PREFIX + '.password')"
            [label]="translate(TRANSLOCO_PREFIX + '.password')"
          >
            <span class="label-link pointer" egFieldSuffixDirective (click)="resetPasswordModal()">
              {{ translate(TRANSLOCO_PREFIX + '.forgotPassword') }}
            </span>
          </eg-input>

          <div class="btn-container" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
            <div class="btn-sidebar">
              <span>{{ translate(TRANSLOCO_PREFIX + '.noAccount') }}</span>
              <a class="link--basic" [routerLink]="['/', 'auth', 'sign-up']">
                {{ translate(TRANSLOCO_PREFIX + '.button.signUp') }}
              </a>
            </div>
            <button id="login-btn" class="btn btn-submit" type="submit">
              {{ translate(TRANSLOCO_PREFIX + '.button.login') }}
            </button>
          </div>
        </div>
      </form>
    </div>

    @if (confirmationModal) {
    <div class="modal modal--fullscreen">
      <div class="modal__overlay" (click)="toggleConfirmModal()"></div>
      <div class="modal__content modal__content--reset">
        <h2 class="subtitle-h2">{{ translate(TRANSLOCO_PREFIX + '.enterEmail') }}</h2>
        <h3 class="subtitle-h3">
          {{ translate(TRANSLOCO_PREFIX + '.enterResetEmail') }}
        </h3>
        <form
          id="reset-form"
          class="login-form login-form--reset"
          fxLayout="column"
          fxLayoutAlign="space-around"
          [formGroup]="confirmEmailForm"
          (ngSubmit)="onResetPassword()"
        >
          <div fxFlex="100" fxLayout="column">
            <eg-input
              class="mb-12"
              type="email"
              name="email"
              formControlName="email"
              fieldType="M"
              [placeholder]="translate(TRANSLOCO_PREFIX + '.email')"
              [label]="translate(TRANSLOCO_PREFIX + '.email')"
            >
            </eg-input>
            <div class="btn-container" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between center">
              <button class="btn btn-submit" type="submit">
                {{ translate(TRANSLOCO_PREFIX + '.button.sendCode') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    }
  </div>
</ng-template>
