export enum DeliveryMedium {
  EMAIL = 'EMAIL',
}

export interface AuthCredential {
  email: string;
  password?: string;
}

export interface AuthUIState {
  deliveryMedium?: DeliveryMedium;
  destination?: string;
  userLanguage?: string;
}

export interface AuthState extends AuthCredential {
  ui: AuthUIState;
  idToken: string;
  accessToken: string;
  redirectTo?: string;
  roles?: string[];
}

export function createInitialState(): AuthState {
  return {
    ui: {
      userLanguage: null,
    },
    idToken: '',
    accessToken: '',
    email: null,
    password: null,
    roles: [],
  };
}
