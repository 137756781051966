import { Component, Input, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { UntilDestroy } from '@ngneat/until-destroy';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DateValue {
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}

export type DropdownAlignType = 'left' | 'right' | 'center';
export type DropdownDirectionType = 'down' | 'up';

export enum OutputType {
  datetime = 'datetime',
  date = 'date',
}

@UntilDestroy()
@Component({
  selector: 'eg-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DateComponent)],
})
export class DateComponent extends AbstractDateComponent<any> {
  @ViewChild(DaterangepickerDirective) dateRangePickerDirective: DaterangepickerDirective;
  @Input() singleDatePicker = true;
  @Input() dropdownAlign: DropdownAlignType = 'right';
  @Input() dropdownDirection: DropdownDirectionType = 'down';
  @Input() output: OutputType = OutputType.datetime;
  opens = 'right';
  drops = 'down';

  get formattedValue(): string {
    if (!this.value?.startDate) {
      return undefined;
    }

    return (this.value as DateValue).startDate.isValid()
      ? `${this.value.startDate.tz(dayjs.tz.guess(), true).format(this.locale.format)}`
      : '';
  }

  /**
   * emits selected interval
   * @param value
   */
  onInputChanged(value: DateValue): void {
    if (!value?.startDate) {
      return;
    }

    this.value = value;
    this.onChange(this.getFormattedValue(value));
    this.changed.emit(this.getFormattedValue(value));
    this.onTouched();
  }

  openDatePicker(event: MouseEvent): void {
    if (this.disabled) {
      return;
    }
    this.dateRangePickerDirective.open();
    event.stopPropagation();
    event.preventDefault();
  }

  override writeValue(value: any): void {
    if (!value) {
      super.writeValue(undefined);
      return;
    }

    const date = dayjs(value);
    super.writeValue({
      startDate: date.isValid() ? date : undefined,
      endDate: date.isValid() ? date : undefined,
    });
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }

    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]();
  }

  getFormattedValue(value: DateValue): string {
    return this.output === OutputType.datetime
      ? value.startDate.tz(dayjs.tz.guess(), true).toISOString()
      : value.startDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
  }
}
