import { Injectable } from '@angular/core';
import { Address } from '../location.model';
import { GROUPED_PLACES } from './dummy-places';

@Injectable()
export class PlaceService {
  getPlacesBySearchTerm(searchQuery: string): Promise<Address[]> {
    return Promise.resolve(GROUPED_PLACES[0].addresses.filter((place) => place.formattedAddress.includes(searchQuery)));
  }

  getPlaceById(placeId: string | number): Promise<Address> {
    return Promise.resolve(GROUPED_PLACES[0].addresses.find(({ id }) => id === placeId));
  }
}
