import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';

interface Separator {
  decimalSeparator: '.' | ',';
  thousandSeparator: '.' | ',';
}

@UntilDestroy()
@Pipe({
  name: 'egNumber',
  pure: false,
})
export class EgNumberPipe implements PipeTransform {
  private separatorRecord: Record<string, Separator> = {
    en: {
      decimalSeparator: '.',
      thousandSeparator: ',',
    },
    de: {
      decimalSeparator: ',',
      thousandSeparator: '.',
    },
  };
  private subscription: Subscription;
  private lastValue: string | number;
  private lastFormattedValue: string;
  private decimalPipe = new DecimalPipe('en-US');

  constructor(private translocoService: TranslocoService, private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * @param value The number to be formatted.
   * @param digitsInfo Decimal representation options, specified by a string
   * in the following format:<br>
   * <code>{minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}</code>.
   *   - `minIntegerDigits`: The minimum number of integer digits before the decimal point.
   * Default is `1`.
   *   - `minFractionDigits`: The minimum number of digits after the decimal point.
   * Default is `0`.
   *   - `maxFractionDigits`: The maximum number of digits after the decimal point.
   * Default is `3`.
   */
  transform(value: number | string, digitsInfo?: string): string {
    if (value === this.lastValue) {
      return this.lastFormattedValue;
    }

    this.lastValue = value;
    const activeLang = this.translocoService.getActiveLang();

    this.subscription?.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang) => {
      this.lastFormattedValue = this.getFormattedString(lang, digitsInfo);
      this.changeDetectorRef.markForCheck();
    });

    this.lastFormattedValue = this.getFormattedString(activeLang, digitsInfo);

    return this.lastFormattedValue;
  }

  private getFormattedString(lang: string, digitsInfo?: string): string {
    const formattedString = this.decimalPipe.transform(this.lastValue, digitsInfo);
    if (lang === 'en') {
      return formattedString;
    }

    return formattedString
      .replace(/\./g, this.separatorRecord[lang].decimalSeparator)
      .replace(/,/g, this.separatorRecord[lang].thousandSeparator);
  }
}
