<div class="support-nav">
  <div *transloco="let translate" fxFlex="100" fxLayout="column" class="support-nav_wrapper">
    <div class="support-nav_item" [routerLink]="['/', 'support', 'disposition']">
      <div class="support-nav_image">
        <img class="nav__img svg-to-white" src="/assets/icons/dispo.svg" alt="disposition" />
      </div>
      <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.helpdesk') }}</span>
    </div>

    <div class="support-nav_item" [routerLink]="['/', 'support', 'train-visits']">
      <div class="support-nav_image">
        <img class="nav__img svg-to-white" src="/assets/icons/train-visits.svg" alt="trainvisits" />
      </div>
      <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.trainSchedule') }}</span>
    </div>

    <div class="support-nav_item" [routerLink]="['/', 'support', 'wishlist']">
      <div class="support-nav_image">
        <img class="nav__img svg-to-white" src="/assets/icons/wishlist_nav_admin.svg" alt="wishlist" />
      </div>
      <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.wishlist') }}</span>
    </div>

    @if (pricingOrAccountingRole) {
    <div class="support-nav_item submenu-switch" fxLayout="column" fxLayoutAlign="center start" (click)="submenuClick($event)">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="not-clickable">
        <div class="support-nav_image">
          <img class="nav__img svg-to-white" src="/assets/icons/money-check-alt.svg" alt="pricing" />
        </div>
        <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.pricing') }}</span>
      </div>
      <div class="support-nav__submenu">
        <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start flex-start">
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'tariff-scales']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.tariff-scales') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'customer-tariffs']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.customer-tariffs') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'customer-discount']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.customer-discounts') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="support-nav_item submenu-switch" fxLayout="column" fxLayoutAlign="center start" (click)="submenuClick($event)">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="not-clickable">
        <div class="support-nav_image">
          <img class="nav__img svg-to-white" src="/assets/icons/calculator.svg" alt="accounting" />
        </div>
        <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.accounting') }}</span>
      </div>
      <div class="support-nav__submenu">
        <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start flex-start">
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'posting-months']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.posting-months') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'incoming-costs']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.incoming-costs') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'summed-costs']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.summed-costs') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'summed-reserves']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.summed-reserves') }}</span>
          </div>
        </div>
      </div>
    </div>
    }

    <div class="support-nav_item submenu-switch" fxLayout="column" fxLayoutAlign="center start" (click)="submenuClick($event)">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" class="not-clickable">
        <div class="support-nav_image">
          <img class="nav__img svg-to-white" src="/assets/icons/customer-info.svg" alt="customer" />
        </div>
        <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.customers') }}</span>
      </div>

      <div class="support-nav__submenu">
        <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start flex-start">
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'customers']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.customers') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'customer-groups']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.customer-groups') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="support-nav_item" [routerLink]="['/', 'support', 'invoices']">
      <div class="support-nav_image">
        <img class="nav__img svg-to-white" src="/assets/icons/invoices.svg" alt="invoices" />
      </div>
      <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.invoices') }}</span>
    </div>

    <div class="support-nav_item submenu-switch" fxLayout="column" fxLayoutAlign="center start" (click)="submenuClick($event)">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxItemAlign="start" class="not-clickable">
        <div class="support-nav_image">
          <img class="nav__img svg-to-white" src="/assets/icons/project-diagram.svg" alt="masterdata" />
        </div>
        <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.title') }}</span>
      </div>

      <div class="support-nav__submenu">
        <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start flex-start">
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'trains']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.trains') }}</span>
          </div>

          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'services']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.services') }}</span>
          </div>
          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'iso-codes']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.isoCodes') }}</span>
          </div>

          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'stations']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.stations') }}</span>
          </div>

          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'operators']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.operators') }}</span>
          </div>

          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'depots']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.depots') }}</span>
          </div>

          <div class="support-nav_item support-nav__subitem" [routerLink]="['/', 'support', 'shipping-line']">
            <span class="support-nav_title">{{ translate(TRANSLOCO_PREFIX + '.navigation.masterdata.shippingLine') }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
