import { Injectable } from '@angular/core';
import { EntityStore, StoreConfig } from '@datorama/akita';
import { ContactModel, CustomerModel } from '@railmybox/api-user';

export interface CustomerState extends CustomerModel {
  contacts?: ContactModel[];
}

export function createInitialCustomerState(): CustomerState {
  return {
    companyName: null,
    companyAddress: null,
    legalForm: null,
    profileType: null,
    vatId: null,
    vatCountryCode: null,
    website: null,
    reference: null,
    pricingAllowed: null,
    phoneNumber: null,
    rmbAuthorized: null,
    notificationEmail: null,
    notificationSms: null,
    newsletter: null,
    legalTerms: null,
    privacyTerms: null,
    registrationStep: null,
    chatRestoreId: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'customer' })
export class CustomerStore extends EntityStore<CustomerState> {
  constructor() {
    super(createInitialCustomerState());
  }
}
