import { Component, Input } from '@angular/core';
import { ModalRef } from '@eg/ui';

@Component({
  selector: 'rmb-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
})
export class BookingConfirmationComponent {
  @Input() type: 'cancel' | 'warning';
  readonly TRANSLOCO_PREFIX = 'confirmation';

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onClick(response: string): void {
    if (response === 'yes') {
      this.modalRef.close('YES');
      return;
    }

    if (response === 'no') {
      this.modalRef.close('NO');
      return;
    }

    if (response === 'submit') {
      this.modalRef.close('SUBMIT');
      return;
    }
  }
}
