import { Component, EventEmitter, Input, Output } from '@angular/core';

export type PillColor = 'grey' | 'orange' | 'lightGreen' | 'green' | 'blue' | 'lightRed' | 'red' | 'selected' | 'inactive';

export type PillColorType = {
  [key: string]: PillColor;
};

@Component({
  selector: 'eg-pill',
  templateUrl: './pill.component.html',
  styleUrls: ['./pill.component.scss'],
})
export class PillComponent {
  @Output() selected: EventEmitter<any> = new EventEmitter<any>();
  @Input() value: any;
  @Input() translationPrefix = '';
  @Input() small: boolean;
  @Input() type: 'number' | 'enum' | 'object' = 'enum';

  @Input() colors: PillColorType = undefined;

  onSelect() {
    this.selected.emit(this.value);
  }
}
