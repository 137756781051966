<div fxLayout="column" fxFlex="100" class="card-search" [class.card-search--disabled]="disabled">
  <div
    class="pointer"
    fxFlex="100"
    fxLayout="row wrap"
    [egDropdownTriggerFor]="dropdown"
    (dropDownClosed)="onDropDownClose()"
    (dropDownOpened)="onDropDownOpen()"
    [disabled]="disabled"
  >
    <div fxFlex="100" class="card-search__tile">
      <div fxFlex="100">
        <ng-container *ngTemplateOutlet="tileTemplate"></ng-container>
      </div>
      @if (clearable && value && !disabled) {
      <div class="card-search__tile__clear" fxLayout="column" fxLayoutAlign="center end" (click)="onClear($event)">
        <span aria-hidden="true">×</span>
      </div>
      }
      <div class="card-search__tile__content" fxLayout="column" fxLayoutAlign="center end">
        <img src="/assets/icons/dropdown_{{ showDropdown ? 'close' : 'open' }}.svg" alt="" />
      </div>
    </div>
  </div>
  <eg-dropdown-container [classes]="dropdownClass" #dropdown>
    @if (showDropdown) {
    <div class="card-search__dropdown" fxLayout="row wrap" fxFlex="100">
      @if (!hideSearch) {
      <eg-input
        class="card-search__dropdown__field search-bar"
        autocomplete="off"
        egAutoFocus
        [placeholder]="searchPlaceholder"
        [formControl]="searchControl"
        (keyDown)="handleKeyDown($event)"
      >
        <img class="card-search__dropdown__field--suffix" egFieldSuffixDirective src="/assets/icons/field_search.svg" alt="" />
      </eg-input>
      }
      <div
        class="card-search__dropdown__container"
        fxFlex="100"
        fxLayout="row wrap"
        [ngClass]="{ 'card-search__dropdown__container--noSearch': hideSearch }"
      >
        @for (item of items; track item) {
        <div
          class="card-search__dropdown__item"
          [ngClass]="{ 'card-search__dropdown__item__highlight': items[selectedIndex] === item }"
          fxFlex="100"
          fxLayout="row wrap"
        >
          <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item, selectCallback: onOptionSelect.bind(this) }"></ng-container>
        </div>
        }
      </div>
      @if (hideSearch && items && !items.length) {
      <div class="card-search__dropdown__item" fxFlex="100" fxLayoutAlign="start">
        <div fxFlex="100" fxLayoutAlign="flex-start" class="px-16">
          <span class="align-center"> No results. </span>
        </div>
      </div>
      }
    </div>
    }
  </eg-dropdown-container>
</div>
