import { Component, Input } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { IllustratedOption } from '../select.interface';

type SingleSelectValue = string | number;

@Component({
  selector: 'eg-select-illustrated',
  templateUrl: './select-illustrated.component.html',
  styleUrls: ['./select-illustrated.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(SelectIllustratedComponent)],
})
export class SelectIllustratedComponent extends AbstractValueAccessorComponent<SingleSelectValue> {
  @Input() options: IllustratedOption[] = [];
  @Input() isSmall = true;

  onOptionSelect(option: IllustratedOption): void {
    if (this.disabled) {
      return;
    }
    if (option.disabled) {
      return;
    }

    this.onTouched();

    if (this.value === option.value) {
      return;
    }

    this.value = option.value;
    this.onValueChange();
  }

  isSelected(option: IllustratedOption): boolean {
    return this.value === option.value;
  }

  isUnselected(option: IllustratedOption): boolean {
    return this.value !== undefined && this.value !== null && this.value !== option.value;
  }

  onValueChange(): void {
    this.onChange(this.value);
    this.changed.emit(this.value);
  }
}
