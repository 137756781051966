export const environment = {
  production: true,
  versionCheckURL: 'undefined',
  webSocketUrl: 'socket.dev.railmybox.io',
  stripePK: '',
  googleMapsApiKey: 'AIzaSyCTCW6xqNIOU5v32z6R-jKCSLTPzFgu-g8',
  apiBasePath: 'api.dev.railmybox.io',
  freshChatToken: '34b47406-5690-4675-80c2-88120ff62e3f',
  freshChatHost: 'https://wchat.eu.freshchat.com',
  aws: {
    gateway: 'undefined',
    protocol: 'https://',
    region: 'eu-central-1',
    identityPoolId: 'eu-central-1:c34e590a-9543-4452-aca3-94851beb542e',
    userPoolId: 'eu-central-1_tPHyoisdD',
    userPoolWebClientId: 'io9740u32lq0hlgkiim8fi3jv',
    defaultBucket: 'railmb-dev-frontend-origin-eu-central-1'
  },
  cookiebotId: 'f0d880b2-cf6a-4567-9c2a-81a3b0ba768c',
  signUpKey: 'Franz jagt im komplett verwahrlosten Taxi quer durch Bayern',
  enableCookieBanner: true
};
