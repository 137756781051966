import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { BaseTableComponent, PillColorType, TableBaseData, TableColumn } from '@eg/ui';
import { ContactModel } from '@railmybox/api-user';
import { slideAnimation } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss'],
  animations: [slideAnimation],
})
export class CustomerListComponent<T extends TableBaseData> extends BaseTableComponent<T> {
  @ContentChild('emptyTableTemplate') emptyTableTemplate: TemplateRef<any>;

  @Input() contactColumns: TableColumn<ContactModel>[];
  @Input() expandable = false;
  dropdown = false;

  registrationStepColors: PillColorType = {
    COMPANY: 'red',
    PAYMENT: 'orange',
    FINISHED: 'green',
  };
  contactTypeColors: PillColorType = {
    ADMIN: 'lightGreen',
    USER: 'grey',
  };

  @Input() expanded: number;

  onDropdownClick() {
    this.dropdown = !this.dropdown;
  }

  onSelectCustomer(customer: T) {
    this.rowClick.emit(customer);
  }

  toggleExpansion(event: MouseEvent, idx: number): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.expanded === idx) {
      this.expanded = undefined;
    } else {
      this.expanded = idx;
    }
  }
}
