import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ShippingLineSelectComponent } from './shipping-line-select.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [ShippingLineSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [ShippingLineSelectComponent],
})
export class ShippingLineModule {}
