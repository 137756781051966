import { NgModule } from '@angular/core';
import { InputModule } from './input/input.module';
import { FormErrorModule } from './form-error/form-error.module';
import { SelectModule } from './select';
import { ButtonModule } from './button/button.module';
import { NumberSelectionModule } from './number-selection/number-selection.module';
import { AlertModule } from './alert/alert.module';
import { DateRangeModule } from './date-range/date-range.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { BreadcrumbModule } from './breadcrumb';
import { TableModule } from './table';
import { TooltipModule } from './tooltip';
import { DateTimeModule } from './date-time/date-time.module';
import { TimeRangePickerModule } from './time-range';
import { ModalModule } from './modal';
import { TabGroupModule } from './tab-group';
import { DateModule } from './date/date.module';
import { FileUploadModule } from './file-upload';
import { DropdownModule } from './dropdown';
import { UploadModule } from './upload';
import { ExpandableModule } from './expandable';
import { SpinnerModule } from './spinner';
import { ConfirmationModule } from './confirmation';
import { MultiselectModule } from './multiselect';
import { ReadonlyTextModule } from './reaonly-text';
import { ToggleModule } from './toggle';
import { PillModule } from './pill';
import { TextareaModule } from './textarea';
import { TimeModule } from './time/time.module';
import { MonthRangeModule } from './month-range/month-range.module';
import { MonthModule } from './month/month.module';

@NgModule({
  exports: [
    AlertModule,
    BreadcrumbModule,
    ButtonModule,
    CheckboxModule,
    ConfirmationModule,
    DateModule,
    DateRangeModule,
    DateTimeModule,
    DropdownModule,
    ExpandableModule,
    FileUploadModule,
    FormErrorModule,
    InputModule,
    ModalModule,
    MonthModule,
    MonthRangeModule,
    MultiselectModule,
    NumberSelectionModule,
    PillModule,
    ReadonlyTextModule,
    SelectModule,
    SpinnerModule,
    TabGroupModule,
    TableModule,
    TimeRangePickerModule,
    ToggleModule,
    TooltipModule,
    UploadModule,
    TextareaModule,
    TimeModule,
  ],
})
export class UiModule {}
