import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrainvisitSelectComponent } from './trainvisit-select.component';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormErrorModule, SelectModule } from '@eg/ui';

@NgModule({
  declarations: [TrainvisitSelectComponent],
  imports: [CommonModule, SelectModule, TranslocoModule, FlexLayoutModule, FormErrorModule],
  exports: [TrainvisitSelectComponent],
})
export class TrainVisitModule {}
