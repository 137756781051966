<div class="card" fxFlex="100" fxLayout="column" *transloco="let translate">
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">
    <svg-icon class="card__close" src="assets/icons/close.svg" (click)="onCloseClick()"></svg-icon>
    <img height="120px" width="120px" src="/assets/icons/summary/service_dangerous_goods.svg" class="svg-to-warning" />

    <div class="card__headline">
      {{ translate(TRANSLOCO_PREFIX + '.' + source + '.title') }}
    </div>
    <div class="card__text">
      {{ translate(TRANSLOCO_PREFIX + '.' + source + '.text', { value: data }) }}
    </div>
  </div>

  <div fxFlex="100" fxLayout="row" fxLayoutGap="20px">
    <button egButton class="card__btn_secondary" (click)="onClick('yes')">{{ translate(TRANSLOCO_PREFIX + '.action.yes') }}</button>
    <button egButton class="card__btn_secondary" (click)="onClick('no')">{{ translate(TRANSLOCO_PREFIX + '.action.no') }}</button>
    <button egButton class="card__btn_primary" (click)="onClick('submit')">{{ translate(TRANSLOCO_PREFIX + '.action.submit') }}</button>
  </div>
</div>
