import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { PlaceService } from '../service/place.service';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, switchMap } from 'rxjs/operators';
import { ControlContainer } from '@angular/forms';
import { Address } from '../location.model';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SelectItem } from '@eg/ui';

@UntilDestroy()
@Component({
  selector: 'rmb-vicinity',
  templateUrl: './vicinity.component.html',
  styleUrls: ['./vicinity.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(VicinityComponent)],
})
export class VicinityComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  typeahead$ = new Subject<string | null>();
  items: BehaviorSubject<SelectItem[]> = new BehaviorSubject<SelectItem[]>([]);

  private static mapAddressToSelectOption(addresses: Address[]): SelectItem[] {
    return addresses.map((place) => ({
      label: place.formattedAddress,
      value: place.id,
    }));
  }

  constructor(
    private placeService: PlaceService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.typeahead$
      .asObservable()
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((term: string) => {
          return from(this.placeService.getPlacesBySearchTerm(term));
        }),
        map((addresses: Address[]) => VicinityComponent.mapAddressToSelectOption(addresses)),
        untilDestroyed(this)
      )
      .subscribe((selectItems) => this.items.next(selectItems));
  }

  onValueSelect(value: string): void {
    this.onChange(value);
    this.changed.emit(value);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }

    const places = VicinityComponent.mapAddressToSelectOption([await this.placeService.getPlaceById(value)]);
    this.items.next(places);
  }
}
