import { ChangeDetectorRef, Component, Input, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { GroupedAddressModel, LocationService, AddressModel } from '@railmybox/api-booking';
import { Observable } from 'rxjs';

export type LocationType = 'start' | 'destination';

@Component({
  selector: 'rmb-location-search',
  templateUrl: './location-search.component.html',
  styleUrls: ['./location-search.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(LocationSearchComponent)],
})
export class LocationSearchComponent extends AbstractValueAccessorComponent<AddressModel> {
  @Input() selectedAddress: AddressModel;
  @Input() locationType: LocationType;
  @Input() baseUrl: string;
  groupedAddresses$: Observable<GroupedAddressModel[]>;

  constructor(
    private locationService: LocationService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  get selectedImage(): string {
    const selectedImage = this.selectedAddress
      ? `/assets/icons/location_${this.selectedAddress.type.toLowerCase()}.svg`
      : `/assets/icons/${this.locationType}_card_empty.svg`;

    return this.baseUrl ? `${this.baseUrl}${selectedImage}` : selectedImage;
  }

  get baseUrlPath(): string {
    return this.baseUrl || '';
  }

  onAddressSelect(address: AddressModel): void {
    this.selectedAddress = { ...address };
    this.onChange?.(address);
  }

  onSearchValueChanged(searchTerm: string): void {
    this.groupedAddresses$ = this.locationService.getLocations(searchTerm);
  }

  writeValue(value: AddressModel) {
    super.writeValue(value);
    this.selectedAddress = value;
  }
}
