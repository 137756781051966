import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { Injectable } from '@angular/core';
import { catchError, share, switchMap } from 'rxjs/operators';
import { AuthStoreService } from '@railmybox/auth';
import { AuthSession, fetchAuthSession } from '@aws-amplify/auth';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private inflightRequest: Observable<AuthSession>;

  constructor(private authStoreService: AuthStoreService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        // Return the error as it is if the url is one from blacklisted urls like login page or register page
        if (!request.url.includes('api')) {
          return throwError(error);
        }

        if (error instanceof HttpErrorResponse) {
          // now either the user is not logged in or the auth_token has expired

          if (error.status === 401) {
            // Refreshing of auth token takes place here
            if (!this.inflightRequest) {
              this.inflightRequest = from(fetchAuthSession()).pipe(share());
              // If the refresh token does not return anything then clear the tokens
              if (!this.inflightRequest) {
                this.authStoreService.resetState();
              }
            }

            return this.inflightRequest.pipe(
              switchMap((userSession: AuthSession) => {
                this.authStoreService.updateAuthState(userSession);
                this.inflightRequest = null;
                const reqRepeat = request.clone({
                  headers: request.headers.set('Authorization', `Bearer ${userSession.tokens.accessToken.toString()}`),
                });

                // make the cancelled request again
                return next.handle(reqRepeat);
              }),
              catchError((err: any) => {
                if (err.status === 401 || err.status === 403) {
                  this.authStoreService.logout();
                }

                return throwError(err);
              })
            );
          }

          return throwError(error);
        }
      })
    );
  }
}
