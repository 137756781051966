import { Component, Input } from '@angular/core';

export type AlertType = 'error' | 'success' | 'info' | 'warning';

@Component({
  selector: 'eg-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  @Input() message: string;
  @Input() type: AlertType = 'info';
}
