import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpandableComponent } from './expandable.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  declarations: [ExpandableComponent],
  imports: [CommonModule, AngularSvgIconModule.forRoot(), FlexModule],
  exports: [ExpandableComponent],
})
export class ExpandableModule {}
