import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthStoreService } from '@railmybox/auth';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(protected authStoreService: AuthStoreService, protected router: Router, private messageService: MessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && this.router.routerState.snapshot.url.includes('support')) {
          if (error.status === 400 || error.status === 500) {
            this.messageService.add({
              severity: 'error',
              detail: error.error?.detail,
              sticky: true,
            });
          }
        }
        // pass it to the component or service that initiated the request
        return throwError(error);
      })
    );
  }
}
