import { Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';
import { DropdownPanel } from '../dropdown-panel.interface';
import { ConnectionPositionPair, Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[egDropdownTriggerFor]',
})
export class DropdownTriggerForDirective implements OnDestroy {
  private isDropdownOpen = false;
  private overlayRef: OverlayRef;
  private dropdownClosingActionsSub = Subscription.EMPTY;

  // tslint:disable-next-line:no-input-rename
  @Input('egDropdownTriggerFor') dropdownPanel: DropdownPanel;
  @Input() offsetY = 4;
  @Input() disabled = false;
  @Output() dropDownOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() dropDownClosed: EventEmitter<void> = new EventEmitter<void>();
  private isFocused = false;
  private tab = false;

  @HostBinding('tabIndex') tabIndex = 0;

  constructor(private overlay: Overlay, private elementRef: ElementRef<HTMLElement>, private viewContainerRef: ViewContainerRef) {}

  /**
   * Toggles the dropdown visibility
   */
  @HostListener('click', ['$event'])
  toggleDropdown(event: MouseEvent): void {
    this.tab = false;
    this.isDropdownOpen ? this.destroyDropdown() : this.openDropdown();
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('focusin', ['$event'])
  focusHandler(event: FocusEvent) {
    if (!this.disabled && !this.isFocused && event.relatedTarget && this.tab) {
      // tabbed into
      this.isFocused = true;
      this.openDropdown();
    }
  }

  @HostListener('focusout', ['$event'])
  focusHandlerOut(event: FocusEvent) {
    if (!this.isDropdownOpen) this.isFocused = false;
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.tab = true;
    }
  }

  /**
   * Opens the dropdown using Angular CDK overlay
   */
  openDropdown(): void {
    this.isDropdownOpen = true;
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width: this.elementRef.nativeElement.clientWidth,
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          new ConnectionPositionPair({ originX: 'start', originY: 'bottom' }, { overlayX: 'start', overlayY: 'top' }),
          new ConnectionPositionPair({ originX: 'start', originY: 'top' }, { overlayX: 'start', overlayY: 'bottom' }),
        ])
        .withDefaultOffsetY(this.offsetY)
        .withPush(false),
    });

    const templatePortal = new TemplatePortal(this.dropdownPanel.templateRef, this.viewContainerRef);
    this.overlayRef.attach(templatePortal);
    this.dropDownOpened.emit();

    this.dropdownClosingActionsSub = this.dropdownClosingActions().subscribe({
      next: () => {
        this.destroyDropdown();
      },
    });
  }

  /**
   * Triggers that will close the dropdown
   */
  private dropdownClosingActions(): Observable<any> {
    return this.overlayRef.backdropClick();
  }

  /**
   * Destroys the dropdown and detaches the overlay ref
   */
  destroyDropdown(): void {
    if (!this.overlayRef || !this.isDropdownOpen) {
      return;
    }

    this.dropdownClosingActionsSub.unsubscribe();
    this.isDropdownOpen = false;
    this.overlayRef.detach();
    this.dropDownClosed.emit();
    this.elementRef.nativeElement.focus();
  }

  /**
   * Dispose the overlay ef if exists
   */
  ngOnDestroy(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
