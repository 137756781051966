import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownContainerComponent } from './components';
import { DropdownTriggerForDirective } from './directives';
import { OverlayModule } from '@angular/cdk/overlay';

const COMPONENTS = [DropdownContainerComponent];

const DIRECTIVES = [DropdownTriggerForDirective];

@NgModule({
  declarations: [...COMPONENTS, ...DIRECTIVES],
  imports: [CommonModule, OverlayModule],
  exports: [...COMPONENTS, ...DIRECTIVES],
})
export class DropdownModule {}
