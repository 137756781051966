import { Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { TemplateRef, Type } from '@angular/core';

export interface OverlayCloseEvent<R> {
  type: 'backdropClick' | 'close';
  data: R;
}

// R = Response Data Type, T = Data passed to Modal Type
export class ModalRef<R = any, T = any> {
  private afterClosedSubject = new Subject<OverlayCloseEvent<R>>();

  constructor(
    protected overlay: OverlayRef,
    protected content: string | TemplateRef<any> | Type<any>,
    protected data: T // pass data to modal i.e. FormData
  ) {}

  close(data?: R): void {
    this._close('close', data);
  }

  get afterClosed$(): Observable<OverlayCloseEvent<R>> {
    return this.afterClosedSubject.asObservable();
  }

  closeOnBackdropClick(): void {
    this.overlay.backdropClick().subscribe(() => this._close('backdropClick', null));
  }

  getData(): T {
    return this.data;
  }

  getContent(): string | TemplateRef<any> | Type<any> {
    return this.content;
  }

  getOverlayRef(): OverlayRef {
    return this.overlay;
  }

  private _close(type: 'backdropClick' | 'close', data: R): void {
    this.overlay.dispose();
    this.afterClosedSubject.next({
      type,
      data,
    });

    this.afterClosedSubject.complete();
  }
}
