import { animate, style, transition, trigger } from '@angular/animations';
import { Component, HostBinding, Input, OnInit, TemplateRef, Type } from '@angular/core';
import { ModalRef } from '../../modal-ref';

@Component({
  selector: 'eg-app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [
    trigger('dialog', [
      //transition('void => *', [style({ transform: 'scale3d(.3, .3, .3)' }), animate(300)]),
      transition('* => void', [animate(300, style({ transform: 'scale3d(.0, .0, .0)' }))]),
    ]),
  ],
})
export class OverlayComponent implements OnInit {
  @HostBinding('class') classes = 'eg-modal';

  contentType: 'template' | 'string' | 'component';
  titleType: 'template' | 'string';
  content: string | TemplateRef<any> | Type<any>;
  context: any = {};
  @Input() title: string | TemplateRef<any>;
  @Input() showHeader = true;
  @Input() width: string;

  constructor(private modalRef: ModalRef) {}

  ngOnInit(): void {
    this.content = this.modalRef.getContent();
    this.titleType = this.getTitleType();

    if (typeof this.content === 'string') {
      this.contentType = 'string';
      return;
    }

    if (this.content instanceof TemplateRef) {
      this.contentType = 'template';

      this.context = {
        ...this.modalRef.getData(),
        close: this.modalRef.close.bind(this.modalRef),
      };

      return;
    }

    this.content = this.modalRef.getContent();
    this.context = this.modalRef.getData();
    this.contentType = 'component';
  }

  close(): void {
    this.modalRef.close(undefined);
  }

  private getTitleType(): 'string' | 'template' {
    if (typeof this.title === 'string') {
      return 'string';
    }

    if (this.title instanceof TemplateRef) {
      return 'template';
    }
    return 'string';
  }
}
