import { translate } from '@ngneat/transloco';

export function removeFromArray<T>(arr: T[], item: T) {
  const index = arr.findIndex((el) => item === el);

  return arr.splice(index, 1);
}

export function enumToSelectItemArray(model: any, prefix: string, translateObj: string) {
  return Object.values(model).map((item) => {
    return { label: translate(prefix + translateObj + item), value: item };
  });
}
