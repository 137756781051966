import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractDateComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import dayjs from 'dayjs/esm';
import utc from 'dayjs/esm/plugin/utc';
import timezone from 'dayjs/esm/plugin/timezone';
import { DaterangepickerDirective } from 'ngx-daterangepicker-material';
import { UntilDestroy } from '@ngneat/until-destroy';

dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.extend(utc);
dayjs.extend(timezone);

export interface DatePickerValue {
  endDate: dayjs.Dayjs;
  startDate: dayjs.Dayjs;
}

export type DropdownAlignType = 'left' | 'right' | 'center';
export type DropdownDirectionType = 'down' | 'up';

export enum OutputType {
  datetime = 'datetime',
  date = 'date',
}

@UntilDestroy()
@Component({
  selector: 'eg-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DateRangeComponent)],
})
export class DateRangeComponent
  extends AbstractDateComponent<{
    endDate: any;
    startDate: any;
  }>
  implements OnInit
{
  @ViewChild(DaterangepickerDirective) dateRangePickerDirective: DaterangepickerDirective;
  @Input() linkedCalendars = true;
  @Input() dateLimit: number;
  @Input() dropdownAlign: DropdownAlignType = 'right';
  @Input() dropdownDirection: DropdownDirectionType = 'down';
  @Input() output: OutputType = OutputType.datetime;
  @Input() showClearButton = false;

  get formattedValue(): string {
    if (!this.value?.endDate || !this.value?.startDate) {
      return undefined;
    }

    return `${this.value.startDate.tz(dayjs.tz.guess(), true).format(this.locale.format)} - ${this.value.endDate
      .tz(dayjs.tz.guess(), true)
      .format(this.locale.format)}`;
  }

  /**
   * emits selected interval
   * @param value
   */
  onInputChanged(value: DatePickerValue): void {
    if (!value?.endDate || !value?.startDate) {
      return;
    }

    this.value = value;
    this.onChange(this.getFormattedValue(value));
    this.changed.emit(this.getFormattedValue(value));
  }

  openDatePicker(event: MouseEvent): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }

    this.dateRangePickerDirective.toggle();
    event.stopPropagation();
    event.preventDefault();
  }

  override writeValue(value: { endDate: any; startDate: any }): void {
    if (value?.startDate && value?.endDate) {
      super.writeValue({
        endDate: dayjs(value?.endDate).tz(dayjs.tz.guess(), true),
        startDate: dayjs(value?.startDate).tz(dayjs.tz.guess(), true),
      });
    }

    if (value === null) {
      super.writeValue(value);
      this.dateRangePickerDirective.clear();
    }
  }

  override setDisabledState(isDisabled: boolean): void {
    super.setDisabledState(isDisabled);
    if (isDisabled === this.control.disabled) {
      return;
    }
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.control[disableFn]({ emitEvent: false });
  }

  getFormattedValue(value: DatePickerValue): any {
    let start: string;
    let end: string;

    switch (this.output) {
      case OutputType.datetime:
        start = value.startDate.tz(dayjs.tz.guess(), true).toISOString();
        end = value.endDate.tz(dayjs.tz.guess(), true).toISOString();
        break;
      case OutputType.date:
        start = value.startDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
        end = value.endDate.tz(dayjs.tz.guess(), true).format('YYYY-MM-DD');
        break;
      default:
        start = value.startDate.tz(dayjs.tz.guess(), true).toISOString();
        end = value.endDate.tz(dayjs.tz.guess(), true).toISOString();
    }

    return { startDate: start, endDate: end };
  }
}
