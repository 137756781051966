import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CheckboxComponent } from './checkbox.component';
import { FormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, FormsModule, FormErrorModule],
  exports: [CheckboxComponent],
})
export class CheckboxModule {}
