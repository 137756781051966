import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { AuthStore } from './auth.store';
import { AuthState } from './auth.state';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RailMyBoxRoles } from '../../util';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  constructor(protected store: AuthStore) {
    super(store);
  }

  get loggedIn$(): Observable<boolean> {
    return this.select('accessToken').pipe(map((token) => !!token));
  }

  getUserEmail(): string {
    return this.getValue().email;
  }

  getUserRoles(): string[] {
    return this.getValue().roles;
  }

  hasRole(role: RailMyBoxRoles): boolean {
    return this.getUserRoles().includes(role);
  }

  /**
   * Retrieves the access token from the store.
   */
  getAccessToken(): string {
    return this.getValue().accessToken;
  }

  getUserLanguageValue(): string {
    return this.getValue().ui.userLanguage;
  }

  getUserLanguage(): Observable<string> {
    return this.select((state) => state.ui.userLanguage);
  }

  getRedirectPath(): string {
    return this.getValue().redirectTo;
  }

  hasAtLeastOnePermission(groups: string[]): Observable<boolean> {
    return this.select('roles').pipe(
      map((roles) => {
        return groups.some((group) => roles.includes(group));
      })
    );
  }

  getIdToken(): Observable<string> {
    return this.select('idToken');
  }

  getAuthMail(): Observable<string> {
    return this.select('email');
  }
}
