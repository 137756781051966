import { TemplateRef } from '@angular/core';

export interface Tab<T = any> {
  name: string;
  id: string;
  tabContent: TemplateRef<T>;
  selected?: boolean;
  tabContext?: T;
  disabled?: boolean;
}
