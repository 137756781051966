<eg-input
  [class]="{ 'input-invalid': control?.invalid && control?.touched }"
  [name]="name"
  autocomplete="off"
  class="input"
  [disabled]="disabled"
  [prefixInputPadding]="20"
  [fieldType]="fieldType"
  [label]="labelWithSuffix"
  [placeholder]="placeholder"
  [value]="value || null"
  (changed)="inputChanged($event)"
>
</eg-input>

@if (control?.errors) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
