import { Component, Input } from '@angular/core';

@Component({
  selector: 'rmb-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss'],
})
export class EmptyPageComponent {
  @Input() image: string;
  @Input() title = '';
  @Input() subtitle = '';
}
