import { Component } from '@angular/core';
import { ModalRef } from '@eg/ui';

@Component({
  selector: 'rmb-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss',
})
export class AccessDeniedComponent {
  readonly TRANSLOCO_PREFIX = 'confirmation';

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onResponseClick(response: string): void {
    this.modalRef.close(response);
  }
}
