import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AbstractValueAccessorComponent } from './abstract-value-accessor.component';
import dayjs from 'dayjs/esm';
import localData from 'dayjs/esm/plugin/localeData';
import 'dayjs/esm/locale/de';
import 'dayjs/esm/locale/en-gb';
import { LocaleConfig } from 'ngx-daterangepicker-material';
import { ControlContainer } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { PrimeNGConfig } from 'primeng/api';

dayjs.extend(localData);

@UntilDestroy()
@Component({ template: '' })
export abstract class AbstractDateComponent<T> extends AbstractValueAccessorComponent<T> implements OnInit {
  @Input() minDate: dayjs.Dayjs;
  @Input() maxDate: dayjs.Dayjs;
  @Input() set: string;
  @Input() cancel: string;
  locale: LocaleConfig = {};
  monthRange: any;
  @Input() dateMin: Date;
  @Input() dateMax: Date;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer,
    private translocoService: TranslocoService,
    private configPrime: PrimeNGConfig
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe({
      next: (language: string) => {
        language === 'en' ? (language = 'en-gb') : language;
        this.locale = this.setLocale(language);
        this.monthRange = this.setLocaleMonthRange(language);
        this.primeNGMonths(language);
        this.changeDetectorRef.detectChanges();
      },
    });
  }

  setLocaleMonthRange(locale: string) {
    let format: string;
    locale === 'de' ? (format = 'MM.YYYY') : (format = 'MM/YYYY');
    return format;
  }

  /**
   * sets date formatting
   */
  setLocale(locale = 'en'): LocaleConfig {
    const dayjsLocale = dayjs().locale(locale).localeData();
    const format = dayjsLocale.longDateFormat('L');
    const displayFormat = dayjsLocale.longDateFormat('L');
    return {
      format: format,
      displayFormat: displayFormat,
      daysOfWeek: dayjsLocale.weekdaysMin(),
      monthNames: dayjsLocale.monthsShort(),
      firstDay: dayjsLocale.firstDayOfWeek(),
      applyLabel: this.set,
      cancelLabel: this.cancel,
    };
  }

  primeNGMonths(locale = 'en') {
    const dayjsLocale = dayjs().locale(locale).localeData();
    this.configPrime.setTranslation({
      monthNamesShort: dayjsLocale.monthsShort(),
    });
  }
}
