import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class DirtyFormUtil<T = any> {
  private readonly head: T;

  constructor(private form: FormGroup) {
    this.head = form.getRawValue();
  }

  get isDirty(): boolean {
    return JSON.stringify(this.head) !== JSON.stringify(this.form.getRawValue());
  }

  get isDirty$(): Observable<boolean> {
    return this.form.valueChanges.pipe(
      map(() => {
        return JSON.stringify(this.head) !== JSON.stringify(this.form.getRawValue());
      })
    );
  }

  isDirtyControl(controlName: string): boolean {
    const control = this.form.get(controlName);
    if (!control) {
      throw Error('Control does not exist on form instance.');
    }

    return JSON.stringify(this.form.getRawValue()[controlName]) !== JSON.stringify(control.value);
  }

  selectDirtyControl(controlName: string): Observable<boolean> {
    return this.form.valueChanges.pipe(
      map(() => {
        return this.isDirtyControl(controlName);
      })
    );
  }
}
