import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { LoadpointService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { LoadpointModel } from '@railmybox/api-booking/model/models';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-container-terminal-select',
  templateUrl: './container-terminal-select.component.html',
  styleUrls: ['./container-terminal-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(ContainerTerminalSelectComponent)],
})
export class ContainerTerminalSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  containerTerminals$: Observable<LoadpointModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = false;

  constructor(
    private loadPointService: LoadpointService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.containerTerminals$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.loadPointService.listLoadpoints(query) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(terminal: LoadpointModel): void {
    this.selectedName = terminal?.name;
    this.value = terminal?.id;
    this.onChange(terminal?.id);
    this.changed.emit(terminal?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    const loadPoints = await this.loadPointService.listLoadpoints().toPromise();
    const selectedLoadPoint = loadPoints.find((point) => point.id === value);

    if (!selectedLoadPoint) {
      return;
    }
    this.selectedName = selectedLoadPoint.name;
  }
}
