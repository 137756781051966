import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BorderStyle, ButtonSize } from '../button-types';

@Component({
  selector: 'eg-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent {
  @Input() icon: string;
  @Input() alt: string;
  @Input() borderStyle: BorderStyle = 'border';
  @Input() buttonSize: ButtonSize = 'default';

  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();
  onIconClick(event: MouseEvent): void {
    this.clicked.emit(event);
    event.stopPropagation();
  }
}
