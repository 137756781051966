import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WishlistCardComponent } from './wishlist-card.component';
import { TranslocoModule } from '@ngneat/transloco';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { InputModule } from '@eg/ui';
import { SharedPipesModule } from '@railmybox/shared/pipes';

@NgModule({
  declarations: [WishlistCardComponent],
  imports: [CommonModule, ReactiveFormsModule, InputModule, TranslocoModule, FlexLayoutModule, SharedPipesModule],
  exports: [WishlistCardComponent],
})
export class WishlistCardModule {}
