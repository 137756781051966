import { CustomerModel } from '@railmybox/api-user';

export interface UserAttributes extends CustomerModel {
  address: string;
  email_verified: string;
  locale: string;
  given_name: string; // First Name
  family_name: string; // Last Name
  sub: string; // User Id
  email: string;
  phone_number: string;
  timeZone?: string;
  'custom:time_zone'?: string;
}

export interface UserState {
  email: string;
  attributes: UserAttributes;
}

export function createInitialUserState(): UserState {
  return {
    email: null,
    attributes: undefined,
  };
}
