import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexModule } from '@ngbracket/ngx-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalModule } from '@eg/ui';
import { BookingConfirmationComponent } from './booking-confirmation.component';

@NgModule({
  declarations: [BookingConfirmationComponent],
  imports: [CommonModule, TranslocoModule, FlexModule, AngularSvgIconModule.forRoot(), ModalModule],
  exports: [BookingConfirmationComponent],
})
export class BookingConfirmationModule {}
