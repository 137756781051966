import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';
import { MonthRangeComponent } from './month-range.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [MonthRangeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, InputModule, CalendarModule],
  exports: [MonthRangeComponent],
})
export class MonthRangeModule {}
