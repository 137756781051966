import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from './input.component';
import { FormErrorModule } from '../form-error/form-error.module';
import { FormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [InputComponent],
  imports: [CommonModule, FormErrorModule, FormsModule, SharedDirectiveModule, NgxMaskDirective, NgxMaskPipe],
  exports: [InputComponent],
  providers: [provideNgxMask()],
})
export class InputModule {}
