import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VatInputComponent } from './vat-input.component';
import { FlexLayoutModule, FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule, InputModule } from '@eg/ui';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [VatInputComponent],
  imports: [CommonModule, InputModule, FormErrorModule, FlexLayoutModule, ReactiveFormsModule, FlexModule, SharedDirectiveModule],
  exports: [VatInputComponent],
})
export class VatModule {}
