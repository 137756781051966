import { AddressModel as APIAddressModel } from '@railmybox/api-booking';

export class AddressModel {
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  googlePlaceId?: string;
  city?: string;
  postalCode?: string;
  streetName?: string;
  streetNumber?: string;
  country?: string;
  address?: string;
  companyName?: string;

  constructor(placeResult: google.maps.places.PlaceResult) {
    if (placeResult.address_components) {
      this.mapGooglePlaceToModel(placeResult);
    }
  }

  mapGooglePlaceToModel(placeResult: google.maps.places.PlaceResult) {
    placeResult.address_components.forEach((component) => {
      switch (component.types[0]) {
        case 'street_number':
          this.streetNumber = component.long_name;
          break;
        case 'route':
          this.streetName = component.long_name;
          break;
        case 'neighborhood':
        case 'locality':
          this.city = component.long_name;
          break;
        case 'sublocality':
          this.city = component.long_name;
          break;
        case 'postal_code':
          this.postalCode = component.long_name;
          break;
        case 'country':
          this.country = component.long_name;
          this.countryCode = component.short_name;
          break;
      }
    });

    this.companyName = placeResult.name;
    this.googlePlaceId = placeResult.place_id;
    this.address = placeResult.formatted_address;
    this.latitude = placeResult.geometry.location.lat();
    this.longitude = placeResult.geometry.location.lng();
  }
}

export function getAPIAddressModel(address: AddressModel): APIAddressModel | undefined {
  if (!address) {
    return undefined;
  }

  return {
    sourceId: address.googlePlaceId,
    source: 'google',
    name: address.companyName,
    formattedAddress: address.address,
    street: address.streetName,
    streetNumber: address.streetNumber,
    zipCode: address.postalCode,
    city: address.city,
    countryCode: address.countryCode,
    latitude: address.latitude,
    longitude: address.longitude,
  };
}
