import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'egFind',
})
export class EgFindPipe implements PipeTransform {
  transform<T>(collection: T[], findBy: keyof T, value: string): T | undefined {
    return (collection || []).find((item) => (item[findBy] as unknown) === value);
  }
}
