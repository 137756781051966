import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AccountInformationModel, CustomerService } from '@railmybox/api-user';
import { distinctUntilChanged, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'rmb-billing-account-info',
  templateUrl: './billing-account-info.component.html',
  styleUrls: ['./billing-account-info.component.scss'],
})
export class BillingAccountInfoComponent implements OnInit {
  readonly TRANSLOCO_PREFIX = 'billing.iban';
  private _billingAccount: AccountInformationModel;
  _form: FormGroup<any>;

  @Input() set form(form: FormGroup) {
    this._form = form;
  }

  get form(): FormGroup {
    return this._form;
  }

  @Input() set billingAccount(model: AccountInformationModel) {
    this._billingAccount = model;
    this._form.patchValue({ ...model });
  }

  get billingAccount(): AccountInformationModel {
    return this._billingAccount;
  }

  @Output() changed: EventEmitter<AccountInformationModel> = new EventEmitter<AccountInformationModel>();

  constructor(private formBuilder: FormBuilder, private customerService: CustomerService) {
    this._form = this.getBillingAccountForm();
  }

  ngOnInit(): void {
    this.formListener();
  }

  public validateIban(): void {
    const ibanControl = this._form?.get('iban');

    this.customerService.validateIban({ iban: ibanControl.value }).subscribe((ibanValidationResultModel) => {
      if (ibanValidationResultModel.valid) {
        this._form?.patchValue({ iban: ibanValidationResultModel.formattedIban });
        this._form?.get('iban').setErrors(null);
        return;
      }

      this._form?.get('iban').setErrors({ ibanValidity: true });
      this._form?.updateValueAndValidity();
    });
  }

  private getBillingAccountForm(): FormGroup {
    return this.formBuilder.group({
      bankAccountOwner: [undefined, [Validators.required]],
      iban: [undefined, [Validators.required]],
    });
  }

  private formListener() {
    this._form?.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this), take(1)).subscribe({
      next: (data: AccountInformationModel) => {
        this.changed.emit(data);
      },
    });
  }
}
