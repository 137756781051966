<div class="card" fxFlex="100" fxLayout="column" fxLayoutAlign="center center" *transloco="let translate">
  <svg-icon class="card__close" src="assets/icons/close.svg" (click)="onCloseClick()"></svg-icon>

  <svg-icon class="card__image" src="/assets/icons/key.svg"></svg-icon>

  <div class="card__headline">
    {{ translate(TRANSLOCO_PREFIX + '.' + source + '.title') }}
  </div>

  <div class="card__text">
    {{ translate(TRANSLOCO_PREFIX + '.' + source + '.text', { value: data }) }}
  </div>

  <button egButton class="card__button" (click)="onResponseClick()">
    {{ translate(TRANSLOCO_PREFIX + '.action.' + type) }}
  </button>
</div>
