import { Order } from '@datorama/akita';

export enum SortDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export function naturalSort<T, S extends { ui: { sortDirection: SortDirection } }>(key: keyof T) {
  return (a: T, b: T, state: S) => {
    const options: any = {
      numeric: true,
      sensitivity: 'base',
    };
    const firstComparator = (a[key] as unknown as string) || '';
    const secondComparator = (b[key] as unknown as string) || '';

    if (!state.ui.sortDirection) {
      return 0;
    }

    return state.ui.sortDirection === SortDirection.ASC
      ? firstComparator.localeCompare(secondComparator, undefined, options)
      : secondComparator.localeCompare(firstComparator, undefined, options);
  };
}

export function arrayNaturalSort<T>(arrayToBeSorted: T[], key: keyof T, sortDirection?: SortDirection): T[] {
  const options: any = {
    numeric: true,
    sensitivity: 'base',
  };

  return [...arrayToBeSorted].sort((a, b) => {
    if (!sortDirection) {
      return 0;
    }

    return sortDirection === SortDirection.ASC
      ? (a[key] || '').toString().localeCompare((b[key] || '').toString(), undefined, options)
      : (b[key] || '').toString().localeCompare((a[key] || '').toString(), undefined, options);
  });
}

export function valueGetterFnSort<T, S extends { ui: { sortDirection: SortDirection; valueGetterFn?: (data: T) => string } }>() {
  return (a: T, b: T, state: S) => {
    const options: any = {
      numeric: true,
      sensitivity: 'base',
    };
    if (!state.ui.sortDirection || !state.ui.valueGetterFn) {
      return 0;
    }

    const firstComparator = (state.ui.valueGetterFn(a) as unknown as string) || '';
    const secondComparator = (state.ui.valueGetterFn(b) as unknown as string) || '';
    return state.ui.sortDirection === SortDirection.ASC
      ? firstComparator.toString().localeCompare(secondComparator, undefined, options)
      : secondComparator.toString().localeCompare(firstComparator, undefined, options);
  };
}

export function compareValues(key: any, order = Order.ASC) {
  return function (a: any, b: any) {
    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order == Order.DESC ? comparison * -1 : comparison;
  };
}
