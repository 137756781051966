import { animate, animation, state, style, transition, trigger } from '@angular/animations';

export const slideAnimation = trigger('slide', [
  transition(':enter', [style({ height: 0, opacity: 0, paddingTop: 0 }), animate(200)]),
  transition(':leave', [animate(200, style({ height: 0, opacity: 0, paddingTop: 0 }))]),
]);

export const enterAnimation = trigger('enter', [
  transition(
    ':enter',
    animation([
      style({
        transform: 'translate(200px,0)',
      }),
      animate(
        '0.3s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
        style({
          transform: 'translate(0)',
        })
      ),
    ])
  ),
  transition(
    ':leave',
    animation([
      style({ transform: 'translate(0)' }),
      animate(
        '0.3s cubic-bezier(0.59, 0.32, 0.38, 1.13)',
        style({
          transform: 'translate(-200px,0)',
        })
      ),
    ])
  ),
]);

export const opacityAnimation = trigger('enabledStateChange', [
  state(
    'default',
    style({
      opacity: 1,
    })
  ),
  state(
    'disabled',
    style({
      opacity: 0.5,
    })
  ),
  transition('* => *', animate('300ms ease-out')),
]);

export const slideFadeAnimation = trigger('fadeSlideInOut', [
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(10px)' }),
    animate('500ms', style({ opacity: 1, transform: 'translateY(0)' })),
  ]),
  transition(':leave', [animate('500ms', style({ opacity: 0, transform: 'translateY(10px)' }))]),
]);
