import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencySymbolPipe, EgDatePipe, EgFindPipe, EgNumberPipe, TruncatePipe } from './pipes';
import { TranslocoModule } from '@ngneat/transloco';

const PIPES = [CurrencySymbolPipe, EgDatePipe, EgFindPipe, EgNumberPipe, TruncatePipe];

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [...PIPES],
  exports: [...PIPES],
  providers: [...PIPES],
})
export class SharedPipesModule {}
