<div *transloco="let translate" class="header">
  <img src="/assets/icons/btn_prev.svg" alt="back" (click)="back()" />

  @if (edit) {
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start">
    <h1 class="subtitle-h2 mx-30 my-0">{{ title }}</h1>
    @if (subtitle) {
    <div class="subtitle-h3 mx-30 my-1">{{ subtitle }}</div>
    }
  </div>
  } @else {
  <div fxFlex="100" fxLayout="column" fxLayoutAlign="flex-start">
    <h1 class="subtitle-h2 mx-30 my-0">{{ createHeader }}</h1>
    @if (subtitle) {
    <div class="subtitle-h3 mx-30 my-1">{{ subtitle }}</div>
    }
  </div>
  }

  <div fxFlex="100" fxLayoutAlign="end center" fxLayout="row">
    <ng-content></ng-content>
    @if (showSaveButton) {
    <div fxFlex fxLayoutAlign="end center" fxLayout="row wrap" fxLayoutGap="8px">
      <button egButton class="px-44" egType="round" egButtonSize="small" (click)="save()" [disabled]="saveDisabled">
        {{ translate('support.button.save') }}
      </button>
    </div>
    }
  </div>
</div>
