<eg-select
  [readonly]="readonly"
  [value]="value"
  [labelTemplate]="labelTemplate"
  [optionTemplate]="optionTemplate"
  [items]="(items$ | async) || []"
  [typeahead]="typeahead$"
  [disabled]="disabled"
  (changed)="onValueSelect($event)"
  (blurred)="onBlur()"
  [clearable]="false"
>
</eg-select>

<ng-template #labelTemplate let-item="item">
  {{ item.code ? '+' + item.code[0] : '' }}
</ng-template>

<ng-template #optionTemplate let-item="item"> {{ item.label }} ( +{{ item.code[0] }} ) </ng-template>
