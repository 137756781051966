import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { OverlayComponent } from '../components';
import { ModalRef } from '../modal-ref';
import { DEFAULT_MODAL_CONFIG, ModalConfig } from '../modal-config';

@Injectable()
export class ModalService {
  constructor(private overlay: Overlay, private injector: Injector) {}

  /**
   * Merges the default config with user provided config
   * @private
   */
  private static getConfig<T>(modalConfig: ModalConfig<T>): ModalConfig<T> {
    return {
      ...DEFAULT_MODAL_CONFIG,
      ...modalConfig,
    };
  }

  open<R = any, T = any>(modalConfig: ModalConfig<T>): ModalRef<R> {
    modalConfig = ModalService.getConfig(modalConfig);

    const configs = new OverlayConfig({
      positionStrategy: modalConfig.positionStrategy || this.overlay.position().global().centerHorizontally().centerVertically(),
      hasBackdrop: true,
      panelClass: [modalConfig.overlayClass || 'eg-modal-overlay', 'is-active'],
      backdropClass: 'eg-modal-background',
    });

    const overlayRef = this.overlay.create(configs);

    const modalRef = new ModalRef<R, T>(overlayRef, modalConfig.content, modalConfig.data);

    if (modalConfig.backdropClose) {
      modalRef.closeOnBackdropClick();
    }

    const injector = this.createInjector(modalRef);
    const componentRef = overlayRef.attach(new ComponentPortal(OverlayComponent, null, injector));

    componentRef.instance.width = modalConfig.width;
    componentRef.instance.title = modalConfig.title;
    componentRef.instance.showHeader = modalConfig.showHeader;

    return modalRef;
  }

  /**
   * Creates a portal injector
   */
  private createInjector(ref: ModalRef): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: ModalRef, useValue: ref }],
    });
  }
}
