import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { CostService, CostsTypeInfoModel } from '@railmybox/api-pricing';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { Observable, from, groupBy, mergeMap, reduce, toArray } from 'rxjs';

@Component({
  selector: 'rmb-costs-type-select',
  templateUrl: './costs-type-select.component.html',
  styleUrl: './costs-type-select.component.scss',
  providers: [...ValueAccessorUtil.getValueAccessorProvider(CostsTypeSelectComponent)],
})
export class CostsTypeSelectComponent extends AbstractValueAccessorComponent<CostsTypeInfoModel> implements OnInit {
  groupedCostsType$: Observable<CostsTypeInfoModel[]>;
  @Input() clearable = true;
  @Input() selectedCostType: CostsTypeInfoModel;

  constructor(
    private costsTypeService: CostService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.groupedCostsType$ = this.costsTypeService.listCostTypes().pipe(
      mergeMap((items) =>
        from(items).pipe(
          groupBy((item) => item.category),
          mergeMap((group) =>
            group.pipe(
              reduce(
                (acc, cur) => {
                  acc.types.push(cur);
                  return acc;
                },
                { category: group.key, types: [] }
              )
            )
          ),
          toArray()
        )
      )
    );
  }

  onCostsTypeSelect(costsType: CostsTypeInfoModel): void {
    if (costsType) {
      this.selectedCostType = { ...costsType };
      this.value = costsType;
    } else {
      this.selectedCostType = null;
      this.value = null;
    }

    this.onChange(costsType);
    this.changed.emit(costsType);
  }

  writeValue(value: CostsTypeInfoModel) {
    super.writeValue(value);

    if (!value) {
      this.selectedCostType = null;
      this.value = null;
      return;
    }

    this.selectedCostType = value;
    this.value = value;
  }
}
