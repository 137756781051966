import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[egAutoFocus]',
})
export class AutoFocusDirective implements AfterViewInit {
  @Input() selector = 'input';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.querySelector(this.selector)?.focus();
  }
}
