import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { BookingServicesComponent } from './booking-services.component';

@NgModule({
  declarations: [BookingServicesComponent],
  imports: [CommonModule, FlexModule, ReactiveFormsModule],
  providers: [],
  exports: [BookingServicesComponent],
})
export class BookingServicesModule {}
