import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { DepotModel, DepotService } from '@railmybox/api-booking';
import { ControlContainer, FormControl } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { untilDestroyed } from '@ngneat/until-destroy';
import { switchMap } from 'rxjs/operators';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-depot-select',
  templateUrl: './depot-select.component.html',
  styleUrls: ['./depot-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(DepotSelectComponent)],
})
export class DepotSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  depotList$: Observable<DepotModel[]>;
  searchControl: FormControl = new FormControl(undefined);
  selectedName: string;
  @Input() clearable = false;

  constructor(
    private depotService: DepotService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.depotList$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.depotService.listDepots(query) : of([]))),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(depotModel: DepotModel): void {
    this.selectedName = depotModel?.name;
    this.value = depotModel?.id;
    this.onChange(depotModel?.id);
    this.changed.emit(depotModel?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      return;
    }

    const depot = await this.depotService
      .listDepots()
      .toPromise()
      .then((filteredValues) => filteredValues.find((depot) => depot.id === value));

    // In case no entry found with that id
    if (!depot) {
      return;
    }

    this.value = depot.id;
    this.selectedName = depot.name;
  }
}
