import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexModule } from '@ngbracket/ngx-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@eg/ui';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { MainComponent } from './main/main.component';
import { SupportNavComponent } from './main/support-nav/support-nav.component';

@NgModule({
  imports: [CommonModule, RouterModule, ReactiveFormsModule, UiModule, FlexModule, TranslocoModule],
  declarations: [MainComponent, SupportNavComponent],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: [{ scope: 'support', alias: 'support' }],
    },
  ],
  exports: [MainComponent, SupportNavComponent],
})
export class LayoutModule {}
