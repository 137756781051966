// Define types
type JsonPrimitive = string | number | boolean | null;
type JsonValue = JsonPrimitive | JsonObject | JsonArray;
interface JsonObject {
  [key: string]: JsonValue;
}
interface JsonArray extends Array<JsonValue> {}

// Type guard for JsonArray
const isJsonArray = (value: any): value is JsonArray => Array.isArray(value);

// Type guard for JsonPrimitive
const isJsonPrimitive = (value: any): value is JsonPrimitive => ['string', 'number', 'boolean'].includes(typeof value) || value === null;

// Standalone function to get roles
export const getRoles = (session): string[] => {
  const payload = session.tokens.accessToken.payload;
  const groups = payload['cognito:groups'];

  return isJsonArray(groups) ? groups.filter(isJsonPrimitive).map(String) : isJsonPrimitive(groups) ? [String(groups)] : [];
};
