import { Component, EventEmitter, Input, OnInit, Optional, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { DropdownTriggerForDirective } from '../../dropdown/directives';
import { toKebabCase } from '@railmybox/shared/util';

@UntilDestroy()
@Component({
  selector: 'eg-card-select',
  templateUrl: './card-select.component.html',
  styleUrls: ['./card-select.component.scss'],
})
export class CardSelectComponent<T, S = unknown> implements OnInit {
  @ViewChild(DropdownTriggerForDirective) dropDownDirective: DropdownTriggerForDirective;
  @Input() items: T[] = [];
  @Input() searchPlaceholder: string;
  @Input() itemTemplate: TemplateRef<{ item: T }>;
  @Input() tileTemplate: TemplateRef<any>;
  @Input() searchDebounceTime = 300;
  @Input() hideAllItemsOnEmptyQuery = true;
  @Input() disabled: boolean;
  @Input() dropdownClass: string;
  @Input() clearable = true;
  @Input() value: any;
  @Input() selectedIndex = -1;
  @Input() keyboardNavigation = true;
  @Input() hideSearch = false;

  @Output() searchValueChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() dropdownOpened: EventEmitter<void> = new EventEmitter<void>();
  @Output() dropdownClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() valueSelection: EventEmitter<S> = new EventEmitter<S>();

  searchControl: FormControl = new FormControl();
  showDropdown = false;

  constructor(@Optional() private ngControl: NgControl) {
    this.dropdownClass = (this.dropdownClass || '') + toKebabCase((ngControl?.name as string) || '').replace('-id', '');
  }

  ngOnInit(): void {
    this.searchControl.valueChanges.pipe(untilDestroyed(this), debounceTime(this.searchDebounceTime), distinctUntilChanged()).subscribe({
      next: (value) => {
        if (!value && this.hideAllItemsOnEmptyQuery) {
          this.items = [];

          return;
        }
        this.searchValueChange.emit(value);
      },
    });
  }

  closeDropdown(): void {
    this.dropDownDirective.destroyDropdown();
    this.showDropdown = false;
    this.searchControl.setValue(undefined, { emitEvent: false });
    if (this.hideAllItemsOnEmptyQuery && !this.hideSearch) {
      this.items = [];
    }
  }

  onOptionSelect(item: S): void {
    this.valueSelection.emit(item);
    this.dropdownClosed.emit();
    this.closeDropdown();
  }

  onDropDownClose(): void {
    this.showDropdown = false;
  }

  onDropDownOpen(): void {
    this.showDropdown = true;
  }

  onClear(event: MouseEvent): void {
    this.valueSelection.emit(undefined);
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Tab') {
      this.closeDropdown();
      return;
    }
    if (!this.keyboardNavigation || !this.items) return;
    if (event.key === 'ArrowDown') {
      this.selectedIndex = Math.min(this.items.length - 1, this.selectedIndex + 1);
    } else if (event.key === 'ArrowUp') {
      this.selectedIndex = Math.max(0, this.selectedIndex - 1);
    } else if (event.key === 'Enter') {
      this.onOptionSelect(this.items[this.selectedIndex] as unknown as S);
      this.selectedIndex = -1;
    }
  }
}
