import { Component, Input } from '@angular/core';
import { ModalRef } from '@eg/ui';

@Component({
  selector: 'rmb-security-question',
  templateUrl: './security-question.component.html',
  styleUrl: './security-question.component.scss',
})
export class SecurityQuestionComponent {
  @Input() source: string;
  @Input() prefix: string;

  constructor(private modalRef: ModalRef) {}

  onCloseClick(): void {
    this.modalRef.close();
  }

  onResponseClick(response: string): void {
    this.modalRef.close(response);
  }
}
