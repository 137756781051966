import { Component, HostBinding, Input, TemplateRef } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

type TYPE = 'template' | 'string';

@Component({
  selector: 'eg-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [style({ opacity: 0 }), animate(300, style({ opacity: 1 }))]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class TooltipComponent {
  @HostBinding('class') classes: string;
  @HostBinding('@tooltip') tooltip = true;
  tooltipType: TYPE;
  _content: string | TemplateRef<any>;
  _context: any;
  _index: number;
  @Input() set content(content: string | TemplateRef<any>) {
    this.tooltipType = content instanceof TemplateRef ? 'template' : 'string';
    this._content = content;
  }
  @Input() set context(context: any) {
    this._context = context;
  }

  @Input() set index(index: number) {
    this._index = index;
  }
}
