import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { DispoOrderChainComponent } from './dispo-order-chain.component';
import { UiModule } from '@eg/ui';
import { SharedPipesModule } from '@railmybox/shared/pipes';

@NgModule({
  declarations: [DispoOrderChainComponent],
  imports: [CommonModule, TranslocoModule, FlexLayoutModule, UiModule, SharedPipesModule],
  exports: [DispoOrderChainComponent],
})
export class DispoOrderChainModule {}
