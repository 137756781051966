import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { ControlContainer, FormControl } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { OperatorModel, OperatorService } from '@railmybox/api-dispo';
import { map, Observable, of, switchMap } from 'rxjs';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';

@Component({
  selector: 'rmb-operator-select',
  templateUrl: './operator-select.component.html',
  styleUrls: ['./operator-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(OperatorSelectComponent)],
})
export class OperatorSelectComponent extends AbstractValueAccessorComponent<string> implements OnInit {
  operators$: Observable<OperatorModel[]>;
  searchControl: FormControl = new FormControl<string | null>(undefined);
  selectedName: string;
  @Input() filter: string;
  @Input() selected: string;
  @Input() clearable = true;

  constructor(
    private operatorService: OperatorService,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.operators$ = this.searchControl.valueChanges.pipe(
      switchMap((query: string) => (query ? this.operatorService.adminListOperators(query) : of([]))),
      map((operators: OperatorModel[]) =>
        this.filter ? operators.filter((model) => `${model.id} ${model.name}`.toLowerCase().includes(this.filter.toLowerCase())) : operators
      ),
      untilDestroyed(this)
    );
  }

  onSearchValueChanged(query: string): void {
    this.searchControl.patchValue(query);
  }

  onSelect(operator: OperatorModel): void {
    this.selectedName = operator?.name;
    this.value = operator?.id;
    this.onChange(operator?.id);
    this.changed.emit(operator?.id);
  }

  async writeValue(value: string): Promise<void> {
    super.writeValue(value);

    if (!value) {
      this.selectedName = null;
      return;
    }
    const operator = await this.operatorService
      .adminListOperators()
      .toPromise()
      .then((filteredValues) => filteredValues.find((operator) => operator.id === value));

    // In case no entry found with that id
    if (!operator) {
      return;
    }

    this.value = operator.id;
    this.selectedName = operator.name;
  }
}
