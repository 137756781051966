<form [formGroup]="phoneForm" fxLayout="row wrap" fxFlex="100">
  <eg-input
    name="phoneNumber"
    formControlName="phoneNumber"
    autocomplete="off"
    class="phone-number"
    [prefixInputPadding]="20"
    [fieldType]="formRule"
    [label]="labelWithSuffix"
    [placeholder]="placeholder"
    (blurred)="onBlur()"
  >
    <rmb-country-select
      egFieldPrefixDirective
      class="select-country-code"
      formControlName="phoneCountryCode"
      [countryList]="countryList$ | async"
      [placeholderMessage]="phoneType"
      [readonly]="formRule === 'R'"
      (blurred)="onBlur()"
    >
    </rmb-country-select>
  </eg-input>
</form>
