<div fxFlex="100" fxLayout="row" class="breadcrumb__container">
  <div class="breadcrumb__container--back-padding" [fxFlex]="breadcrumbPadding"></div>
  <div fxFlex="100">
    @for (breadcrumb of breadcrumbs; track breadcrumb; let i = $index) {
    <span [class.enabled]="!breadcrumb.disabled" [class.disabled]="breadcrumb.disabled" (click)="breadcrumbClicked(breadcrumb)">
      @if (i > 0) {
      <img class="breadcrumb__separator" src="/assets/icons/breadcrumb_separator.svg" />
      }
      <span class="breadcrumb__number mr-4" [class.ml-24]="i > 0">{{ i + 1 }}</span>
      {{ breadcrumb.name | transloco }}
    </span>
    }
  </div>
</div>
