import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DateComponent } from './date.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { FormErrorModule } from '../form-error/form-error.module';
import { InputModule } from '../input/input.module';

@NgModule({
  declarations: [DateComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FormErrorModule,
    ReactiveFormsModule,
    InputModule,
    NgxDaterangepickerMd.forRoot(),
  ],
  exports: [DateComponent],
})
export class DateModule {}
