<div fxFlex="100" fxLayout="column" fxLayoutAlign="start" fxFlexFill *transloco="let translate">
  @if (tabs$ | async; as tabs) {
  <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="center center" class="tab-group">
    @for (tab of tabs; track tab; let idx = $index) {
    <div
      class="tab-item"
      [class.tab-item--selected]="tab.id === selectedTab?.id"
      [class.tab-item--disabled]="tab.disabled"
      [fxFlex]="100 / tabs.length"
      fxLayout="row"
      fxLayoutAlign="center center"
      (click)="onTabClick(tab)"
    >
      <span>{{ translateLabels ? translate(tab.name, { index: idx + 1 }) : tab.name }}</span>
    </div>
    }
  </div>
  }

  <div fxFlex="100" fxFlexFill fxLayout="row wrap">
    <ng-container *ngTemplateOutlet="selectedTab?.tabContent; context: selectedTab?.tabContext"></ng-container>
  </div>
</div>
