/// <reference types="@types/googlemaps" />
import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AddressModel, getAPIAddressModel } from '../address.model';
import { AddressModel as APIAddressModel } from '@railmybox/api-booking';

@Directive({
  selector: '[rmbGoogleAutocomplete]',
})
export class GoogleAutocompleteDirective implements OnInit, OnDestroy {
  @Input() inputSelector = 'input';
  @Input() countries: string[] = ['de'];
  @Output() placeChanged: EventEmitter<AddressModel> = new EventEmitter<AddressModel>();
  @Output() apiAddressChange: EventEmitter<APIAddressModel> = new EventEmitter<APIAddressModel>();
  autoCompleteListener: google.maps.MapsEventListener;

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    const options = {
      componentRestrictions: { country: this.countries },
    };

    setTimeout(() => {
      const autocomplete = new google.maps.places.Autocomplete(this.elementRef.nativeElement.querySelector(this.inputSelector), options);
      this.autoCompleteListener = autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();

        const address = new AddressModel(place);
        this.placeChanged.emit(address);
        this.apiAddressChange.emit(getAPIAddressModel(address));
      });
    }, 0);
  }

  ngOnDestroy(): void {
    this.autoCompleteListener?.remove();
  }
}
