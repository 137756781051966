import {
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Injector,
  Input,
  NgModuleFactory,
  NgModuleRef,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  Type,
  ViewContainerRef,
} from '@angular/core';

@Directive({
  selector: '[egComponentOutlet]',
})
export class ComponentOutletDirective implements OnChanges, OnDestroy {
  @Input() egComponentOutlet: Type<any>;
  @Input() egComponentOutletInjector: Injector;
  @Input() egComponentOutletInputs: any = {};
  @Input() egComponentOutletContent: any[][];
  @Input() egComponentOutletNgModuleFactory: NgModuleFactory<any>;

  private _componentRef: ComponentRef<any> | null = null;
  private _moduleRef: NgModuleRef<any> | null = null;

  constructor(private _viewContainerRef: ViewContainerRef) {}

  ngOnChanges(changes: SimpleChanges) {
    this._viewContainerRef.clear();
    this._componentRef = null;

    if (this.egComponentOutlet) {
      const elInjector = this.egComponentOutletInjector || this._viewContainerRef.parentInjector;

      if (changes['ngComponentOutletNgModuleFactory']) {
        if (this._moduleRef) this._moduleRef.destroy();

        if (this.egComponentOutletNgModuleFactory) {
          const parentModule = elInjector.get(NgModuleRef);
          this._moduleRef = this.egComponentOutletNgModuleFactory.create(parentModule.injector);
        } else {
          this._moduleRef = null;
        }
      }

      const componentFactoryResolver = this._moduleRef
        ? this._moduleRef.componentFactoryResolver
        : elInjector.get(ComponentFactoryResolver);

      const componentFactory = componentFactoryResolver.resolveComponentFactory(this.egComponentOutlet);

      this._componentRef = this._viewContainerRef.createComponent(
        componentFactory,
        this._viewContainerRef.length,
        elInjector,
        this.egComponentOutletContent
      );

      this.applyInputs(this._componentRef.instance);
    }
  }

  ngOnDestroy() {
    if (this._moduleRef) {
      this._moduleRef.destroy();
    }
  }

  private applyInputs(instance: any) {
    Object.entries(this.egComponentOutletInputs).forEach(([key, value]) => {
      instance[key] = value;
    });
  }
}
