import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { AuthStoreService } from '@railmybox/auth';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(protected authStoreService: AuthStoreService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.authStoreService.getAuthQuery().getAccessToken();

    if (request.url.includes('api')) {
      if (accessToken) {
        const req = request.clone({
          setHeaders: {
            //'Content-Type': 'application/json; charset=utf-8',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          url: this.updatedUrl(request.url).replace('http:', 'https:'),
        });
        return next.handle(req);
      } else {
        const req = request.clone({
          url: this.updatedUrl(request.url).replace('http:', 'https:'),
        });
        return next.handle(req);
      }
    }
    const req = request.clone({
      url: request.url.replace('http:', 'https:'),
    });
    return next.handle(req);
  }

  /**
   * It will modify the url and add the correct base path on different environment
   *
   * @param {string} url
   *
   * @returns {string}
   */
  private updatedUrl(url: string): string {
    return url
      .split('/')
      .map((value, index) => {
        if (index === 2) {
          return environment.apiBasePath;
        }

        return value;
      })
      .join('/');
  }
}
