import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DropdownPanel } from '../../dropdown-panel.interface';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'eg-dropdown-container',
  templateUrl: './dropdown-container.component.html',
  styleUrls: ['./dropdown-container.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ height: 0, opacity: 0 }),
        animate(
          '.3s ease-out',
          style({
            height: '*',
            opacity: 1,
          })
        ),
      ]),
      transition(':leave', [
        style({ height: '*', opacity: 1 }),
        animate(
          '.3s ease-in',
          style({
            height: 0,
            opacity: 0,
          })
        ),
      ]),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownContainerComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef: TemplateRef<any>;
  @Input() classes: string;
  @Output() closed: EventEmitter<void> = new EventEmitter<void>();
}
