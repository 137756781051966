export function isSet(val: unknown): boolean {
  return val !== null && val !== undefined;
}

export function pickByKeys<T, K extends keyof T>(obj: T, keys: K[]): Record<K, T[K]> {
  const emptyObj: any = {};
  keys.forEach((key) => {
    emptyObj[key] = obj[key];
  });

  return emptyObj;
}

export function omitKeys<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const shallowClone = { ...obj };
  keys.forEach((key: K) => {
    delete shallowClone[key];
  });

  return shallowClone;
}

export function encodeObject(object: unknown): string {
  return btoa(JSON.stringify(object));
}

export function decodedObject(encodedString: string): unknown {
  return JSON.parse(atob(encodedString));
}
