import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { FlexModule } from '@ngbracket/ngx-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ModalModule } from '@eg/ui';
import { AccessDeniedComponent } from './access-denied.component';

@NgModule({
  declarations: [AccessDeniedComponent],
  imports: [CommonModule, TranslocoModule, FlexModule, AngularSvgIconModule.forRoot(), ModalModule],
  exports: [AccessDeniedComponent],
})
export class AccessDeniedModule {}
