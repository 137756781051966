import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import dayjs from 'dayjs/esm';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { DateFormatType, DateType, formatDateTime } from '@railmybox/shared/util';

@UntilDestroy()
@Pipe({
  name: 'egDate',
  pure: false,
})
export class EgDatePipe implements PipeTransform {
  private subscription: Subscription;
  private lastValue: string;
  private lastDate: DateType;
  constructor(private translocoService: TranslocoService, private changeDetectorRef: ChangeDetectorRef) {}

  transform(date: DateType, type: DateFormatType = 'date'): string {
    if (date === this.lastDate) {
      return this.lastValue;
    }

    this.lastDate = date;

    if (!date) return undefined;

    const activeLang = this.translocoService.getActiveLang();

    this.subscription?.unsubscribe();

    this.subscription = this.translocoService.langChanges$.pipe(untilDestroyed(this)).subscribe((lang) => {
      dayjs.locale(lang);

      this.lastValue = formatDateTime(lang, type, date);
      this.changeDetectorRef.markForCheck();
    });

    this.lastValue = formatDateTime(activeLang, type, date);

    return this.lastValue;
  }

  addDayToDisplay(date: DateType) {
    return dayjs(date).add(1, 'day')?.toISOString();
  }
}
