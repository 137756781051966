import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { selectPersistStateInit } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { AuthQuery } from '../store/auth';

@Injectable({ providedIn: 'root' })
export class LoginGuard {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authQuery.loggedIn$, selectPersistStateInit()]).pipe(
      map(([isLoggedIn]) => {
        if (isLoggedIn) {
          this.router.navigate(['/booking']); // @TODO: Replace this with landing page

          return false;
        }

        return true;
      })
    );
  }
}
