<eg-select
  [label]="labelWithSuffix"
  [readonly]="readonly"
  [value]="value"
  [clearable]="true"
  [editableSearchTerm]="true"
  [items]="(items | async) || []"
  [typeahead]="typeahead$"
  (changed)="onValueSelect($event)"
  (blurred)="onTouched()"
>
</eg-select>
