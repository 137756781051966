import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectComponent } from './select.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';
import { CardSelectComponent } from './card-select/card-select.component';
import { FlexModule } from '@ngbracket/ngx-layout';
import { InputModule } from '../input/input.module';
import { SelectIllustratedComponent } from './select-illustrated/select-illustrated.component';
import { TranslocoModule } from '@ngneat/transloco';
import { TileSelectComponent } from './tile-select/tile-select.component';
import { DropdownModule } from '../dropdown';
import { RadioSelectComponent } from './radio-select/radio-select.component';
import { IconSelectComponent } from './icon-select/icon-select.component';
import { TooltipModule } from '../tooltip';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

const COMPONENTS = [
  SelectComponent,
  CardSelectComponent,
  SelectIllustratedComponent,
  TileSelectComponent,
  RadioSelectComponent,
  IconSelectComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    NgSelectModule,
    ReactiveFormsModule,
    FormsModule,
    FormErrorModule,
    FlexModule,
    SharedDirectiveModule,
    InputModule,
    TranslocoModule,
    DropdownModule,
    TooltipModule,
  ],
  exports: [...COMPONENTS],
})
export class SelectModule {}
