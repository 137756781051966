<div *transloco="let translate" fxLayout="row" fxFlexFill fxFlex="100" fxLayoutAlign="center start">
  <div class="pb-24 full-height" fxLayout="column" fxLayoutAlign="center center">
    <div fxLayout="row wrap" fxLayoutAlign="start start">
      <div fxFlex="500px" fxLayout="row wrap">
        <h1 fxFlex="100" class="mb-30">
          {{ translate(TRANSLOCO_PREFIX + 'title') }}
        </h1>
        <h6 fxFlex="100" class="mb-32">
          <span>{{ translate(TRANSLOCO_PREFIX + 'pre') }}</span>
          <span class="bold text-color-black">{{ registrationEmail }}</span>
          <span>{{ translate(TRANSLOCO_PREFIX + 'post') }}</span>
        </h6>

        @if (responseError) {
        <eg-alert type="error" class="mb-8" fxFlex="100" [message]="responseError"> </eg-alert>
        } @if (infoMsg) {
        <eg-alert type="info" class="mb-8" fxFlex="100" [message]="translate(infoMsg)"> </eg-alert>
        }

        <form fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-around" [formGroup]="codeForm" (ngSubmit)="onCodeSubmit()">
          <eg-input
            fxLayout="row wrap"
            fxFlex="100"
            formControlName="code"
            name="code"
            autocomplete="off"
            fieldType="M"
            [label]="translate('forms.code.title')"
            [placeholder]="translate('forms.code.title')"
          >
          </eg-input>

          <div class="mt-40" fxLayout="row" fxFlex="100" fxLayoutAlign="space-between center">
            @if ({ counter: (counter$ | async) }; as context) {
            <h6>
              @if (!context.counter) {
              <span>{{ translate(TRANSLOCO_PREFIX + 'noCode') }}</span>
              <span class="text-color-primary no-underline hover-underline pointer" (click)="resendCode()">
                {{ translate(TRANSLOCO_PREFIX + 'resendCode') }}
              </span>
              } @else {
              <span class="text-color-primary">
                {{ translate(TRANSLOCO_PREFIX + 'resendCodeText') }} 00:{{ context.counter | number: '2.0' }}
              </span>
              }
            </h6>
            }
            <button class="btn btn-submit" type="submit">{{ translate(TRANSLOCO_PREFIX + 'sendCode') }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
