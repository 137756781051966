import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AddressModel, AddressTypeModel, WishlistModel } from '@railmybox/api-booking';

@Component({
  selector: 'rmb-wishlist-card',
  templateUrl: './wishlist-card.component.html',
  styleUrls: ['./wishlist-card.component.scss'],
})
export class WishlistCardComponent {
  private _wish: WishlistModel;
  public firstAddress: AddressModel;
  public secondAddress: AddressModel;
  public thirdAddress: AddressModel;
  readonly TRANSLOCO_PREFIX = 'wishlist.managementPage.';

  @Output() wishDeleteClicked: EventEmitter<string> = new EventEmitter<string>();

  @Input() set wish(wish: WishlistModel) {
    this._wish = wish;
    const addresses = this._wish?.bookingCreationData.addresses;
    const stationLoadpoints = addresses?.filter(
      (el: AddressModel) => el.type === AddressTypeModel.Loadpoint || el.type === AddressTypeModel.Station
    );
    this.firstAddress = stationLoadpoints[0];
    this.secondAddress = stationLoadpoints[stationLoadpoints.length - 1];
  }

  get wish(): WishlistModel {
    return this._wish;
  }

  onDeleteClick(wishId: any) {
    this.wishDeleteClicked.emit(wishId);
  }
}
