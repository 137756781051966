import { PhoneNumberUtil } from 'google-libphonenumber';
import { AbstractControl, FormGroup } from '@angular/forms';
import * as libPhoneNumber from 'google-libphonenumber';

export function isValid(
  relatedField = 'phoneCountryCode'
): (form: AbstractControl) => { invalidNumber: true } | { countryCodeRequired: true } | null {
  const phoneUtil: PhoneNumberUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

  return (control: AbstractControl) => {
    // control is the field on which validator is applied
    const form = control.parent as FormGroup;

    const relatedControl = form.get(relatedField);
    const phoneNumber: string = control.value.toString();
    if (relatedControl.invalid) {
      return { countryCodeRequired: true };
    }

    if (!phoneNumber) return null;

    try {
      const parsedPhoneNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, relatedControl.value);

      if (phoneUtil.isValidNumberForRegion(parsedPhoneNumber, relatedControl.value)) {
        return null;
      }

      return { invalidNumber: true };
    } catch (e) {
      return { invalidNumber: true };
    }
  };
}
