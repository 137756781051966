import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { SortDirectionChangeEvent, TableBaseData, TableColumn, TableSourceType } from '../../table.model';
import { ActionMenuItem } from '../../menu.interface';
import { SortDirection } from '@railmybox/shared/util';

@Component({
  template: '',
})
export abstract class BaseTableComponent<T extends TableBaseData> {
  @Input() dataSource: T[];
  @Input() columns: TableColumn<T>[] = [];
  @Input() showAction = true;
  @Input() showHeader = true;
  @Input() menuItems: ActionMenuItem[] = [];
  @Input() tableSource: TableSourceType;
  @Input() rowClassField: string;
  @Input() selectedItems: T[];
  @Input() disabledSelection = false;
  @Input() isSelected = false;
  @Output() sortDirectionChange: EventEmitter<SortDirectionChangeEvent<T>> = new EventEmitter<SortDirectionChangeEvent<T>>();
  @Output() rowClick: EventEmitter<T> = new EventEmitter<T>();

  selectedRowId: string | number;

  sortDirections: typeof SortDirection = SortDirection;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  sortData(idx: number): void {
    const column = this.columns[idx];
    if (!column.sortable) {
      return;
    }

    const previousColumnIndex = this.columns.findIndex(({ sortDirection }) => !!sortDirection);
    if (previousColumnIndex >= 0 && this.columns[previousColumnIndex].key !== column.key) {
      // Reset the previously sorted column
      this.columns[previousColumnIndex].sortDirection = undefined;
    }

    column.sortDirection = this.getSortDirection(column.sortDirection);
    this.sortDirectionChange.emit({
      key: column.sortKey || column.key,
      sortDirection: column.sortDirection,
      valueGetterFn: column.valueGetterFn,
    });

    this.changeDetectorRef.detectChanges();
  }

  onMenuStatusChange(menuOpen: boolean, row: T): void {
    if (menuOpen) {
      this.selectedRowId = row.id;

      return;
    }

    this.selectedRowId = undefined;
  }

  private getSortDirection(sortDirection: SortDirection | null): SortDirection | null {
    // Initially Start with ascending order
    if (!sortDirection) {
      return SortDirection.ASC;
    }

    if (sortDirection === SortDirection.ASC) {
      return SortDirection.DESC;
    }

    // Clear sorting filter for descending
    return null;
  }

  rowSelected(row: T): void {
    this.disabledSelection ? null : this.rowClick.emit(row);
  }

  isRowSelected(row: T) {
    return this.selectedItems?.includes(row);
  }

  trackBy(_: number, row: T): string {
    return row.id as string;
  }
}
