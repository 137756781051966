import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RegistrationFormData } from './registration.model';
import { allOf, emailValidator, guessTimezone, mustMatch, passwordValidator } from '@railmybox/shared/util';

export function getRegistrationForm(formBuilder: FormBuilder): FormGroup {
  return formBuilder.group(
    {
      companyName: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [emailValidator, Validators.required]],
      password: ['', [passwordValidator]],
      confirmPassword: [''],
      address: ['', [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      streetNumber: ['', [Validators.required]],
      streetName: ['', [Validators.required]],
      country: ['', [Validators.required]],
      countryCode: ['', [Validators.required]],
      googlePlaceId: ['', [Validators.required]],
      latitude: [0, [Validators.required]],
      longitude: [0, [Validators.required]],
      terms: [false, [Validators.requiredTrue]],
      privacy: [false, [Validators.requiredTrue]],
    },
    { validators: [mustMatch('password', 'confirmPassword'), allOf()] }
  );
}

export function mapRegistrationFormDataToCognitoAttributes(formData: RegistrationFormData): any {
  return {
    email: formData.email,
    given_name: formData.firstName,
    family_name: formData.lastName,
    locale: formData.locale,
    phone_number: formData.phoneNumber,
    'custom:company_name': formData.companyName,
    'custom:company_city': formData.city,
    'custom:company_zip': formData.postalCode,
    'custom:company_street': formData.streetName,
    'custom:company_streetnumber': formData.streetNumber,
    'custom:company_country': formData.defaultCountryCode,
    'custom:geoloc_id': formData.googlePlaceId,
    'custom:address_latitude': formData.latitude.toString(),
    'custom:address_longitude': formData.longitude.toString(),
    'custom:flag_privacy': formData.privacy.toString(),
    'custom:flag_terms': formData.terms.toString(),
    'custom:time_zone': guessTimezone(),
  };
}
