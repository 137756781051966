import { Injectable, NgZone } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject, Subscription, interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VersionCheckerService {
  isNewVersionAvailable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  intervalSource = interval(15 * 60 * 1000); // check every 15 mins for update
  intervalSubscription: Subscription;

  constructor(private swUpdate: SwUpdate, private zone: NgZone) {
    this.checkForUpdate();
  }

  checkForUpdate(): void {
    this.intervalSubscription?.unsubscribe();
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.intervalSubscription = this.intervalSource.subscribe(async () => {
        try {
          const updateAvailable = await this.swUpdate.checkForUpdate();
          this.isNewVersionAvailable$.next(updateAvailable);
        } catch (error) {
          console.error('Failed to check for updates:', error);
        }
      });
    });
  }

  applyUpdate(): void {
    // Reload the page to update to the latest version after the new version is activated
    this.swUpdate
      .activateUpdate()
      .then(() => document.location.reload())
      .catch((error) => console.error('Failed to apply updates:', error));
  }
}
