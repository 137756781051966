import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, Optional, SimpleChanges } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { AbstractValueAccessorComponent, FieldType, ValueAccessorUtil } from '@railmybox/shared/util';
import * as libPhoneNumber from 'google-libphonenumber';
import { PhoneNumberFormat } from 'google-libphonenumber';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Country, PhoneType } from './phone-number.interface';
import { CountryCodeService } from './service/country-code.service';
import { Observable } from 'rxjs';
import { isValid } from './validator/phone-number-validator';

@UntilDestroy()
@Component({
  selector: 'rmb-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(PhoneNumberComponent)],
})
export class PhoneNumberComponent extends AbstractValueAccessorComponent<string> implements OnInit, OnChanges {
  @Input() defaultCountryCode = 'DE';
  @Input() phoneType: PhoneType;
  @Input() countryList$: Observable<Country[]>;
  @Input() translocoPrefix = 'phone';

  @Input() set formRules(rule: 'M' | 'O' | 'R') {
    this.formRule = rule;
    switch (rule) {
      case 'O':
        this.setFormValidators(null);
        break;
      case 'M':
        this.setFormValidators(Validators.required);
        break;
      case 'R':
        this.phoneForm.disable();
    }
  }

  formRule: FieldType | 'R';
  phoneForm: FormGroup;
  phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

  constructor(
    private formBuilder: FormBuilder,
    private countryCodeService: CountryCodeService,
    changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer
  ) {
    super(changeDetectorRef, _controlContainer);
    this.phoneForm = this.getPhoneForm();
  }

  /**
   * Adds the validator to phoneNumber control
   */
  async ngOnInit(): Promise<void> {
    this.countryList$ = this.countryCodeService.getCountryList();
    // Updates the validity of phone number on country code change
    this.phoneForm
      .get('phoneCountryCode')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe({
        next: () => this.phoneForm.get('phoneNumber').updateValueAndValidity(),
      });

    this.onPhoneFormChange();
  }

  /**
   * Initialize the internal phoneForm
   * Also mark the input as touched on second writeValue Call
   *
   * @inheritDoc
   */
  writeValue(value: string) {
    super.writeValue(value);
    if (value) {
      this.splitNumber(value);
    }
  }

  /**
   * OnChange component lifecycle hook
   */
  ngOnChanges(simpleChanges: SimpleChanges): void {
    super.ngOnChanges(simpleChanges);
    if (this.defaultCountryCode) {
      this.phoneForm.patchValue({ phoneCountryCode: this.defaultCountryCode });
    }
  }

  /**
   * Retrieves the phone number form
   */
  getPhoneForm(): FormGroup {
    return this.formBuilder.group({
      phoneCountryCode: [this.defaultCountryCode],
      phoneNumber: [''],
    });
  }

  /**
   * Separates the phone number and country code from a final phoneNumber string (+49123...) -> (+49 | 123...)
   * @param phoneNum
   */
  splitNumber(phoneNum: string): void {
    const parsedPhoneNumber = this.phoneUtil.parseAndKeepRawInput(phoneNum);
    this.phoneForm.patchValue({
      phoneNumber: parsedPhoneNumber.getNationalNumber(),
      phoneCountryCode: this.phoneUtil.getRegionCodeForNumber(parsedPhoneNumber),
    });
  }

  /**
   * Adds a listener to the phoneForm which emits phoneNumber to parent component on value change
   */
  onPhoneFormChange(): void {
    this.phoneForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (phoneFormValue) => {
        const formattedPhoneNumber = this.formatPhoneNumber(phoneFormValue);
        if (!formattedPhoneNumber && this.phoneForm.valid) {
          return;
        }

        this.onChange(formattedPhoneNumber);

        this.changed.emit(formattedPhoneNumber);
      },
    });
  }

  onBlur(): void {
    this.phoneForm.markAllAsTouched();
    this.phoneForm.updateValueAndValidity();
    this.onTouched();
  }

  /**
   * Retrieves the current formatted phone number as string
   */
  formatPhoneNumber({ phoneCountryCode, phoneNumber }): string {
    try {
      return this.phoneUtil.format(this.phoneUtil.parseAndKeepRawInput(phoneNumber, phoneCountryCode), PhoneNumberFormat.E164) || '';
    } catch (e) {
      return '';
    }
  }

  /**
   * Updates the validity of the phoneForm
   *
   * To be used by parent component
   */
  updateFormValidity(): void {
    this.phoneForm.markAllAsTouched();
    this.onTouched();
    this.phoneForm.patchValue(this.phoneForm.getRawValue());
  }

  /**
   * Set the form validators
   *
   * @private
   */
  private setFormValidators(validatorFn: ValidatorFn | null): void {
    const phoneNumberValidators: ValidatorFn[] = [
      Validators.minLength(2),
      Validators.maxLength(18),
      Validators.pattern(/^[1-9][0-9]*$/),
      isValid(),
    ];

    if (validatorFn) {
      phoneNumberValidators.unshift(validatorFn);
    }

    this.phoneForm.get('phoneNumber').setValidators(phoneNumberValidators);
    this.phoneForm.get('phoneCountryCode').setValidators(validatorFn);
    this.phoneForm.updateValueAndValidity();
  }

  setDisabledState(isDisabled: boolean) {
    super.setDisabledState(isDisabled);
    const disableFn = isDisabled ? 'disable' : 'enable';
    this.phoneForm[disableFn]({ emitEvent: false });
  }
}
