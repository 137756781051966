import { Component, Input } from '@angular/core';

@Component({
  selector: 'rmb-support-nav',
  templateUrl: './support-nav.component.html',
  styleUrls: ['./support-nav.component.scss'],
})
export class SupportNavComponent {
  @Input() pricingOrAccountingRole: boolean;
  readonly TRANSLOCO_PREFIX = 'support';

  submenuClick(event: Event) {
    event.stopPropagation();
    const target = event.target as HTMLDivElement;

    target.classList.contains('open') ? target.classList.remove('open') : target.classList.add('open');
  }
}
