<eg-select
  *transloco="let translate"
  [disabled]="disabled"
  [items]="_technicalNames"
  [label]="label"
  [value]="value"
  (changed)="onSelect($event)"
  [clearable]="false"
>
</eg-select>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
