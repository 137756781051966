import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { TileAddComponent } from './tile-add/tile-add.component';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedDirectiveModule } from '@railmybox/shared/directive';

@NgModule({
  declarations: [ButtonComponent, TileAddComponent, IconButtonComponent],
  imports: [CommonModule, FlexLayoutModule, TranslocoModule, SharedDirectiveModule],
  exports: [ButtonComponent, TileAddComponent, IconButtonComponent],
})
export class ButtonModule {}
