import { Component, Input } from '@angular/core';

@Component({
  selector: 'eg-form-error',
  templateUrl: './form-error.component.html',
  styleUrls: ['./form-error.component.scss'],
})
export class FormErrorComponent {
  @Input() errorMessage: string;
}
