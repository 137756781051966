import { ChangeDetectorRef, Component, Input, OnInit, Optional } from '@angular/core';
import { AbstractValueAccessorComponent, formatDateTime, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer } from '@angular/forms';
import { Observable } from 'rxjs';
import { PillColorType } from '@eg/ui';
import { DispoService, TrainVisitModel, TrainVisitService } from '@railmybox/api-dispo';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'rmb-trainoption-select',
  templateUrl: './trainoption-select.component.html',
  styleUrls: ['./trainoption-select.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(TrainoptionSelectComponent)],
})
export class TrainoptionSelectComponent extends AbstractValueAccessorComponent<TrainVisitModel | string> implements OnInit {
  trainOptions$: Observable<TrainVisitModel[]>;
  selectedTrain: string;
  @Input() displayValue = 'trainIdOperator';
  @Input() clearable = true;
  @Input() dispoOrderId = '';

  trainStatusColors: PillColorType = {
    ACTIVE: 'lightGreen',
    IN_PROGRESS: 'green',
    DELAYED: 'orange',
    CANCELLED: 'red',
    DONE: 'blue',
    BLOCKED: 'green',
  };

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() _controlContainer: ControlContainer,
    private dispoService: DispoService,
    private trainVisitService: TrainVisitService,
    private translocoService: TranslocoService
  ) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit(): void {
    this.trainOptions$ = this.dispoService.listTrainOptions(this.dispoOrderId);
  }

  onSelect(trainOption: TrainVisitModel): void {
    this.selectedTrain = trainOption
      ? `${trainOption[this.displayValue]} ${formatDateTime(this.translocoService.getActiveLang(), 'date', trainOption.planTime)}`
      : null;
    this.value = trainOption;
    this.onChange(trainOption);
    this.changed.emit(trainOption);
  }

  async writeValue(value: TrainVisitModel | string): Promise<void> {
    if (!value) {
      return;
    }

    super.writeValue(value);

    const trainId = typeof value === 'string' ? value : value?.id;
    const train = await this.trainVisitService.getTrainVisit(trainId).subscribe((train) => {
      // In case no entry found with that id
      if (!train) {
        return;
      }

      this.value = train;
      this.selectedTrain = `${train[this.displayValue]} ${formatDateTime(this.translocoService.getActiveLang(), 'date', train.planTime)}`;
    });
  }
}
