<div class="expandable" fxFlex="100" fxLayout="column">
  <div class="expandable__header" fxFlex="100" fxLayout="row" fxLayoutAlign="space-between" (click)="toggleExpansion()">
    <div class="expandable__header--title">{{ heading }}</div>
    <svg-icon class="expandable__header--icon" src="/assets/icons/sort_{{ expanded ? 'up' : 'down' }}.svg"></svg-icon>
  </div>
  @if (expanded) {
  <div class="expandable__body" [@slide]>
    <ng-content></ng-content>
  </div>
  }
</div>
