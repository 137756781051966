<ng-select
  #ngSelect
  [disabled]="disabled"
  [selectOnTab]="selectOnTab"
  [dropdownPosition]="dropDownPosition"
  [ngModel]="value"
  [items]="items"
  [clearable]="clearable"
  [editableSearchTerm]="editableSearchTerm"
  [typeahead]="typeahead"
  [bindValue]="bindValue"
  [multiple]="true"
  [closeOnSelect]="false"
  [maxSelectedItems]="items?.length"
  [addTag]="addTag"
  fxFlex
  fxLayout="column"
  fxLayoutAlign="center center"
  (change)="onSelectionChanged($event)"
  (blur)="onSelectTouched()"
>
  <ng-container>
    @for (item of items; track item) {
    <ng-option [value]="item.value">
      {{ item.label || item.value }}
    </ng-option>
    }
  </ng-container>
</ng-select>

<label class="absolute" [class.has-value]="!!value" [for]="name" (click)="onLabelClick()">{{ labelWithSuffix }}</label>

@if (control?.touched && control?.errors) {
<eg-form-error [errorMessage]="errorMessage"></eg-form-error>
}
