import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[egNoSeparatorNumber]',
})
export class NoSeparatorNumberDirective {
  private readonly numbersRegex: RegExp = /^\d*$/;

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    if (!this.numbersRegex.test(event.key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) onPaste(event: Event): void {
    if (!this.numbersRegex.test((event as ClipboardEvent).clipboardData.getData('text/plain'))) {
      event.preventDefault();
    }
  }
}
