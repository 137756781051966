import { AbstractControl, FormGroup } from '@angular/forms';

export interface APIError {
  field: string;
  message: string;
  severity: string;
}

export function applyReactiveFormUtil(form: FormGroup, errors: APIError[]): void {
  Object.keys(form.controls).forEach((controlName: string) => {
    if (form.controls[controlName] instanceof FormGroup) {
      return applyReactiveFormUtil(form.controls[controlName] as FormGroup, errors);
    }

    const error = errors.find(({ field }) => field === controlName);
    if (!error) {
      return;
    }

    const lastMessageKeys = error.message.split('.');
    const lastMessageKey = lastMessageKeys.length === 1 ? 'api' : lastMessageKeys.pop();
    const errorObject = { [lastMessageKey]: { severity: error.severity } as any };
    if (lastMessageKey === 'api') {
      errorObject[lastMessageKey].message = error.message;
    }

    (form.controls[controlName] as AbstractControl).setErrors(errorObject);
  });
}
