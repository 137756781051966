import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CdkTableModule } from '@angular/cdk/table';
import { FlexModule } from '@ngbracket/ngx-layout';
import { TranslocoModule } from '@ngneat/transloco';
import { CardTableComponent } from './components/card-table/card-table.component';
import { FormsModule } from '@angular/forms';
import { ActionMenuComponent } from './components/action/action-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { SharedPipesModule } from '@railmybox/shared/pipes';
import { SharedDirectiveModule } from '@railmybox/shared/directive';
import { AlertModule } from '../alert/alert.module';

@NgModule({
  declarations: [CardTableComponent, ActionMenuComponent],
  imports: [
    CommonModule,
    CdkTableModule,
    FlexModule,
    TranslocoModule,
    FormsModule,
    AngularSvgIconModule.forRoot(),
    SharedDirectiveModule,
    SharedPipesModule,
    AlertModule,
  ],
  exports: [CardTableComponent, ActionMenuComponent],
})
export class TableModule {}
