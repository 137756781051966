import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSelect } from '../../select/select.interface';

@Component({
  selector: 'eg-icon-select',
  templateUrl: './icon-select.component.html',
  styleUrls: ['./icon-select.component.scss'],
})
export class IconSelectComponent {
  @Input() items: IconSelect[];
  @Input() value: boolean;
  @Input() translateLabel: string;
  @Output() valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  onItemSelect(item: IconSelect) {
    if (this.value === item.value) {
      return;
    }

    this.value = item.value;
    this.valueChanged.emit(this.value as boolean);
  }
}
