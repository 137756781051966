import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserStoreService } from '../../store/user';
import { AuthQuery } from '../../store/auth';
import { signUp } from '@aws-amplify/auth';
import { getRegistrationForm, mapRegistrationFormDataToCognitoAttributes } from './registration.form';
import { GooglePlaceChanged, PhoneNumberComponent } from '@railmybox/ui';

@Component({
  selector: 'rmb-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpComponent {
  readonly TRANSLOCO_PREFIX = 'auth.signup.form';

  @ViewChild(PhoneNumberComponent) phoneNumberComponent: PhoneNumberComponent;
  registrationForm: FormGroup;
  errorMessage: string;
  defaultCountryCode = 'DE';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private authQuery: AuthQuery,
    private userStoreService: UserStoreService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.registrationForm = getRegistrationForm(this.formBuilder);
  }

  async registration(): Promise<void> {
    this.phoneNumberComponent.updateFormValidity();
    this.registrationForm.markAllAsTouched();
    if (this.registrationForm.invalid) {
      return;
    }

    const registrationFormValue = this.registrationForm.value;
    const attributes = mapRegistrationFormDataToCognitoAttributes({
      ...registrationFormValue,
      locale: this.authQuery.getUserLanguageValue(),
      defaultCountryCode: this.defaultCountryCode,
    });

    const { email, password } = registrationFormValue;

    try {
      await signUp({ username: email, password, options: { userAttributes: attributes } });

      this.userStoreService.updateUserState({
        email,
      });

      await this.router.navigate(['/auth', 'verify']);
    } catch (err) {
      this.errorMessage = typeof err === 'string' ? err : err.message;
      this.changeDetectorRef.detectChanges();
    }
  }

  addressChanged(address: string) {
    if (address) {
      return;
    }

    this.registrationForm.get('address').markAsTouched();
    this.registrationForm.patchValue({ address: null });
  }

  onPlaceChanged(placeChanged: GooglePlaceChanged) {
    delete placeChanged.companyName;

    this.registrationForm.patchValue({ ...placeChanged }, { emitEvent: false });
    this.defaultCountryCode = placeChanged.countryCode;
    this.registrationForm.get('address').markAsTouched();
    this.registrationForm.get('address').updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }
}
