<eg-card-select
  *transloco="let translate"
  [items]="trainOptions$ | async"
  [itemTemplate]="itemTemplate"
  [tileTemplate]="tileTemplate"
  [hideSearch]="true"
  (dropdownClosed)="markInputAsTouched()"
  (valueSelection)="onSelect($event)"
  [value]="value"
  [clearable]="false"
  [disabled]="control.disabled"
>
</eg-card-select>
<ng-template #tileTemplate let-item="item">
  <div fxFlex="100" fxLayoutAlign="start center" class="label">
    @if (selectedTrain) {
    <span [class.label-top]="selectedTrain">{{ labelWithSuffix }}</span>
    }
    <span class="selected-value">{{ selectedTrain || labelWithSuffix }}</span>
  </div>
</ng-template>

<ng-template #itemTemplate let-item="item" let-selectCallback="selectCallback">
  <div fxFlex="100" fxLayoutAlign="center" class="px-16 select__item" (click)="selectCallback(item)">
    <span fxFlex="30" class="align-center"> {{ item.trainIdOperator }} </span>
    <span fxFlex="15" class="align-center">
      <eg-pill [value]="item.remainingCapacity" [type]="'number'"></eg-pill>
    </span>
    <span fxFlex="20" class="align-center"> {{ item.planTime | egDate: 'date' }} </span>
    <span fxFlex="15" class="align-center select__item--light"> {{ item.planTime | egDate: 'time' }} </span>
    <span fxFlex="20" class="align-center">
      <eg-pill [value]="item.trainStatus" translationPrefix="support.trainStatus." [colors]="trainStatusColors"></eg-pill>
    </span>
  </div>
</ng-template>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
