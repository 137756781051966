<input
  class="input"
  [class.no-label]="!labelWithSuffix"
  [id]="id"
  type="text"
  mask="Hh:m0"
  [(ngModel)]="value"
  [placeholder]="placeholder"
  [disabled]="disabled"
  [leadZeroDateTime]="true"
  [showMaskTyped]="false"
  (keyup)="onTimeChange($event.target['value'])"
  (blur)="markInputAsTouched()"
/>

<label [for]="id" class="absolute input-label">
  {{ labelWithSuffix }}
</label>

@if (control?.touched && control?.errors && errorMessage) {
<eg-form-error [errorMessage]="errorMessage" class="input-error"></eg-form-error>
}
