import { Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { AuthCredential, AuthUIState } from './auth.state';
import { AuthQuery } from './auth.query';
import { AuthSession, SignOutInput, fetchAuthSession, signOut } from '@aws-amplify/auth';
import { take } from 'rxjs/operators';
import { getRoles } from '../../helper/retrieve-roles.helper';

@Injectable({ providedIn: 'root' })
export class AuthStoreService {
  constructor(private store: AuthStore, private query: AuthQuery) {}

  /**
   * Updates the auth state
   */
  updateAuthState(session: AuthSession): void {
    if (!session || !session.tokens) {
      this.logout();
      return;
    }
    this.store.update({
      idToken: session.tokens.idToken.toString(),
      accessToken: session.tokens.accessToken.toString(),
      roles: getRoles(session),
    });
  }

  /**
   * Updates the UI state for auth store
   */
  updateUIState(uiState: Partial<AuthUIState>): void {
    this.store.update((state) => ({
      ...state,
      ui: {
        ...state.ui,
        ...uiState,
      },
    }));
  }

  updateUserCredentials(userCredentials: Partial<AuthCredential>): void {
    this.store.update((state) => ({
      ...state,
      ...userCredentials,
    }));
  }

  isLoggedIn(): Promise<boolean> {
    return this.getAuthQuery().loggedIn$.pipe(take(1)).toPromise();
  }

  setRedirectTo(path: string): void {
    this.store.update({ redirectTo: path });
  }

  /**
   * Retrieves the AuthStoreQuery instance
   */
  getAuthQuery(): AuthQuery {
    return this.query;
  }

  resetState(): void {
    this.store.reset();
  }

  getCurrentSession() {
    return fetchAuthSession();
  }

  async logout(opts?: SignOutInput): Promise<void> {
    localStorage.removeItem('AkitaStores');
    await signOut(opts);
    location.href = location.origin;
  }
}
