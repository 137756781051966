import { ChangeDetectorRef, Component, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { AbstractValueAccessorComponent, ValueAccessorUtil } from '@railmybox/shared/util';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'eg-number-selection',
  templateUrl: './number-selection.component.html',
  styleUrls: ['./number-selection.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(NumberSelectionComponent)],
})
export class NumberSelectionComponent extends AbstractValueAccessorComponent<number> implements OnChanges {
  @Input() max = 999;
  @Input() min = 0;

  constructor(changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
    this.value = this.value || 0;
  }

  decrementQuantity(): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }

    if (this.value <= this.min) {
      this.value = this.min;

      return;
    }

    this.value--;
    this.onValueChange(this.value);
  }

  incrementQuantity(): void {
    if (this.control?.disabled || this.disabled) {
      return;
    }

    if (this.value >= this.max) {
      this.value = this.max;

      return;
    }

    this.value++;
    this.onValueChange(this.value);
  }

  onValueChange(value: number): void {
    this.value = value;
    this.changeDetectorRef.detectChanges();
    this.onChange(value);
    this.changed.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    const { value } = changes;

    if (value) {
      this.value = this.verifyValidInput(value.currentValue);
    }
  }

  private verifyValidInput(value: number): number {
    if (value <= this.min) {
      return this.min;
    }

    if (value >= this.max) {
      return this.max;
    }

    return value;
  }
}
