import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideDirective } from './click-outside/click-outside.directive';
import { FieldPrefixDirective } from './field-prefix.directive';
import { FieldSuffixDirective } from './field-suffix.directive';
import { MarkFormTouchedDirective } from './mark-form-touched.directive';
import { AutoFocusDirective } from './auto-focus.directive';
import { NoSeparatorNumberDirective } from './no-separator-number.directive';
import { DropdownDirective } from './dropdown.directive';

const DIRECTIVES = [
  AutoFocusDirective,
  DropdownDirective,
  FieldPrefixDirective,
  FieldSuffixDirective,
  MarkFormTouchedDirective,
  AutoFocusDirective,
  NoSeparatorNumberDirective,
  ClickOutsideDirective,
];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES],
})
export class SharedDirectiveModule {}
