import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { AuthQuery } from '../store/auth';
import { selectPersistStateInit } from '@datorama/akita';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(private authQuery: AuthQuery, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return combineLatest([this.authQuery.loggedIn$, selectPersistStateInit()]).pipe(
      map(([isLoggedIn]) => {
        if (!isLoggedIn) {
          this.router.navigate(['/auth', 'login']);

          return false;
        }

        return true;
      })
    );
  }
}
