<ng-select
  #ngSelect
  [disabled]="disabled"
  [selectOnTab]="selectOnTab"
  [dropdownPosition]="dropDownPosition"
  [placeholder]="labelWithSuffix"
  [ngModel]="displayValue || value"
  [items]="items"
  [clearable]="clearable"
  [editableSearchTerm]="editableSearchTerm"
  [typeahead]="typeahead"
  [bindValue]="bindValue"
  [addTag]="addTag"
  [compareWith]="compareFn"
  (change)="onSelectionChanged($event)"
  (blur)="onSelectTouched()"
>
  @if (!optionTemplate) { @for (item of items; track item) {
  <ng-option [value]="item.value">
    {{ item.label || item.value }}
  </ng-option>
  } } @if (optionTemplate) {
  <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
    <ng-template *ngTemplateOutlet="optionTemplate; context: { item: item, index: index, searchTerm: search }"></ng-template>
  </ng-template>
  } @if (labelTemplate; as item) {
  <ng-template ng-label-tmp let-item="item">
    <ng-template *ngTemplateOutlet="labelTemplate; context: { item: item }"></ng-template>
  </ng-template>
  }
</ng-select>

<label class="absolute" [class.has-value]="!!value" [for]="name" (click)="onLabelClick()">{{ labelWithSuffix }}</label>

@if (control?.touched && control?.errors) {
<eg-form-error [errorMessage]="errorMessage"></eg-form-error>
}
