import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormErrorModule } from '../form-error/form-error.module';
import { TimeComponent } from './time.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

@NgModule({
  declarations: [TimeComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FormErrorModule, NgxMaskDirective, NgxMaskPipe],
  exports: [TimeComponent],
})
export class TimeModule {}
