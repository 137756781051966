import { ChangeDetectorRef, Component, OnInit, Optional } from '@angular/core';
import { ControlContainer, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { VatValidationModel } from '@railmybox/api-user';
import { Observable } from 'rxjs';
import { AbstractValueAccessorComponent, FieldType, ValueAccessorUtil } from '@railmybox/shared/util';
import { vatRegexValidator } from './validator/vat-input.validator';

@UntilDestroy()
@Component({
  selector: 'rmb-vat-input',
  templateUrl: './vat-input.component.html',
  styleUrls: ['./vat-input.component.scss'],
  providers: [...ValueAccessorUtil.getValueAccessorProvider(VatInputComponent)],
})
export class VatInputComponent extends AbstractValueAccessorComponent<any> implements OnInit {
  vat$: Observable<VatValidationModel>;

  constructor(changeDetectorRef: ChangeDetectorRef, @Optional() _controlContainer: ControlContainer) {
    super(changeDetectorRef, _controlContainer);
  }

  ngOnInit() {
    const control = this.control;
    if (control) {
      control.setValidators([Validators.required, vatRegexValidator()]);
    }
  }

  patchForm(value: string): void {
    this.formControl.patchValue(value);
  }

  async writeValue(value: string | any): Promise<void> {
    if (!value) {
      return;
    }

    if (typeof value === 'object') {
      super.writeValue(value?.vatCountryCode + value?.vatId);
      return;
    }
    super.writeValue(value);
  }

  inputChanged(value: string): void {
    const vat = { vatCountryCode: value.substring(0, 2).toUpperCase(), vatId: value.substring(2) };
    this.onChange(vat);
    this.formControl?.setValue(vat);
    this.changed.emit(vat);
  }
}
