<div
  *transloco="let translate"
  class="template-card"
  fxFlex="100"
  fxLayout="row"
  fxLayoutAlign="center baseline"
  fxLayoutGap="20px"
  (click)="onTemplateSelect($event)"
>
  <div fxFlex="20" fxLayout="column">
    <span class="heading mb-3">{{ template.name }}</span>
    <span class="subheading2"
      >{{ translate('category.' + template.transportCategory) }} - {{ translate('customs.' + template.customsType) }}</span
    >
  </div>

  <div fxFlex="20" fxLayout="column">
    <span class="subheading mb-3">{{ translate('waypoints.' + firstAddress?.type) }}</span>
    <span class="subheading2">{{ firstAddress?.formattedAddress }}</span>
  </div>

  <div fxFlex="20" fxLayout="column">
    <span class="subheading mb-3">{{ translate('waypoints.' + secondAddress?.type) }}</span>
    <span class="subheading2">{{ secondAddress?.formattedAddress }}</span>
  </div>

  <div fxFlex="20" fxLayout="column">
    <span class="subheading mb-3">{{ thirdAddress ? translate('waypoints.' + thirdAddress?.type) : ' ' }}</span>
    <span class="subheading2">{{ thirdAddress ? thirdAddress?.formattedAddress : ' ' }}</span>
  </div>

  <div fxFlex="10" fxLayout="column" fxFlexAlign="center">
    <span class="subheading mb-4">20' - {{ template.num20ftContainer }}</span>
    <span class="subheading">40' - {{ template.num40ftContainer }}</span>
    @if (template.num45ftContainer > 0) {
    <span class="subheading mt-4">45' - {{ template.num45ftContainer }}</span>
    }
  </div>

  @if (enableEditing) {
  <div fxFlex="10" fxLayout="row" fxFlexAlign="center">
    <div fxLayout="row" fxLayoutAlign="start center" (click)="onEditClick($event)">
      <img src="assets/icons/edit.svg" class="svg-to-primary-filter icon" alt="edit" />
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" (click)="onDeleteClick($event)">
      <img src="assets/icons/trash.svg" class="svg-to-primary-filter icon" alt="delete" />
    </div>
  </div>
  }
</div>
